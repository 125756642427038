import { Sidebar } from "components";
import {
  BackButton,
  LoadingSpinner,
  PageTitle,
  RoundedButton,
} from "components/Custom";

import DeleteFolderModal from "components/Qna/DeleteFolderModal";
import RenameFolderModal from "components/Qna/RenameFolderModal";
import { useFetchFolderById, useFetchMaterialsByFolder } from "hooks/materials";
import React, { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Materials } from "components/WalkThrough";
import { API_URL } from "constants";
import axios from "axios";
import toast from "react-hot-toast";
import { cleanToken } from "utils";
import SearchUser from "components/Users/SearchUser";
import UploadQNAFileModal from "components/Qna/UploadQNAFileModal";
import SpinnerCircle from "components/Custom/SpinnerCircle";
import QNAMaterialsTable from "components/Qna/QNAMaterialsTable";
import { UploadUrlModal } from "components/Qna";

function QnaMaterialFolder() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const cachedBranding = queryClient.getQueryData(["branding"])?.data;
  const [toggleDropDown, setToggleDropDown] = useState(false);
  const [showUploadFileModal, setShowUploadFileModal] = useState(false);
  const [showUploadUrlModal, setShowUploadUrlModal] = useState(false);
  const [showRenameFolderModal, setShowRenameFolderModal] = useState(false);
  const [showDeleteFolderModal, setShowDeleteFolderModal] = useState(false);
  const [showMaterialsCard, setShowMaterialsCard] = useState(
    location?.state?.showModal || false
  );
  const { folderId } = useParams();
  const {
    data: materials,
    refetch,
    isFetching: isFetchingMaterials,
  } = useFetchMaterialsByFolder(folderId);
  const {
    data: folder,
    refetch: refetchFolder,
    isFetching: isFetchingFolder,
  } = useFetchFolderById(folderId);
  const [folderName, setFolderName] = useState("");

  const [searchText, setSearchText] = useState("");

  const [materialData, setMaterialData] = useState(materials?.data);

  useEffect(() => {
    if (searchText != "") {
      var filtered = materials?.data.filter((c) => c.url.includes(searchText));
      setMaterialData(filtered);
    } else {
      setMaterialData(materials?.data);
    }
  }, [searchText]);

  useEffect(() => {
    setFolderName(folder?.data?.folderName);
  }, [folder]);

  useEffect(() => {
    setMaterialData(materials?.data);
  }, [materials]);

  const deploy = useMutation({
    mutationFn: () => {
      return axios.post(
        API_URL + `/QNA/DeployProject/${folder?.data?.folderId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${cleanToken(
              localStorage.getItem("token")
            )}`,
          },
        }
      );
    },
    onSuccess: async (data) => {
      console.log("data: ", data);
      toast.success("Deployed!");
    },
    onError: (error) => {
      console.log("error: ", error);
    },
  });

  const handleDeploy = () => {
    deploy.mutate();
  };

  return (
    <div className="container-fluid">
      <div className="row flex-nowrap">
        <Sidebar handleTourClick={() => setShowMaterialsCard(true)} />
        <div className="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-2"></div>
        <div
          className="col-10 col-sm-9 col-md-9 col-lg-9 col-xl-10"
          style={{ padding: "0 5%" }}
        >
          <div className="dashboard">
            <div className="page-title">
              <div className="row w-100">
                <div className="col-12 d-flex align-items-center">
                  <BackButton />
                  <PageTitle
                    title="Manage QNA Materials"
                    time="29 May 2023 - 4:02 PM"
                  />
                </div>
              </div>
            </div>

            <div className="normal-card sub-token">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center w-75">
                  <h6
                    className="me-5 mb-0 w-25"
                    style={{ color: cachedBranding?.page_TextColor }}
                  >
                    {folder?.data?.folderName}
                  </h6>
                  <div className="dropdown w-25">
                    <button
                      className="light-btn p-2 rounded mx-3 border-0"
                      type="button"
                      id="dropdownMenu2"
                      onClick={() => setToggleDropDown(!toggleDropDown)}
                    >
                      + Materials
                    </button>
                    <div
                      className={`dropdown-menu ${
                        toggleDropDown ? "show" : ""
                      }`}
                    >
                      <button
                        className="dropdown-item chat-dropdown"
                        type="button"
                        onClick={() => (
                          setShowUploadFileModal(!showUploadFileModal),
                          setToggleDropDown(false)
                        )}
                        style={{ color: cachedBranding?.page_TextColor }}
                      >
                        Files{" "}
                        <img
                          src="/images/files.svg"
                          style={{ width: "25px" }}
                        />
                      </button>
                      <button
                        className="dropdown-item chat-dropdown"
                        type="button"
                        onClick={() => (
                          setShowUploadUrlModal(!showUploadUrlModal),
                          setToggleDropDown(false)
                        )}
                        style={{ color: cachedBranding?.page_TextColor }}
                      >
                        Url's{" "}
                        <img src="/images/url.svg" style={{ width: "25px" }} />
                      </button>
                    </div>
                  </div>

                  {/* <div className="search-bar w-50">
                    <img src="/images/search.svg" className="search-ic" />
                    <SearchUser
                      searchText={searchText}
                      setSearchText={setSearchText}
                    />
                  </div> */}
                </div>
                <div className=" d-flex">
                  {deploy.isLoading ? (
                    <SpinnerCircle />
                  ) : (
                    <RoundedButton
                      text="Deploy"
                      handleOnClick={() => handleDeploy()}
                      className="p-3 ms-3 float-end w-100"
                    />
                  )}
                  {/* <button
                    className="btn"
                    style={{
                      color: cachedBranding?.page_TextColor,
                      fontSize: "14px",
                    }}
                    onClick={() => setShowRenameFolderModal(true)}
                  >
                    <img className="mx-1" src="/images/edit-user.svg" /> Rename
                  </button> */}
                  <button
                    className="btn"
                    style={{
                      fontSize: "14px",
                      color: cachedBranding?.page_TextColor,
                    }}
                    onClick={() => setShowDeleteFolderModal(true)}
                  >
                    <img className="mx-1" src="/images/delete-user.svg" />{" "}
                    Delete
                  </button>
                </div>
              </div>
              <hr className="mb-0" />
              <QNAMaterialsTable
                files={materialData}
                refetch={refetch}
                cachedBranding={cachedBranding}
                isQna={true}
              />
            </div>
          </div>
        </div>
      </div>
      <UploadQNAFileModal
        active={showUploadFileModal}
        handleClose={() => setShowUploadFileModal(false)}
        refetch={refetch}
        folderId={folderId}
        cachedBranding={cachedBranding}
      />
      <UploadUrlModal
        active={showUploadUrlModal}
        handleClose={() => setShowUploadUrlModal(false)}
        refetch={refetch}
        folderId={folderId}
        folderName={folderName}
        cachedBranding={cachedBranding}
      />
      <RenameFolderModal
        active={showRenameFolderModal}
        handleClose={() => setShowRenameFolderModal(false)}
        folder={folder?.data}
        refetch={refetchFolder}
      />
      <DeleteFolderModal
        active={showDeleteFolderModal}
        handleClose={() => setShowDeleteFolderModal(false)}
        folder={folder?.data}
      />
      <Materials
        show={showMaterialsCard}
        handleClose={() => setShowMaterialsCard(false)}
        handleSubmit={() =>
          navigate("/settings/script", { state: { showModal: true } })
        }
        cachedBranding={cachedBranding}
      />
      <LoadingSpinner showSpinner={isFetchingFolder || isFetchingMaterials} />
    </div>
  );
}

export default QnaMaterialFolder;
