import { Sidebar } from "components";
import { PageTitle, TabButton } from "components/Custom";
import { useEffect, useState } from "react";
import { useFetchFolders } from "hooks/materials";
import { useQueryClient } from "@tanstack/react-query";
import {
  useFetchAllCategories,
  useFetchChatCategorization,
  useFetchChatSettings,
} from "hooks/category";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "constants";
import { cleanToken } from "utils";
import { useLocation } from "react-router-dom";
import ChatSettingsModal from "components/WalkThrough/ChatSettingsModal";
import toast from "react-hot-toast";
import {
  Category,
  InstituteSettings,
  InternetSettings,
  QASettings,
} from "components/ChatSettings";
import { useFetchActiveIndexers } from "hooks/azure";
import { Password, Session, SignIn } from "components/SecurityPolicies";

function SecurityPolicies() {
  const location = useLocation();
  const [isChecked, setIsChecked] = useState(false);
  const [showQnA, setShowQnA] = useState(false);
  const [showMaterials, setShowMaterials] = useState(false);
  const [showInternetDataInChatConsole, setShowInternetDataInChatConsole] =
    useState(false);
  const [page, setPage] = useState(1);
  const [projectId, setProjectId] = useState();
  const [limit, setLimit] = useState(0);
  const [temperature, setTemperature] = useState(0);
  const [topP, setTopP] = useState(0);
  const [indexerId, setIndexerId] = useState();
  const queryClient = useQueryClient();
  const cachedBranding = queryClient.getQueryData(["branding"])?.data;
  const [showChatSetting, setShowChatSetting] = useState(
    location?.state?.showModal || false
  );
  const [selected, setSelected] = useState("sign-in");

  // useEffect(() => {
  //   if (chatSettings) {
  //     setIsChecked(chatSettings?.data[0]?.autoCategorize);
  //     setProjectId(
  //       chatSettings?.data[0]?.activeProjectId
  //         ? chatSettings?.data[0]?.activeProjectId
  //         : "00000000-0000-0000-0000-000000000000"
  //     );
  //     setIndexerId(
  //       chatSettings?.data[0]?.activeIndexerId
  //         ? chatSettings?.data[0]?.activeIndexerId
  //         : "00000000-0000-0000-0000-000000000000"
  //     );
  //     setShowInternetDataInChatConsole(chatSettings?.data[0]?.showInternet);
  //     setShowMaterials(chatSettings?.data[0]?.showMaterials);
  //     setShowQnA(chatSettings?.data[0]?.showQnA);
  //     setLimit(chatSettings?.data[0]?.maxTokenResponce);
  //     setTemperature(chatSettings?.data[0]?.temperature);
  //   }
  // }, [chatSettings]);

  // useEffect(() => {
  //   refetchChatCategorization();
  // }, []);

  // useEffect(() => {
  //   refetchChatCategorization({ pageNumber: page });
  // }, [page]);

  return (
    <div
      className="container-fluid"
      style={{
        backgroundColor: cachedBranding?.page_BackgroundColor,
        minHeight: "100vh",
      }}
    >
      <div className="row flex-nowrap">
        <Sidebar
          handleTourClick={() => (
            setShowChatSetting(true), window.scrollTo(0, 0)
          )}
        />
        <div className="col-2 col-sm-3 col-md-2 col-lg-3 col-xl-2"></div>
        <div className="col-10 col-sm-9 col-md-10 col-lg-9 col-xl-10 px-sm-5">
          <div className="dashboard">
            <div className="page-title">
              <div className="row w-100">
                <PageTitle
                  title="Security & Policies"
                  time="29 May 2023 - 4:02 PM"
                />
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div className="user-option">
                    <nav className="nav nav-pills nav-fill res-opt-btns w-50">
                      <TabButton
                        active={"sign-in" === selected ? true : false}
                        text="Sign In"
                        handleOnClick={() => setSelected("sign-in")}
                      />
                      <TabButton
                        active={"password" === selected ? true : false}
                        text="Password"
                        handleOnClick={() => setSelected("password")}
                      />
                      <TabButton
                        active={"session" === selected ? true : false}
                        text="Session"
                        handleOnClick={() => setSelected("session")}
                      />
                    </nav>
                  </div>
                </div>
                <div className="col-12">
                  {"sign-in" === selected ? (
                    <SignIn />
                  ) : "password" === selected ? (
                    <Password />
                  ) : (
                    <Session />
                  )}
                </div>
                {/* <div className="mt-3">
                  <button
                    type="button"
                    className="btn main-btn w-15 float-end"
                    onClick={handleSaveChatSettings}
                    style={{
                      backgroundColor: cachedBranding?.page_ButtonColor,
                    }}
                  >
                    Save
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ChatSettingsModal
        show={showChatSetting}
        handleClose={() => setShowChatSetting(false)}
        cachedBranding={cachedBranding}
      />
    </div>
  );
}

export default SecurityPolicies;
