import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import SubMenuItem from "./SubMenuItem";

function SidebarButton({
  title,
  to,
  icon,
  data,
  primaryColor,
  secondaryColor,
  textColor,
}) {
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    if (
      "settings" === window.location.pathname.split("/")[1] ||
      "company-profile" === window.location.pathname.split("/")[1] ||
      "qna" === window.location.pathname.split("/")[1] ||
      "materials" === window.location.pathname.split("/")[1]
    ) {
      setToggle(true);
    }
  }, []);

  return (
    <>
      {"Settings" === title ? (
        <li className="nav-item menu-list flex-column">
          <div
            className="nav-link px-0 align-middle d-flex align-items-center justify-content-between cursor-pointer"
            onClick={() => setToggle(!toggle)}
          >
            <div className="align-middle px-0 d-flex align-items-center">
              <i className={`menu-icon ${icon}`} style={{ color: textColor }} />{" "}
              <span
                className="ms-1 d-none d-sm-inline"
                style={{ color: textColor ? textColor : "#fff" }}
              >
                {title}
              </span>{" "}
            </div>
            <i
              className="menu-icon icon-more-arrow fs-5"
              style={{ color: textColor }}
            />
          </div>
          {toggle && (
            <ul className=" nav flex-column ms-1">
              {data?.map(
                (route, index) =>
                  route.isSetting && (
                    <SubMenuItem
                      title={route?.routeTitle}
                      to={route?.routeTo}
                      primaryColor={primaryColor}
                      secondaryColor={secondaryColor}
                      textColor={textColor}
                      key={index}
                    />
                  )
              )}
            </ul>
          )}
        </li>
      ) : (
        <li
          className={`nav-item menu-list ${
            window.location.pathname == to ? "active" : ""
          }`}
        >
          <NavLink
            to={to}
            className=" nav-link align-middle p-0 d-flex align-items-center"
          >
            <i
              className={`menu-icon ${icon}`}
              style={{
                color:
                  window.location.pathname == to ? secondaryColor : textColor,
              }}
            />{" "}
            <span
              className="ms-1 d-none d-sm-inline"
              style={{
                color:
                  window.location.pathname == to ? secondaryColor : textColor,
              }}
            >
              {title}
            </span>
          </NavLink>
        </li>
      )}
    </>
  );
}

export default SidebarButton;
