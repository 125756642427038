import React, { useState } from "react";
import { Link } from "react-router-dom";
import Details from "./Details";
import Edit from "./Edit";

function DetailsCard({ user, refetch, image, cachedBranding }) {
  const [selected, setSelected] = useState(true);
  return (
    <div className="profile-card mt-3 h-100">
      <div className="d-flex justify-content-between align-items-center w-100 border-bottom pb-4 mb-3">
        <h5 className="fw-semibold border-0 mb-0 p-0">Personal Information</h5>
        {selected && (
          <a className="cursor-pointer" onClick={() => setSelected(!selected)}>
            <img src="/images/edit-user.svg" />
          </a>
        )}
      </div>
      {!selected ? (
        <Edit
          handleOnSubmit={() => setSelected(!selected)}
          user={user}
          refetch={refetch}
          image={image}
          cachedBranding={cachedBranding}
        />
      ) : (
        <Details user={user} image={image} cachedBranding={cachedBranding} />
      )}
    </div>
  );
}

export default DetailsCard;
