import { Sidebar } from "components";
import { BackButton, LoadingSpinner, PageTitle } from "components/Custom";

import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useFetchQnaByMaterialId } from "hooks/materials";
import { useNavigate, useLocation } from "react-router-dom";
import { Materials } from "components/WalkThrough";
import { useQueryClient } from "@tanstack/react-query";
import { MaterialFileHeader, MaterialFileQuestionCard } from "components/Qna";

function QnaMaterialFile() {
  const { materialId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const queryClient = useQueryClient();
  const cachedBranding = queryClient.getQueryData(["branding"])?.data;

  const {
    data: qnas,
    refetch,
    isFetching: isFetchingQnas,
  } = useFetchQnaByMaterialId(materialId);
  const [filteredQNA, setfilteredQNA] = useState(qnas?.data);

  useEffect(() => {
    if (searchText != "") {
      var filtered = qnas?.data.filter(
        (c) => c.answer.includes(searchText) || c.question.includes(searchText)
      );
      setfilteredQNA(filtered);
    } else {
      setfilteredQNA(qnas?.data);
    }
  }, [searchText]);

  useEffect(() => {
    setfilteredQNA(qnas?.data);
  }, [qnas]);
  const [showMaterialsCard, setShowMaterialsCard] = useState(
    location?.state?.showModal || false
  );

  return (
    <div className="container-fluid">
      <div className="row flex-nowrap">
        <Sidebar handleTourClick={() => setShowMaterialsCard(true)} />
        <div className="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-2"></div>
        <div
          className="col-10 col-sm-9 col-md-9 col-lg-9 col-xl-10"
          style={{ padding: "0 5%" }}
        >
          <div className="dashboard">
            <div className="page-title">
              <div className="row w-100">
                <div className="col-12 d-flex align-items-center">
                  <BackButton />
                  <PageTitle
                    title="Questions and Answers"
                    time="29 May 2023 - 4:02 PM"
                  />
                </div>
              </div>
            </div>
            <MaterialFileHeader
              qnas={filteredQNA}
              materialId={materialId}
              searchText={searchText}
              setSearchText={setSearchText}
              cachedBranding={cachedBranding}
            />
            {filteredQNA &&
              filteredQNA.map((qna, index) => (
                <MaterialFileQuestionCard
                  key={index}
                  qna={qna}
                  refetch={refetch}
                  cachedBranding={cachedBranding}
                />
              ))}
          </div>
        </div>
      </div>
      <Materials
        show={showMaterialsCard}
        handleClose={() => setShowMaterialsCard(false)}
        handleSubmit={() =>
          navigate("/settings/script", { state: { showModal: true } })
        }
        cachedBranding={cachedBranding}
      />
      <LoadingSpinner showSpinner={isFetchingQnas} />
    </div>
  );
}

export default QnaMaterialFile;
