import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

function UpdateMethodModal({ show, handleClose, cardInfo }) {
  const [cardNumber, setCardNumber] = useState("");
  const [expiryMonth, setExpiryMonth] = useState("");
  const [expiryYear, setExpiryYear] = useState("");
  const [securityCode, setSecurityCode] = useState("");

  useEffect(() => {
    setCardNumber(cardInfo?.number);
    setExpiryMonth(cardInfo?.expiryMonth);
    setExpiryYear(cardInfo?.expiryYear);
    setSecurityCode(cardInfo?.securityCode);
  }, [cardInfo]);

  return (
    <Modal show={show} onHide={handleClose} centered>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalCenterTitle">
            Update Payment Method
          </h5>
          <div className="modal-option">
            <button type="button" className="btn" onClick={handleClose}>
              <img src="/images/close-modal.svg" />
            </button>
          </div>
        </div>
        <div className="modal-body">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h6>Credit Card</h6>
            <div className="">
              <img src="/images/visa.svg" />
              <img src="/images/master.svg" />
            </div>
          </div>
          <div className="row">
            <div className="col-12 mb-3">
              <label for="exampleFormControlInput1" className="form-label">
                Card Number
              </label>
              <input
                type="text"
                className="form-control profile-input"
                value={cardNumber}
                onChange={(e) => setCardNumber(e.target.value)}
              />
            </div>
            <div className="col-6 mb-3 w-50">
              <label for="exampleFormControlInput1" className="form-label">
                Expiry Month
              </label>
              <input
                type="number"
                className="form-control profile-input"
                placeholder="MM"
                value={expiryMonth}
                onChange={(e) => setExpiryMonth(e.target.value)}
              />
            </div>
            <div className="col-6 mb-3 w-50">
              <label for="exampleFormControlInput1" className="form-label">
                Expiry Year
              </label>
              <input
                type="number"
                className="form-control profile-input"
                placeholder="YY"
                value={expiryYear}
                onChange={(e) => setExpiryYear(e.target.value)}
              />
            </div>
            <div className="col-6 mb-3 w-50">
              <label for="exampleFormControlInput1" className="form-label">
                Security Code
              </label>
              <input
                type="number"
                className="form-control profile-input"
                placeholder="123"
                value={securityCode}
                onChange={(e) => setSecurityCode(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer d-flex">
          <button
            type="button"
            className="btn btn-secondary w-25"
            style={{ height: "45px" }}
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn main-btn w-25"
            style={{ backgroundColor: "#0744e1" }}
          >
            Confirm
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default UpdateMethodModal;
