import React, { useState, useEffect } from "react";
import { useFetchFolders } from "hooks/materials";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { cleanToken } from "utils";
import { API_URL } from "constants";
import toast from "react-hot-toast";
import SearchUser from "components/Users/SearchUser";
import QnaMaterialFolder from "../Qna/QnaMaterialFolder";
import { useFetchContainers } from "hooks/azure";
import NewContainerModal from "./NewContainerModal";
import { LoadingSpinner } from "components/Custom";
import MaterialFolder from "./MaterialFolder";

function MaterialsCard({ cachedBranding }) {
  const [showModal, setShowModal] = useState(false);

  const [searchText, setSearchText] = useState("");

  const [containerName, setContainerName] = useState("");
  const {
    data: containers,
    refetch,
    isFetching: isFetchingContainers,
  } = useFetchContainers();

  const [filteredContainers, setFilteredContainers] = useState(
    containers?.data
  );

  useEffect(() => {
    if (searchText != "") {
      var filtered = containers?.data.filter((c) =>
        c.folderName.includes(searchText)
      );
      setFilteredContainers(filtered);
    } else {
      setFilteredContainers(containers?.data);
    }
  }, [searchText]);

  useEffect(() => {
    setFilteredContainers(containers?.data);
  }, [containers]);

  const addFolder = useMutation({
    mutationFn: (fields) => {
      return axios.post(
        API_URL + `/Azure/CreateContainer/${fields}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${cleanToken(
              localStorage.getItem("token")
            )}`,
          },
        }
      );
    },
    onSuccess: async (data) => {
      setShowModal(false);
      setContainerName("");
      refetch();
      toast.success("Folder successfully added");
    },
    onError: (error) => {
      console.log("error: ", error);
      toast.error("Something went wrong!");
    },
  });

  const handleSave = () => {
    if (containerName.includes(" ")) {
      toast.error("Container name should not contain spaces!");
    } else {
      addFolder.mutate(containerName);
    }
  };

  return (
    <div className="normal-card sub-token">
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center w-75">
          <h6
            className="me-5 mb-0"
            style={{ color: cachedBranding?.page_TextColor }}
          >
            Containers{" "}
          </h6>
          {/* <div className="search-bar w-50">
            <img src="/images/search.svg" className="search-ic" />
            <SearchUser searchText={searchText} setSearchText={setSearchText} />
          </div> */}
        </div>
        <button
          className="btn"
          onClick={() => setShowModal(true)}
          style={{ color: cachedBranding?.page_TextColor }}
        >
          <img className="px-2" src="/images/new-folder.svg" /> New Container
        </button>
      </div>
      <hr />
      <div className="d-flex flex-wrap m-2">
        {filteredContainers &&
          filteredContainers.map((container, index) => (
            <div className=" adivgn-items-center mx-3 my-2" key={index}>
              <MaterialFolder
                container={container}
                icon="/images/folder.svg"
                cachedBranding={cachedBranding}
              />
            </div>
          ))}
      </div>
      <NewContainerModal
        active={showModal}
        handleClose={() => setShowModal(false)}
        handleSave={handleSave}
        containerName={containerName}
        setContainerName={setContainerName}
        cachedBranding={cachedBranding}
        isLoading={addFolder.isLoading}
      />
      <LoadingSpinner showSpinner={isFetchingContainers} />
    </div>
  );
}

export default MaterialsCard;
