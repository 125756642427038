import React from "react";

function MaterialsTableHeader({ cachedBranding }) {
  return (
    <thead>
      <tr>
        <th
          scope="col"
          className="table-name fw-normal pb-3"
          style={{ color: cachedBranding?.page_TextColor }}
        >
          File Name
        </th>
        <th
          scope="col"
          className="table-name fw-normal pb-3"
          style={{ color: cachedBranding?.page_TextColor }}
        >
          Data Type
        </th>
        <th
          scope="col"
          className="table-name fw-normal pb-3"
          style={{ color: cachedBranding?.page_TextColor }}
        >
          File Size
        </th>
        <th
          scope="col"
          className="table-name fw-normal pb-3"
          style={{ color: cachedBranding?.page_TextColor }}
        >
          Created At
        </th>
        {/* <th
          scope="col"
          className="table-name fw-normal pb-3"
          style={{ color: cachedBranding?.page_TextColor }}
        >
          Uploaded by
        </th> */}
        <th scope="col"></th>
      </tr>
    </thead>
  );
}

export default MaterialsTableHeader;
