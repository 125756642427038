import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "../constants";
import { cleanToken, handleResponseStatus } from "utils";

const fetchUserDashboardNumbers =  (dateFilter) => {
  return  axios.get(API_URL + `/Dashboard/GetDashboardNumbers/${dateFilter}`, {
    headers: {
      Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
    },
  });
};

export const useFetchDashboardNumbers = (dateFilter) => {
  return useQuery({
    queryKey: ["FetchDashboardNumbers"],
    queryFn: () => fetchUserDashboardNumbers(dateFilter),
    enabled: true,
    onError: () => {
    },
  });
};

const fetchLongestResponseSent =  (dateFilter) => {
  return  axios.get(API_URL + `/Dashboard/GetResponsesSent/${dateFilter}`, {
    headers: {
      Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
    },
  });
};

export const useLongestResponseSent = (dateFilter) => {
  return useQuery({
    queryKey: ["fetchLongestResponseSent"],
    queryFn: () => fetchLongestResponseSent(dateFilter),
    enabled: true,
    onError: () => {
    },
  });
};

const fetchLongestResponseSentGroup =  (dateFilter) => {
  return  axios.get(API_URL + `/Dashboard/GetResponsesSentByGroup/${dateFilter}`, {
    headers: {
      Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
    },
  });
};

export const useLongestResponseSentGroup = (dateFilter) => {
  return useQuery({
    queryKey: ["longestResponesGroup"],
    queryFn: () => fetchLongestResponseSentGroup(dateFilter),
    enabled: true,
  });
};

//

const fetchLongestResponseReceived =  (dateFilter) => {
  return  axios.get(API_URL + `/Dashboard/GetResponsesReceived/${dateFilter}`, {
    headers: {
      Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
    },
  });
};

export const useLongestResponseReceived = (dateFilter) => {
  return useQuery({
    queryKey: ["fetchLongestResponseReceived"],
    queryFn: () => fetchLongestResponseReceived(dateFilter),
    enabled: true,
    onError: () => {
    },
  });
};

const fetchLongestResponseReceivedGroup =  (dateFilter) => {
  return  axios.get(API_URL + `/Dashboard/GetResponsesReceivedByGroup/${dateFilter}`, {
    headers: {
      Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
    },
  });
};

export const useLongestResponseReceivedGroup = (dateFilter) => {
  return useQuery({
    queryKey: ["longestRecievedGroup"],
    queryFn: () => fetchLongestResponseReceivedGroup(dateFilter),
    enabled: true,
  });
};

//

const fetchConsumption =  (dateFilter) => {
  return  axios.get(API_URL + `/Dashboard/GetDashboardGraph/${dateFilter}`, {
    headers: {
      Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
    },
  });
};

export const useConsumption = (dateFilter) => {
  return useQuery({
    queryKey: ["fetchConsumption"],
    queryFn: () => fetchConsumption(dateFilter),
    enabled: true,
    onError: () => {
    },
  });
};

///
const fetchGroupConsumption =(dateFilter)=>{
  return  axios.get(API_URL+ `/Dashboard/GetDashboardGraphByGroup/${dateFilter}`,{
    headers: {
      Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
    },
  })
}
export const useGroupConsumption=(dateFilter)=>{
  return useQuery({
    queryKey:["fetchGroupConsumption"],
    queryFn:()=>fetchGroupConsumption(dateFilter),
    enabled:true,
  })
}

//

const fetchCategories =  (dateFilter) => {
  return  axios.get(API_URL + `/Dashboard/GetCategoryText/${dateFilter}`, {
    headers: {
      Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
    },
  });
};

export const useCategories = (dateFilter) => {
  return useQuery({
    queryKey: ["fetchCategories"],
    queryFn: () => fetchCategories(dateFilter),
    enabled: true,
    onError: () => {
    },
  });
};
//
const fetchCategoriesByGroup =  (dateFilter) => {
  return  axios.get(API_URL + `/Dashboard/GetCategoryTextByGroup/${dateFilter}`, {
    headers: {
      Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
    },
  });
};

export const useCategoriesByGroup = (dateFilter) => {
  return useQuery({
    queryKey: ["fetchCategoriesByGroup"],
    queryFn: () => fetchCategoriesByGroup(dateFilter),
    enabled: true,
    onError: () => {
    },
  });
};

const fetchDashboardNumbersByGroup =  (dateFilter) => {
  return  axios.get(API_URL + `/Dashboard/GetDashboardNumbersByGroup/${dateFilter}`, {
    headers: {
      Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
    },
  });
};

export const useFetchDashboardNumbersByGroup = (dateFilter) => {
  return useQuery({
    queryKey: ["groupNumbers"],
    queryFn: () => fetchDashboardNumbersByGroup(dateFilter),
    enabled: true,
  });
};


const fetchRatings =  (dateFilter) => {
  return  axios.get(API_URL + `/Dashboard/GetRating/${dateFilter}`, {
    headers: {
      Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
    },
  });
};

export const useRatings = (dateFilter) => {
  return useQuery({
    queryKey: ["useRatings"],
    queryFn: () => fetchRatings(dateFilter),
    enabled: true,
  });
};


const fetchRatingsByGroup =  (dateFilter) => {
  return  axios.get(API_URL + `/Dashboard/GetRatingByGroup/${dateFilter}`, {
    headers: {
      Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
    },
  });
};

export const useRatingsByGroup = (dateFilter) => {
  return useQuery({
    queryKey: ["useRatingsGroup"],
    queryFn: () => fetchRatingsByGroup(dateFilter),
    enabled: true,
  });
};