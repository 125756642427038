import React from "react";

function UserRestrictionTableHeader({ cachedBranding }) {
  return (
    <thead>
      <tr>
        <th
          className="table-header bg-transparent"
          scope="col"
          style={{ color: cachedBranding?.page_TextColor }}
        >
          Name
        </th>
        <th
          className="table-header bg-transparent"
          scope="col"
          style={{ color: cachedBranding?.page_TextColor }}
        >
          Email
        </th>
        <th
          className="table-header bg-transparent"
          scope="col"
          style={{ color: cachedBranding?.page_TextColor }}
        >
          Mobile
        </th>
        <th
          className="table-header bg-transparent"
          scope="col"
          style={{ color: cachedBranding?.page_TextColor }}
        >
          Department
        </th>
        <th
          className="table-header bg-transparent"
          scope="col"
          style={{ color: cachedBranding?.page_TextColor }}
        >
          Token Balance
        </th>
        <th
          className="table-header headercenter bg-transparent"
          scope="col"
          style={{ color: cachedBranding?.page_TextColor }}
        >
          Status
        </th>
      </tr>
    </thead>
  );
}

export default UserRestrictionTableHeader;
