import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import GroupNavTab from "./GroupNavTab";
import GroupDetails from "./GroupDetails";
import Usage from "./Usage";
import Tags from "./Tags";
import DeleteGroupModal from "./DeleteGroupModal";
import DeleteProceedModal from "./DeleteProceedModal";
import { Link } from "react-router-dom";
import SettingsTab from "./SettingsTab";
import DeleteAccountModal from "./DeleteAccountModal";
import EditUserModal from "./EditUserModal";
import UserDetails from "./UserDetails";
import { useFetchUserImage } from "hooks/users";
import { useQueryClient } from "@tanstack/react-query";

function GroupModal({
  show,
  handleClose,
  type = "group",
  info,
  refetchUsers,
  refetchGroups,
  groupImage,
  renderInitials,
}) {
  const queryClient = useQueryClient();
  const cachedBranding = queryClient.getQueryData(["branding"])?.data;
  const [selected, setSelected] = useState(
    "group" === type ? "details" : "userDetails"
  );
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
  const [showProceed, setShowProceed] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const { data: userImage, refetch: refetchImage } = useFetchUserImage(
    info?.userId
  );

  return (
    <Modal
      show={show}
      onHide={handleClose}
      style={{ paddingRight: "0px", overflowY: "hidden" }}
      dialogClassName="side"
    >
      <div className="modal-content side-content group-modal">
        <div className="modal-header">
          <h4
            className="modal-title"
            style={{ color: cachedBranding?.page_TextColor }}
          >
            {"group" === type ? "Group Profile" : "Profile"}
          </h4>
          <div className="modal-option">
            <button type="button" className="btn" onClick={handleClose}>
              <img src="/images/close-modal.svg" />
            </button>
          </div>
        </div>
        <div className="modal-body">
          <div className="header-profile">
            {"group" === type ? (
              groupImage?.data?.imageBytes ? (
                <img
                  src={`data:image/jpeg;base64,${groupImage?.data?.imageBytes}`}
                  className="user-image"
                />
              ) : (
                <div className="group-initials2">
                  {renderInitials(info?.groupName)}
                </div>
              )
            ) : userImage?.data?.imageBytes ? (
              <img
                src={`data:image/jpeg;base64,${userImage?.data?.imageBytes}`}
                className="user-image"
              />
            ) : (
              <img
                src="/images/user-empty.svg"
                alt="hugenerd"
                className="user-image"
              />
            )}
            {"group" === type ? (
              <h5 style={{ color: cachedBranding?.page_TextColor }}>
                {info?.name}
              </h5>
            ) : (
              <h5 style={{ color: cachedBranding?.page_TextColor }}>
                {info?.firstName} {info?.lastName}
              </h5>
            )}
            {"user" === type && <span>{info?.displayName}</span>}
          </div>
          <div className="nav nav-tabs nav-fill">
            {"group" === type ? (
              <>
                <GroupNavTab
                  text="Details"
                  active={"details" === selected ? true : false}
                  handleOnClick={() => setSelected("details")}
                  cachedBranding={cachedBranding}
                />
                <GroupNavTab
                  text="Usage"
                  active={"usage" === selected ? true : false}
                  handleOnClick={() => setSelected("usage")}
                  cachedBranding={cachedBranding}
                />
                <GroupNavTab
                  text="Tags"
                  active={"tags" === selected ? true : false}
                  handleOnClick={() => setSelected("tags")}
                  cachedBranding={cachedBranding}
                />
              </>
            ) : (
              <>
                <GroupNavTab
                  text="Details"
                  active={"userDetails" === selected ? true : false}
                  handleOnClick={() => setSelected("userDetails")}
                />
                <GroupNavTab
                  text="Usage"
                  active={"userUsage" === selected ? true : false}
                  handleOnClick={() => setSelected("userUsage")}
                />
                <GroupNavTab
                  text="Settings"
                  active={"userSettings" === selected ? true : false}
                  handleOnClick={() => setSelected("userSettings")}
                />
              </>
            )}
          </div>
          <div className="main-details">
            <div className="tab-content">
              <GroupDetails
                active={"details" === selected ? true : false}
                group={info}
                refetchGroups={refetchGroups}
              />
              <Usage
                active={
                  "usage" === selected || "userUsage" === selected
                    ? true
                    : false
                }
                user={info}
                type={type}
                cachedBranding={cachedBranding}
              />
              <UserDetails
                active={"userDetails" === selected ? true : false}
                user={info}
                type={type}
                cachedBranding={cachedBranding}
              />
              <Tags
                active={"tags" === selected ? true : false}
                tags={info?.groupTagResponse}
                group={info}
                refetchGroups={refetchGroups}
                cachedBranding={cachedBranding}
              />
              <SettingsTab
                active={"userSettings" === selected ? true : false}
                user={info}
                cachedBranding={cachedBranding}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <div className="profile-option">
            {"group" === type ? (
              <Link to={`/edit-group/${info?.groupId}`}>
                <button type="button" className="btn action-btn">
                  {" "}
                  <img src="/images/edit-user.svg" />
                  Edit Group
                </button>
              </Link>
            ) : (
              <button
                type="button"
                className="btn action-btn"
                onClick={() => setShowEditModal(true)}
              >
                {" "}
                <img src="/images/edit-user.svg" />
                Edit Profile
              </button>
            )}
            {"group" === type ? (
              <button
                type="button"
                className="btn action-btn delete"
                onClick={() => setShowDeleteModal(true)}
              >
                <img src="/images/delete-user.svg" /> Delete Group
              </button>
            ) : (
              <button
                type="button"
                className="btn action-btn delete"
                onClick={() => setShowDeleteAccountModal(true)}
              >
                <img src="/images/delete-user.svg" /> Delete Account
              </button>
            )}
          </div>
        </div>
      </div>
      <DeleteGroupModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        handleProceed={() => (setShowProceed(true), setShowDeleteModal(false))}
      />
      <DeleteProceedModal
        show={showProceed}
        handleClose={() => (
          setShowProceed(false), refetchGroups(), refetchUsers(), handleClose()
        )}
        type={type}
        info={info}
      />
      <DeleteAccountModal
        show={showDeleteAccountModal}
        handleClose={() => (refetchUsers(), setShowDeleteAccountModal(false))}
        handleSubmit={() => (
          setShowDeleteAccountModal(false), setShowProceed(true)
        )}
        selectedUser={info}
      />
      <EditUserModal
        show={showEditModal}
        handleClose={() => (refetchUsers(), setShowEditModal(false))}
        selectedUser={info}
      />
    </Modal>
  );
}

export default GroupModal;
