import React, { useState, useEffect } from "react";

function SignIn({ cachedBranding }) {
  return (
    <div>
      <div className="normal-card">
        <div className="px-3">
          <p
            className="mb-0 policies-label"
            style={{ color: cachedBranding?.page_TextColor }}
          >
            Terms & Conditions
          </p>
          <div className="switch-ai-g">
            <p
              className="no-margin"
              style={{ color: cachedBranding?.page_TextColor }}
            >
              Require users to agree to Terms & Conditions
            </p>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                // checked={isChecked}
                // onChange={() => setIsChecked(!isChecked)}
              />
            </div>
          </div>
          <div className="cont-wmessage">
            <img src="/images/info.svg" alt="vector" />
            <p
              className="w-message"
              style={{ color: cachedBranding?.page_TextColor }}
            >
              Ai category is On, the token consumption is double.
            </p>
          </div>
        </div>
      </div>
      <div className="normal-card">
        <div className="px-3">
          <p
            className="mb-0 policies-label"
            style={{ color: cachedBranding?.page_TextColor }}
          >
            System Use Notification
          </p>
          <div className="switch-ai-g">
            <p
              className="no-margin"
              style={{ color: cachedBranding?.page_TextColor }}
            >
              Show System Use Notification before every login
            </p>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                // checked={isChecked}
                // onChange={() => setIsChecked(!isChecked)}
              />
            </div>
          </div>
          <textarea
            className="w-100 mt-2 notification-input p-2"
            rows={3}
            placeholder="Enter your notification here"
          ></textarea>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
