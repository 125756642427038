import React from "react";
import { Modal } from "react-bootstrap";

function DeleteAccountModal({
  cachedBranding,
  show,
  handleClose,
  handleSubmit,
}) {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <div className="modal-content">
        <div className="modal-header">
          <h4
            className="modal-title"
            style={{ color: cachedBranding?.page_TextColor }}
          >
            Delete Account
          </h4>
          <div className="modal-option">
            <button
              type="button"
              className="btn"
              data-bs-dismiss="modal"
              onClick={handleClose}
            >
              <img src="/images/close-modal.svg" />
            </button>
          </div>
        </div>
        <div className="modal-body editProfile">
          <span style={{ color: cachedBranding?.page_TextColor }}>
            Are you sure you want to delete this account?
          </span>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn normal-btn"
            onClick={handleClose}
          >
            No
          </button>
          <button
            type="button"
            className="btn delete-btn"
            onClick={handleSubmit}
          >
            Yes
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default DeleteAccountModal;
