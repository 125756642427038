import React from "react";
import { Modal } from "react-bootstrap";

const ChatSettingsModal = ({ show, handleClose, cachedBranding }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="settings-plan modal-walkthrough"
      size="lg"
    >
      <div className="modal-content p-0">
        <div className="speech topuser p-3 m-0">
          <div className="row">
            <div
              className="col-12 d-flex justify-content-end mb-3 me-4"
              onClick={handleClose}
            >
              <img src="/images/close-modal.svg" />
            </div>
          </div>
          <div className="row">
            <div className="col-6 ">
              <img src="/images/chat-settings.svg" />
            </div>
            <div className="col-6 p-0 pe-4">
              <h5 className="fw-semibold">Chat Settings</h5>
              <ol style={{ fontSize: "14px" }}>
                <li>
                  Select the Chat Console materials by selecting the project.
                </li>
                <li>
                  Choose whether you want the AI-generated categories or manual
                  assignment of Q&A to categories.
                </li>
                <li>You can add a category for manual assignment.</li>
              </ol>
              <div className="d-flex justify-content-end">
                <a
                  className="btn main-btn mt-4 px-4 cursor-pointer"
                  onClick={handleClose}
                  style={{ backgroundColor: cachedBranding?.page_ButtonColor }}
                >
                  Finish
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ChatSettingsModal;
