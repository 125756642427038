import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import TierCard from "./TierCard";
import AddsOnCard from "./AddsOnCard";
import { RoundedButton } from "components/Custom";

function ChangePlanModal({ show, handleClose, handleSubmit }) {
  const [tier, setTier] = useState(1);
  const [addsOn, setAddsOn] = useState(1);

  const tiers = [
    {
      id: 1,
      title: "Tier 1",
      gptVersion: "GPT 3 Turbo",
      tokens: 100000,
      consumption: "FREE Consumption",
      integration: "FREE Integration",
      support: "FREE Support",
      services: [
        "Support 5/7",
        "Intial Consumption",
        "Dashboard",
        "Bandwind Monitoring",
        "Questions & Answers Monitoring",
      ],
    },
    {
      id: 2,
      title: "Tier 2",
      gptVersion: "GPT 3",
      tokens: 100000,
      consumption: "FREE Consumption",
      integration: "FREE Integration",
      support: "FREE Support",
      services: [
        "Support 5/7",
        "Intial Consumption",
        "Dashboard",
        "Bandwind Monitoring",
        "Questions & Answers Monitoring",
      ],
    },
    {
      id: 3,
      title: "Tier 3",
      gptVersion: "GPT 3",
      tokens: 100000,
      consumption: "FREE Consumption",
      integration: "FREE Integration",
      support: "FREE Support",
      services: [
        "Support 5/7",
        "Intial Consumption",
        "Dashboard",
        "Bandwind Monitoring",
        "Questions & Answers Monitoring",
      ],
    },
    {
      id: 4,
      title: "Tier 4",
      gptVersion: "GPT 3",
      tokens: 100000,
      consumption: "FREE Consumption",
      integration: "FREE Integration",
      support: "FREE Support",
      services: [
        "Support 5/7",
        "Intial Consumption",
        "Dashboard",
        "Bandwind Monitoring",
        "Questions & Answers Monitoring",
      ],
    },
    {
      id: 5,
      title: "Tier 5",
      gptVersion: "GPT 3",
      tokens: 100000,
      consumption: "FREE Consumption",
      integration: "FREE Integration",
      support: "FREE Support",
      services: [
        "Support 5/7",
        "Intial Consumption",
        "Dashboard",
        "Bandwind Monitoring",
        "Questions & Answers Monitoring",
      ],
    },
  ];

  const addOns = [
    {
      type: "Voice Record",
      price: "$100",
      services: [
        "1,000 minutes",
        "Azura Cognitive Services",
        "Audio Listening",
      ],
    },
    {
      type: "Video Calls",
      price: "$300",
      services: ["850 minutes", "Azura Cognitive Services", "Audio Listening"],
    },
  ];

  return (
    <Modal show={show} onHide={handleClose} centered size="xl">
      <div className="modal-content">
        <div className="modal-header">
          <h1 className="modal-title fs-5" id="staticBackdropLabel">
            Change Plan
          </h1>
          <button type="button" className="btn" onClick={handleClose}>
            <img src="/images/close-modal.svg" />
          </button>
        </div>
        <div className="modal-body">
          <div className="tier-list">
            {tiers &&
              tiers.map((tier, index) => (
                <TierCard
                  active={index === tier ? true : false}
                  title={tier.title}
                  price="$1000 / Monthly"
                  handleOnClick={() => setTier(index)}
                  item={tier}
                  key={index}
                />
              ))}
          </div>
          <div className="add-on-list mt-2 mb-3">
            <h5 className="mb-4 p-3 border-bottom">Add ons</h5>
            <div className="add-on-price">
              {addOns &&
                addOns.map((addOn, index) => (
                  <AddsOnCard
                    handleSelect={() => setAddsOn(2)}
                    item={addOn}
                    // addsOn={addsOn}
                    addsOn={2 === addsOn ? true : false}
                    key={index}
                  />
                ))}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end p-4">
          <div className="d-flex w-50 flex-row">
            <RoundedButton
              text="Cancel"
              style={{ width: "50%", height: "45px" }}
              type="btn-secondary"
              handleOnClick={() => handleClose()}
              className="mx-3"
            />
            <RoundedButton
              text="Next"
              type="main-btn w-50"
              handleOnClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ChangePlanModal;
