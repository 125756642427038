import React, { useState } from "react";

function CustomCheckbox({ label, handleOnChange, value=false  }) {
  const [checked, setChecked] = useState();
  return (
    <div className="form-check">
      <input
        className="form-check-input"
        type="checkbox"
        onChange={handleOnChange}
        checked={value}
      />
      <label
        className="form-check-label fw-medium mb-3"
        htmlFor="flexCheckDefault"
      >
        {label}
      </label>
    </div>
  );
}

export default CustomCheckbox;
