import { UserIcon } from "components/Custom";
import React, { useEffect, useState } from "react";

function UserRestrictionTableRow({ user, onUpdate, cachedBranding }) {
  const [tokens, setTokens] = useState(user?.tokenBalance);
  const [active, setActive] = useState(user?.isActive);

  useEffect(() => {
    setTokens(user?.tokenBalance || "");
    setActive(user?.isActive || false);
  }, [user]);

 
  const handleTokensChange = (e) => {
    const newTokens = e.target.value;
    setTokens(newTokens);
    onUpdate({ ...user, tokenBalance: parseInt(newTokens) });
  };

  const handleActiveChange = () => {
    const newActive = !active;
    setActive(newActive);
    onUpdate({ ...user, isActive: newActive });
  };

  return (
    <tr className="table-tile">
      <th scope="row" className="user-name p-3">
        <div className="user-name">
          <UserIcon
            userId={user?.userId}
            name={user?.username}
            image={user?.image}
            cachedBranding={cachedBranding}
          />
        </div>
      </th>
      <td className="table-details">
        <span style={{ color: cachedBranding?.page_TextColor }}>
          {user?.displayEmail}
        </span>
      </td>
      <td className="table-details">
        <span style={{ color: cachedBranding?.page_TextColor }}>
          {user?.phone}
        </span>
      </td>
      <td className="table-details">
        <span style={{ color: cachedBranding?.page_TextColor }}>
          {user?.department}
        </span>
      </td>
      <td className="table-details">
        {" "}
        <input
          type="number"
          min="0"
          max="1000000"
          maxLength="9"
          placeholder="0"
          value={tokens}
          onChange={handleTokensChange}
          className="num-inp mb-0"
          disabled={!active}
        />
      </td>
      <td className="table-details">
        {" "}
        <div className="tag">
          {!active ? (
            <div
              className="role super-admin w-100 text-body-tertiary border-dark-subtle bg-light cursor-pointer"
              onClick={handleActiveChange}
            >
              <span>Locked</span>
            </div>
          ) : (
            <div
              className="role super-admin w-100 cursor-pointer  border-dark-subtle bg-light "
              onClick={handleActiveChange}
            >
              <span>Active</span>
            </div>
          )}
        </div>
      </td>
    </tr>
  );
}

export default UserRestrictionTableRow;
