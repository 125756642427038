import React from "react";
import { Modal } from "react-bootstrap";

function TableModal({ show, handleClose, responses }) {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="longest-questions"
      centered
      size="lg"
    >
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Longest Questions Received</h4>
          <div className="modal-option">
            <button type="button" className="btn">
              <img src="/images/download-ico.svg" />
            </button>
            <button
              type="button"
              className="btn"
              data-bs-dismiss="modal"
              onClick={() => handleClose()}
            >
              <img src="/images/close-modal.svg" />
            </button>
          </div>
        </div>
        <div className="modal-body">
          <div className="table-responsive-sm minCard">
            <table className="table table-striped table-borderless table-name">
              <thead>
                <tr>
                  <th scope="col" className="table-name">
                    Name
                  </th>
                  <th scope="col" className="table-name">
                    Question
                  </th>
                  <th scope="col" className="table-name text-nowrap">
                    Token Count
                  </th>
                </tr>
              </thead>
              <tbody>
                {responses.map((response, index) => (
                  <tr key={index}>
                    <th scope="row" className="table-details">
                      {response?.name}
                    </th>
                    <td className="ques-res ">{response?.response}</td>
                    <td className="table-details text-align-right">
                      {response?.tokenCount}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default TableModal;
