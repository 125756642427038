import React from "react";
import { RoundedButton } from "components/Custom";
import { isEmpty } from "lodash";

function BotMainView({
  bot,
  tempBot,
  backgroundColor,
  botColor = "#0744e1",
  buttonColor = "#0744e1",
  botIconContentType,
}) {
  return (
    <div
      className="login-view mt-4 p-3"
      style={{ backgroundColor: backgroundColor }}
    >
      <div className="row">
        <div className="col-7"></div>
        <div className="col-5">
          <img src="/images/bot-maximiza.png" className="px-1 cursor-pointer" />
          <img src="/images/bot-minimize.png" className="px-1 cursor-pointer" />
          <img src="/images/bot-close.png" className="px-1 cursor-pointer" />
        </div>
      </div>
      <div className="w-100 mt-5">
        <div className="text-center mb-4">
          {tempBot ? (
            <img
              src={URL.createObjectURL(tempBot)}
              alt="Selected Bot"
              width={73}
            />
          ) : !isEmpty(bot) ? (
            <img src={`data:${botIconContentType};base64,${bot}`} width={73} />
          ) : (
            <img src="/images/default-bot.svg" height={63} width={73} />
          )}{" "}
        </div>
        <div className="text-center">
          <p className="bot-main-title">HOW CAN WE HELP YOU?</p>
        </div>
        <div className="mb-5">
          <button
            className="btn main-btn w-100 justify-content-between px-3"
            style={{ backgroundColor: buttonColor }}
            onClick={() => {}}
          >
            Ask us a new question
            <img src="/images/arrow-right.svg" className="me-2" />
          </button>
        </div>
        <div className="mb-3">
          <button
            className="btn main-btn w-100 justify-content-between px-3"
            style={{ backgroundColor: buttonColor }}
            onClick={() => {}}
          >
            Your previous chat
            <img src="/images/arrow-right.svg" className="me-2" />
          </button>
        </div>
        <div>
          <button
            className="btn main-btn w-100 justify-content-between px-3"
            style={{ backgroundColor: buttonColor }}
            onClick={() => {}}
          >
            Terms and Conditions
            <img src="/images/arrow-right.svg" className="me-2" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default BotMainView;
