
import WarningMessage from "./WarningMessage";
import TableChatSettings from "./TableChatSettings";
import NewCategoryModal from "./NewCategoryModal";
import { RoundedButton } from "components/Custom";
import React, { useState, useEffect } from 'react';

function Category({
  cachedBranding,
  isChecked,
  chatCategorization,
  refetchChatCategorization,
  page,
  setPage,
  categories,
  refetchCategories,
  setIsChecked,
}) {
  const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
  return (
    <div>
      <div className="normal-card">
        <div className="px-3">
          <div className="switch-ai-g">
            <p
              className="no-margin"
              style={{ color: cachedBranding?.page_TextColor }}
            >
              AI Generated Category
            </p>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                checked={isChecked}
                onChange={() => setIsChecked(!isChecked)}
              />
            </div>
          </div>
          {isChecked && <WarningMessage cachedBranding={cachedBranding} />}
        </div>
      </div>
      <div>
      {!isChecked && (
                  <div className="addcategory">
                    <RoundedButton
                      text="Add Category"
                      handleOnClick={() => setShowAddCategoryModal(true)}
                    />
                  </div>
                )}
                   <NewCategoryModal
        show={showAddCategoryModal}
        handleClose={() => {
          setShowAddCategoryModal(false);
        }}
        refetchCategories={refetchCategories}
      />
        {!isChecked && (
          <TableChatSettings
            chatCategorization={chatCategorization}
            refetchChatCategorization={refetchChatCategorization}
            page={page}
            setPage={setPage}
            refetchCategories={refetchCategories}
            categories={categories}
            cachedBranding={cachedBranding}
          />
        )}
      </div>
    </div>
  );
}

export default Category;
