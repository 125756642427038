import React, { useState } from "react";
import DataCardTable from "./DataCardTable";
import ChartModal from "./ChartModal";
import TableModal from "./TableModal";
import LineChart from "./LineChart";
import PieChart from "./PieChart";
import { useQueryClient } from "@tanstack/react-query";

function DataCard({ title, type, chart = "", style = {}, responses = [] ,consumption,setConsumption, selected,  dateFilterString}) {
  const queryClient = useQueryClient();
  const cachedBranding = queryClient.getQueryData(["branding"])?.data;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  return (
    <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
      <div className="consumption">
        <div className="title-chart">
          <h6 style={{ color: cachedBranding?.page_TextColor }}>{title}</h6>
        </div>
        {"image" === type && (
          <>
            <div className="chart-result">
              <img src={chart} style={style} />
            </div>
            <ChartModal show={show} handleClose={handleClose} chart={chart} />
          </>
        )}
        {"chart" === type && (
          <>
            <div className="chart-result my-5">
              <LineChart
                show={show}
                handleClose={handleClose}
                chart={chart}
                setShow={setShow}
                cachedBranding={cachedBranding}
                consumption={consumption}
                setConsumption={setConsumption}
                dateFilterString={dateFilterString}

              />
            </div>
          </>
        )}
        {"pie" === type && (
          <>
            <div className="chart-result my-5">
              <PieChart
                show={show}
                handleClose={handleClose}
                chart={chart}
                setShow={setShow}
                selected={selected}
                dateFilterString={dateFilterString}
              />
            </div>
          </>
        )}
        {"table" === type && (
          <>
            <DataCardTable
              responses={responses}
              cachedBranding={cachedBranding}
            />
            <TableModal
              show={show}
              handleClose={handleClose}
              responses={responses}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default DataCard;
