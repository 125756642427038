import React from "react";

function TierCard({ active, title, price, handleOnClick, item }) {
  return (
    <div
      className={`tier-card border border-2 shadow-sm rounded-4 p-3 mb-3 cursor-pointer ${
        active ? "border-primary" : ""
      }`}
      onClick={handleOnClick}
    >
      <div className="form-check">
        <div className="d-flex justify-content-between align-items-center mb-2 border-bottom p-2 px-0">
          <div className="d-flex">
            <input
              className="form-check-input"
              type="radio"
              name="exampleRadios"
              id="exampleRadios1"
              value="option1"
              checked={active}
            />
            <label className="form-check-label px-2 fs-5 fw-semibold">
              {title}
            </label>
          </div>

          <div className="d-flex flex-column">
            {active && <span className="active-plan d-none">Active Plan</span>}
            <span className="fw-semibold">{price}</span>
          </div>
        </div>
        <div className="tier-features d-flex justify-content-between">
          <div className="d-flex flex-column flex-fill">
            <span className="fw-semibold">{item?.gptVersion}</span>
            <span className="fw-semibold">{item?.tokens} Tokens</span>
            <span className="fw-semibold">{item?.consumption}</span>
            <span className="fw-semibold">{item?.integration}</span>
            <span className="fw-semibold">{item?.support}</span>
            <span>Admin Console</span>
          </div>
          <div className="d-flex flex-column flex-fill">
            {item?.services &&
              item?.services.map((service, index) => (
                <span key={index}>{service}</span>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TierCard;
