import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "constants";
import React from "react";
import { Modal } from "react-bootstrap";
import { cleanToken } from "utils";

function DeleteChatModal({
  show,
  handleClose,
  chat,
  refetchFavorites,
  refetchIndividualChats,
  refetchInstituteChats,
  refetchInstituteChatsGroup,
  refetchMaterialChatsGroup,
  refetchMaterialChats,
  handleSelect,
}) {
  const deleteChat = useMutation({
    mutationFn: () => {
      return axios.delete(
        chat?.chatId
          ? API_URL + `/Chat/DeleteChat/${chat?.chatId}`
          : API_URL + `/Chat/DeleteChat/${chat?.cd[0]?.chatId}`,
        {
          headers: {
            Authorization: `Bearer ${cleanToken(
              localStorage.getItem("token")
            )}`,
          },
        }
      );
    },
    onSuccess: async (data) => {
      console.log("data: ", data);
      handleSelect(null, "new");
      refetchFavorites();
      refetchIndividualChats();
      refetchInstituteChats();
      refetchInstituteChatsGroup();
      refetchMaterialChatsGroup();
      refetchMaterialChats();
      handleClose();
    },
    onError: (error) => {
      console.log("error: ", error);
    },
  });

  const handleDeleteChat = () => {
    deleteChat.mutate();
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <div className="modal-content">
        <div className="modal-header">
          <h1 className="modal-title fs-5" id="exampleModalLabel">
            Delete Chat
          </h1>
          <button
            type="button"
            className="btn-close"
            onClick={handleClose}
          ></button>
        </div>
        <div className="modal-body">
          Are you sure you want to delete the chat "{chat?.chatTitle}" ?
        </div>
        <div className="modal-footer d-flex flex-nowrap">
          <button
            type="button"
            className="btn normal-btn"
            onClick={handleClose}
          >
            No
          </button>
          <button
            type="button"
            className="btn delete-btn"
            onClick={() => handleDeleteChat()}
          >
            Yes
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default DeleteChatModal;
