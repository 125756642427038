import React from "react";

function SearchUser({ searchText, setSearchText }) {
  return (
    <div className="search-bar">
      <img src="/images/search.svg" className="search-ic" />
      <input
        className="search-inp"
        type="text"
        placeholder="Search User"
        onChange={(e) => setSearchText(e.target.value)}
        value={searchText}
      />
    </div>
  );
}

export default SearchUser;
