import React from "react";
import UsageDetail from "./UsageDetail";
import moment from "moment";

function Usage({ active, user, type, cachedBranding }) {
  return (
    <div className={`tab-pane fade ${active ? "show active" : ""}`}>
      {"user" == type ? (
        <div className="details">
          <UsageDetail
            item="Last Login"
            value={
              user.lastLogin == null
                ? "Never"
                : moment(new Date(user?.lastLogin)).fromNow()
            }
            cachedBranding={cachedBranding}
          />
          <UsageDetail
            item="Responses Sent Token Usage"
            value={user?.tokenSent ? user?.tokenSent : "0"}
            cachedBranding={cachedBranding}
          />
          <UsageDetail
            item="Responses Received Token Usage"
            value={user?.tokenRecieved ? user?.tokenRecieved : "0"}
            cachedBranding={cachedBranding}
          />
          <UsageDetail
            item="Average Token Used Daily"
            value={user?.averageToken ? user?.averageToken : "0"}
            cachedBranding={cachedBranding}
          />
          <UsageDetail
            item="Total of Token used since joined"
            value={
              user?.tokenSent + user?.tokenRecieved
                ? user?.tokenSent + user?.tokenRecieved
                : "0"
            }
            cachedBranding={cachedBranding}
          />
        </div>
      ) : (
        <div className="details">
          {/* <UsageDetail item="Popular Topics" value='0' />
          <UsageDetail item="Last Asked Question" value='0' /> */}
          <UsageDetail
            item="Total Token Usage"
            value={
              user?.tokenSent + user?.tokenRecieved
                ? user?.tokenSent + user?.tokenRecieved
                : "0"
            }
            cachedBranding={cachedBranding}
          />
        </div>
      )}
    </div>
  );
}

export default Usage;
