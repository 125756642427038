import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "constants";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { cleanToken } from "utils";
import toast from "react-hot-toast";
import SpinnerCircle from "components/Custom/SpinnerCircle";

function UploadUrlModal({
  active,
  handleClose,
  refetch,
  folderId,
  folderName = "",
  cachedBranding,
}) {
  const [urlName, setUrlName] = useState("");
  const [url, setUrl] = useState("");

  const addUrl = useMutation({
    mutationFn: (fields) => {
      return axios.post(API_URL + `/QNA/CreateProjectSource`, fields, {
        headers: {
          Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
        },
      });
    },
    onSuccess: async (data) => {
      getQNA.mutate(data?.data?.materialId);
    },
    onError: (error) => {
      console.log("error: ", error);
      toast.error("Something went wrong!");
    },
  });

  const getQNA = useMutation({
    mutationFn: (materialId) => {
      return axios.get(API_URL + `/QNA/FillQNA/${folderId}/${materialId}`, {
        headers: {
          Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
        },
      });
    },
    onSuccess: async (data) => {
      handleClose();
      refetch();
      toast.success("Uploaded successfully");
    },
    onError: (error) => {
      console.log("error: ", error);
    },
  });

  const handleSubmit = () => {
    addUrl.mutate({
      folderId: folderId,
      folderName: folderName,
      fileName: url,
      uploadtype: "url",
      isStructured: false,
      url: url,
    });
  };

  return (
    <Modal
      show={active}
      onHide={handleClose}
      className="topics-categories"
      centered
      size="lg"
    >
      <div className="modal-content">
        <div className="modal-header">
          <h5
            className="modal-title"
            style={{ color: cachedBranding?.page_TextColor }}
          >
            Upload URL's
          </h5>
          <div className="modal-option">
            <button type="button" className="btn" onClick={handleClose}>
              <img src="/images/close-modal.svg" />
            </button>
          </div>
        </div>
        <div className="modal-body">
          <div className="d-flex align-items-center mb-3">
            <div className="form-group profile-field w-50 mx-2">
              <label
                className="fw-medium"
                style={{ color: cachedBranding?.page_TextColor }}
              >
                URL Name
              </label>
              <input
                type="text"
                className="form-control profile-input"
                placeholder="Enter Url Name"
                value={urlName}
                onChange={(e) => setUrlName(e.target.value)}
              />
            </div>
            <div className="form-group profile-field w-50 mx-2">
              <label
                className="fw-medium"
                style={{ color: cachedBranding?.page_TextColor }}
              >
                URL
              </label>
              <input
                type="text"
                className="form-control profile-input"
                placeholder="Paste Url"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
              />
            </div>
          </div>
          <div className="d-flex align-items-center mb-3">
            <label
              className="fw-medium  mb-3"
              style={{ color: cachedBranding?.page_TextColor }}
            >
              The upload process will take time. Please wait till the modal
              closes.
            </label>
          </div>
        </div>

        <div className="modal-footer d-flex align-items-end">
          {addUrl.isLoading ? (
            <SpinnerCircle />
          ) : (
            <>
              <button
                type="button"
                className="btn main-btn w-25 mx-3"
                onClick={handleSubmit}
                style={{ backgroundColor: cachedBranding?.page_ButtonColor }}
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-secondary w-25"
                onClick={handleClose}
              >
                Cancel
              </button>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default UploadUrlModal;
