import { useFetchUserImage } from "hooks/users";
import { isEmpty } from "lodash";
import React, { useEffect } from "react";

function UserIcon({
  userId,
  image,
  name,
  type,
  height,
  width,
  cachedBranding,
}) {
  const { data: userImage, refetch: refetchImage } = useFetchUserImage(userId);

  useEffect(() => {
    if (userId.length) {
      refetchImage();
    }
  }, [userId]);

  return (
    <div className="user-name">
      {!isEmpty(userImage?.data?.imageBytes) ? (
        <img
          src={`data:image/jpeg;base64,${userImage?.data?.imageBytes}`}
          width="40"
          height="40"
          className="rounded-circle"
        />
      ) : (
        <img
          src="/images/user-empty.svg"
          alt="hugenerd"
          width="40"
          height="40"
          className="rounded-circle"
        />
      )}
      <span style={{ color: cachedBranding?.page_TextColor }}>{name}</span>
    </div>
  );
}

export default UserIcon;
