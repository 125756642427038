import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "constants";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { cleanToken } from "utils";

function EditTagsCard({ group, refetchGroup, cachedBranding }) {
  const [tagText, setTagText] = useState("");

  const deleteTag = useMutation({
    mutationFn: (tagId) => {
      return axios.post(
        API_URL + `/Group/RemoveTagFromGroup/${tagId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${cleanToken(
              localStorage.getItem("token")
            )}`,
          },
        }
      );
    },
    onSuccess: async (data) => {
      refetchGroup();
      toast.success("Tag successfully deleted")
    },
    onError: (error) => {
      console.log("error: ", error);
    },
  });

  const addTag = useMutation({
    mutationFn: () => {
      return axios.post(
        API_URL + `/Group/AddTagToGroup/${tagText}/${group?.groupId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${cleanToken(
              localStorage.getItem("token")
            )}`,
          },
        }
      );
    },
    onSuccess: async (data) => {
      setTagText("");
      refetchGroup();
      toast.success("Tag successfully added")

    },
    onError: (error) => {
      console.log("error: ", error);
    },
  });

  const handleDeleteTag = (tagId) => {
    deleteTag.mutate(tagId);
  };

  const handleAdd = () => {
    addTag.mutate();
  };

  return (
    <div className="data-option">
      <h5
        className="fw-semibold mb-3"
        style={{ color: cachedBranding?.page_TextColor }}
      >
        Edit Tags
      </h5>
      <div className="input-group align-items-center mb-4">
        <input
          type="text"
          className="form-control newgroup-input p-1 px-3 rounded-pill"
          placeholder="Enter tag"
          value={tagText}
          onChange={(e) => setTagText(e.target.value)}
        />
        <button
          className="btn shadow-sm main-btn p-3 w-500 rounded-pill ms-3"
          type="button"
          id="button-addon2"
          onClick={() => handleAdd()}
          style={{ backgroundColor: cachedBranding?.page_ButtonColor }}
        >
          Add Tag
        </button>
      </div>
      <div className="details-name">
        <div className="d-flex flex-wrap group-topics mb-4 mt-3">
          {!isEmpty(group) &&
            group?.groupTagResponse.map((tag, index) => (
              <span key={index} className="m-1">
                {tag?.tagName}{" "}
                <img
                  src="/images/remove.svg"
                  className="remove-btn cursor-pointer"
                  onClick={() => handleDeleteTag(tag?.groupTagId)}
                />
              </span>
            ))}
        </div>
      </div>
    </div>
  );
}

export default EditTagsCard;
