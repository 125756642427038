import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { isEmpty } from "lodash";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API_URL } from "constants";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import { cleanToken } from "utils";
import { useFetchRoles } from "hooks/roles";
import toast from "react-hot-toast";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  displayEmail: Yup.string()
    .email("Invalid Email")
    .required("Email is required"),
  phone: Yup.string().required("Phone is required"),
  mobile: Yup.string().required("Mobile is required"),
  department: Yup.string().notRequired(),
  username: Yup.string().required("Username is required"),
  password: Yup.string().min(6, "Password must be at least 6 characters"),
  roleId: Yup.number().required("Role is required"),
});

function EditUserModal({
  show,
  handleClose,
  selectedUser,
  refetchUsers,
  editForm,
}) {
  const queryClient = useQueryClient();
  const cachedBranding = queryClient.getQueryData(["branding"])?.data;
  const { data: roles } = useFetchRoles();
  const initialValues = selectedUser || {
    firstName: "",
    lastName: "",
    displayEmail: "",
    phone: "",
    mobile: "",
    department: "",
    username: "",
    password: "",
    roleId: selectedUser
      ? Number(selectedUser.roleId)
      : roles?.data[0]?.roleId || 0,
  };

  const addUser = useMutation({
    mutationFn: (fields) => {
      return axios.post(API_URL + `/User/CreateUser`, fields, {
        headers: {
          Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
        },
      });
    },
    onSuccess: async (data) => {
      console.log("data: ", data);
      refetchUsers();
      handleClose();
      toast.success("Successfully added")
    },
    onError: (error) => {
      toast.error("Username or Email already exits", {
        duration: 8000,
      });
      console.log("error: ", error);
    },
  });

  const editUser = useMutation({
    mutationFn: (fields) => {
      return axios.put(
        API_URL + `/User/EditUser/${selectedUser?.userId}`,
        fields,
        {
          headers: {
            Authorization: `Bearer ${cleanToken(
              localStorage.getItem("token")
            )}`,
          },
        }
      );
    },
    onSuccess: async (data) => {
      console.log("data: ", data);
      if(refetchUsers){
        refetchUsers();
      }
      handleClose();
      toast.success("Successfully updated")
    },
    onError: (error) => {
      console.log("error: ", error);
    },
  });

  const handleSubmit = (data) => {
    console.log("data: ", data);
    if (selectedUser) {
      editUser.mutate(data);
      return;
    }
    addUser.mutate(data);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  const handleRoleIdChange = (event) => {
    const { value } = event.target;
    formik.setFieldValue("roleId", value);
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <div className="modal-content">
              <div className="modal-header">
                <h4
                  className="modal-title"
                  style={{ color: cachedBranding?.page_TextColor }}
                >
                  {!isEmpty(selectedUser) ? "Edit User" : "Add User"}
                </h4>
                <div className="modal-option">
                  <button
                    type="button"
                    className="btn"
                    data-bs-dismiss="modal"
                    onClick={handleClose}
                  >
                    <img src="/images/close-modal.svg" />
                  </button>
                </div>
              </div>
              <div className="modal-body editProfile">
                <div className="form-group profile-field">
                  <label style={{ color: cachedBranding?.page_TextColor }}>
                    First Name
                  </label>
                  <Field
                    type="text"
                    className="form-control profile-input"
                    name="firstName"
                  />
                  <ErrorMessage
                    name="firstName"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="form-group profile-field">
                  <label style={{ color: cachedBranding?.page_TextColor }}>
                    Last Name
                  </label>
                  <Field
                    type="text"
                    className="form-control profile-input"
                    name="lastName"
                  />
                  <ErrorMessage
                    name="lastName"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="form-group profile-field">
                  <label style={{ color: cachedBranding?.page_TextColor }}>
                    Email
                  </label>
                  <Field
                    type="email"
                    className="form-control profile-input"
                    name="displayEmail"
                  />
                  <ErrorMessage
                    name="displayEmail"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="form-group profile-field">
                  <label style={{ color: cachedBranding?.page_TextColor }}>
                    Mobile
                  </label>
                  <Field
                    type="text"
                    className="form-control profile-input"
                    name="mobile"
                  />
                  <ErrorMessage
                    name="mobile"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="form-group profile-field">
                  <label style={{ color: cachedBranding?.page_TextColor }}>
                    Phone
                  </label>
                  <Field
                    type="text"
                    className="form-control profile-input"
                    name="phone"
                  />
                  <ErrorMessage
                    name="phone"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="form-group profile-long">
                  <label style={{ color: cachedBranding?.page_TextColor }}>
                    Department
                  </label>
                  <Field
                    type="text"
                    className="form-control profile-input"
                    name="department"
                  />
                  <ErrorMessage
                    name="department"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="form-group profile-field">
                  <label style={{ color: cachedBranding?.page_TextColor }}>
                    Username
                  </label>
                  <Field
                    type="text"
                    className="form-control profile-input"
                    name="username"
                  />
                  <ErrorMessage
                    name="username"
                    component="div"
                    className="error-message"
                  />
                </div>
                {editForm && (
                  <div className="form-group profile-field">
                    <label style={{ color: cachedBranding?.page_TextColor }}>
                      Password
                    </label>
                    <Field
                      type="password"
                      className="form-control profile-input"
                      name="password"
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="error-message"
                    />
                  </div>
                )}
                <div className="form-group profile-long">
                  <label style={{ color: cachedBranding?.page_TextColor }}>
                    Role
                  </label>
                  <Field
                    as="select"
                    className="form-control profile-input"
                    name="roleId"
                  >
                    {roles?.data.map((role) => (
                      <option key={role.roleId} value={role.roleId}>
                        {role.roleName}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="roleId"
                    component="div"
                    className="error-message"
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn main-btn"
                  style={{ backgroundColor: cachedBranding?.page_ButtonColor }}
                >
                  Save changes
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default EditUserModal;
