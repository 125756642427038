import { Sidebar } from "components";
import { BackButton } from "components/Custom";
import { EditGroupCard, EditTagsCard, EditUsersCard } from "components/Users";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useFetchGroupById } from "hooks/groups";
import { isEmpty } from "lodash";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "constants";
import { cleanToken } from "utils";
import toast from "react-hot-toast";

function EditGroup() {
  const { groupId } = useParams();
  const queryClient = useQueryClient();
  const cachedBranding = queryClient.getQueryData(["branding"])?.data;

  const { data: group, refetch: refetchGroup } = useFetchGroupById(groupId);
  const [groupName, setGroupName] = useState("");

  const navigate=useNavigate()
  const editGroupName = useMutation({
    mutationFn: (fields) => {
      return axios.post(
        API_URL +
          `/Group/EditGroupName/${group?.data[0]?.groupId}/${groupName}`,
        fields,
        {
          headers: {
            Authorization: `Bearer ${cleanToken(
              localStorage.getItem("token")
            )}`,
          },
        }
      );
    },
    onSuccess: async (data) => {
      refetchGroup();
      setGroupName("");
      toast.success("Group successfully updated")
    },
    onError: (error) => {
      console.log("error: ", error);
    },
  });

  const handleSubmit = () => {
    editGroupName.mutate();
  };

  useEffect(() => {
    if (!isEmpty(group?.data)) {
      setGroupName(group?.data[0]?.groupName);
    }
  }, [group]);

  return (
    <div className="container-fluid">
      <div className="row flex-nowrap">
        <Sidebar handleTourClick={() =>
          navigate("/users", { state: { showAddUser: true } })
        } />
        <div className="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-2"></div>
        <div className="col-10 col-sm-9 col-md-9 col-lg-9 col-xl-10 px-sm-5">
          <div className="dashboard">
            <div className="page-title">
              <div className="row w-100">
                <div className="col-7 col-lg-7 col-xl-8 d-flex align-items-center mb-4">
                  <BackButton />
                  <div className="main-title">
                    <h3 style={{ color: cachedBranding?.page_TextColor }}>
                      Group
                    </h3>
                  </div>
                </div>

                <div className="col-5 col-lg-5 col-xl-4 filter">
                  <div className="add-user">
                    <a
                      href="#"
                      className="btn main-btn"
                      style={{
                        backgroundColor: cachedBranding?.page_ButtonColor,
                      }}
                      onClick={handleSubmit}
                    >
                      Save Group
                    </a>
                  </div>
                </div>

                <div className="col-12">
                  <div className="row">
                    <div className="col-6">
                      <EditGroupCard
                        groupName={groupName}
                        setGroupName={setGroupName}
                        refetchGroup={refetchGroup}
                        groupId={groupId}
                        cachedBranding={cachedBranding}
                      />
                      <EditTagsCard
                        group={group?.data[0]}
                        refetchGroup={refetchGroup}
                        cachedBranding={cachedBranding}
                      />
                    </div>
                    <div className="col-6">
                      <EditUsersCard
                        group={group?.data[0]}
                        refetchGroup={refetchGroup}
                        cachedBranding={cachedBranding}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditGroup;
