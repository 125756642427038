import { RoundedButton } from "components/Custom";
import { isEmpty } from "lodash";
import React from "react";

function BotLoginView({
  bot,
  tempBot,
  backgroundColor,
  botColor,
  buttonColor,
  botIconContentType,
}) {
  return (
    <div
      className="login-view mt-4 p-3"
      style={{ backgroundColor: backgroundColor }}
    >
      <div className="row">
        <div className="col-7"></div>
        <div className="col-5">
          <img src="/images/bot-maximiza.png" className="px-1 cursor-pointer" />
          <img src="/images/bot-minimize.png" className="px-1 cursor-pointer" />
          <img src="/images/bot-close.png" className="px-1 cursor-pointer" />
        </div>
      </div>
      <div className="w-100 mt-5">
        <div className="text-center mb-4">
          {tempBot ? (
            <img
              src={URL.createObjectURL(tempBot)}
              alt="Selected Bot"
              width={73}
            />
          ) : !isEmpty(bot) ? (
            <img src={`data:${botIconContentType};base64,${bot}`} width={73} />
          ) : (
            <img src="/images/default-bot.svg" height={63} width={73} />
          )}
        </div>
        <div className="mb-4">
          <label className="form-label fw-medium mb-2">Email Address</label>
          <input
            type="email"
            className="form-control profile-input p-3"
            value=""
          />
        </div>
        <div className="mb-5">
          <label className="form-label fw-medium mb-2">Password</label>
          <input
            type="password"
            className="form-control profile-input p-3"
            value=""
          />
        </div>
        <div>
          <RoundedButton
            text="Login"
            handleOnClick={() => {}}
            className="w-100"
            color={buttonColor}
          />
        </div>
      </div>
    </div>
  );
}

export default BotLoginView;
