import ReactApexChart from "react-apexcharts";
import { useCategories, useCategoriesByGroup } from "hooks/Dashboard";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ChartModal from "./ChartModal";

function PieChart({
  show,
  handleClose,
  chart,
  setShow,
  selected,
  dateFilterString,
}) {
  const navigate = useNavigate();

  const [consumption, setConsumption] = useState([]);
  const { data: consumptionData, refetch: refetchConsumption } =
    useCategories(dateFilterString);

  const { data: consumptionDataGroup, refetch: refetchConsumptionGroup } =
    useCategoriesByGroup(dateFilterString);
  useEffect(() => {
    if (selected == "Users") {
      if (consumptionData?.data) {
        const token = consumptionData.data.map((c) => c?.amount || 0);
        setConsumption(token);
      }
    } else {
      if (consumptionDataGroup?.data) {
        const token = consumptionDataGroup.data.map((c) => c?.amount || 0);
        setConsumption(token);
      }
    }
  }, [consumptionData, selected]);

  const chartSettings = {
    series: consumption,
    options: {
      chart: {
        type: "polarArea",
        toolbar: {
          show: true,
          tools: {
            show: false,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
            download: `<img src="/images/download-ico.svg" />`,
            customIcons: [
              {
                icon: `<img src="/images/full-screen-ico.svg" height="14" width="14"  class="ps-2" />`,
                index: 0,
                click: () => setShow(true),
                title: "Zoom In",
              },
            ],
          },
        },
      },
      yaxis: {
        labels: {
          formatter: (value) => {
            return value.toFixed(1);
          },
        },
      },
      labels: consumptionData?.data.map((c) => c?.categoryText) || [],
      legend: {
        show: false,
      },
    },
  };

  return (
    <div>
      {consumptionData ? (
        <>
          <ReactApexChart
            options={chartSettings?.options}
            series={chartSettings?.series}
            type="polarArea"
            height={350}
          />
          <ChartModal
            show={show}
            handleClose={handleClose}
            chart={chart}
            child={
              <ReactApexChart
                options={chartSettings.options}
                series={chartSettings.series}
                type="polarArea"
                height={350}
              />
            }
            title="Topics / Categories"
          />
        </>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
}

export default PieChart;
