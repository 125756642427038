import React from "react";

function UsageDetail({ item, value, cachedBranding }) {
  return (
    <div className="details-name">
      <span style={{ color: cachedBranding?.page_TextColor }}>{item}</span>
      <p>{value ? value : "N/A"}</p>
    </div>
  );
}

export default UsageDetail;
