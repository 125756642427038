import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { PublicRoute, PrivateRoute } from "routes";
import "bootstrap/dist/css/bootstrap.min.css";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
  useQueryClient,
} from "@tanstack/react-query";
import {
  Dashboard,
  Subscription,
  Users,
  NewGroup,
  ChatConsole,
  Profile,
  CompanyProfile,
  EditGroup,
  Login,
} from "./pages";
import { TokenRestrictions, Script, Branding } from "pages/Settings";
import { Materials, MaterialFile, MaterialFolder } from "pages/Materials";
import {
  MaterialCustomQna,
  Qna,
  QnaMaterialFile,
  // MaterialFile,
  QnaMaterialFolder,
  // MaterialCustomQna,
} from "pages/Qna";
import { CustomerDetails, CustomerList } from "pages/Admin";
import toast, { Toaster } from "react-hot-toast";
import "App.css";
import ChatSettings from "pages/ChatSettings";
import { useEffect } from "react";
import { LoadingOverlay } from "components/Custom";
import SecurityPolicies from "pages/SecurityPolicies";
import { Model, Models } from "pages/SubjectiveAnswers";

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: async (error) => {
      if (401 === error?.response?.status) {
        localStorage.removeItem("token");
        toast.dismiss();
        toast.error("Session Expired. Please Login Again!", {
          duration: 10000,
          style: {
            background: "#FFEAEA",
          },
        });
      }
    },
  }),
  mutations: {},
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Toaster position="top-center" reverseOrder={false} />
      <Router>
        <LoadingOverlay>
          <Routes>
            <Route path="/" element={<PrivateRoute />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/users" element={<Users />} />
              <Route path="/subscription" element={<Subscription />} />
              <Route path="/new-group" element={<NewGroup />} />
              <Route path="/chat-console" element={<ChatConsole />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/company-profile" element={<CompanyProfile />} />
              <Route path="/edit-group/:groupId" element={<EditGroup />} />
            </Route>
            <Route path="/settings" element={<PrivateRoute />}>
              <Route
                path="token-restrictions"
                element={<TokenRestrictions />}
              />
              <Route path="qna" element={<Qna />} />
              <Route path="script" element={<Script />} />
              <Route path="branding" element={<Branding />} />
              <Route path="chat-settings" element={<ChatSettings />} />
              <Route path="Security-policies" element={<SecurityPolicies />} />
            </Route>
            <Route path="/qna" element={<PrivateRoute />}>
              <Route
                path="qna-material-folder/:folderId"
                element={<QnaMaterialFolder />}
              />
              <Route
                path="qna-material-file/:materialId"
                element={<QnaMaterialFile />}
              />
              <Route
                path="custom-qna/:materialId"
                element={<MaterialCustomQna />}
              />
            </Route>
            <Route path="/materials" element={<PrivateRoute />}>
              <Route path="manage-materials" element={<Materials />} />
              <Route
                path="material-folder/:containerId"
                element={<MaterialFolder />}
              />
            </Route>
            <Route path="/admin" element={<PrivateRoute />}>
              <Route path="customer-list" element={<CustomerList />} />
              <Route path="customer-details" element={<CustomerDetails />} />
            </Route>
            <Route path="/subjective-answers" element={<PrivateRoute />}>
              <Route path="models" element={<Models />} />
              <Route path="model" element={<Model />} />
            </Route>
            <Route path="/" element={<PublicRoute />}>
              <Route index element={<Login />} />
            </Route>
          </Routes>
        </LoadingOverlay>
      </Router>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  );
}

export default App;
