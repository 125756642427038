import React from "react";
import { Modal } from "react-bootstrap";

function AddPlugin({ show, handleClose, handleSubmit }) {
  return (
    <Modal show={show} onHide={handleClose} animation={false}>
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Add Plug-in</h4>
          <div className="modal-option">
            <button type="button" className="btn" onClick={handleClose}>
              <img src="/images/close.svg" />
            </button>
          </div>
        </div>
        <div className="modal-body editProfile">
          <div className="row">
            <div className="col-6">
              <div className="token-card justify-content-center w-100 cursor-pointer">
                <div className="token-details">
                  <h3>Voice Record</h3>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="token-card justify-content-center w-100 cursor-pointer">
                <div className="token-details">
                  <h3>Image</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer border-0 justify-content-center">
          <button
            type="button"
            className="btn cancel-btn rounded-pill w-25 py-2 px-4"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn main-admin-btn rounded-pill w-25 py-2 px-4"
            onClick={handleSubmit}
          >
            Confirm
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default AddPlugin;
