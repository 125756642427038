import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "constants";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { cleanToken } from "utils";

function UploadCsv({ show, handleClose, refetchUsers }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const queryClient = useQueryClient();
  const cachedBranding = queryClient.getQueryData(["branding"])?.data;

  const addFile = useMutation({
    mutationFn: (fields) => {
      return axios.post(API_URL + `/user/UploadUsers`, fields, {
        headers: {
          Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
        },
      });
    },
    onSuccess: async (data) => {
      handleClose();
      if (refetchUsers) {
        refetchUsers();
      }
      toast.success("Uploaded Successfully");
    },
    onError: (error) => {
      console.log("error: ", error);
      toast.error("Something went wrong.");
    },
  });

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const file = e.dataTransfer.files[0];

    if (file) {
      const formData = new FormData();
      formData.append("FileToUpload", file);
      setSelectedFile(file);
      addFile.mutate(formData);
    }
  };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("FileToUpload", file);
    if (file) {
      setSelectedFile(file);
      addFile.mutate(formData);
    }
  };

  const handleFileButtonClick = () => {
    document.getElementById("fileInput").click();
  };

  const preventDefault = (e) => {
    e.preventDefault();
  };

  const handleDownloadTemplate = () => {
    const filePath = "/documents/Users.xlsx";
    const a = document.createElement("a");
    a.href = process.env.PUBLIC_URL + filePath;
    a.download = "Users.xlsx";
    a.click();
  };

  return (
    <Modal show={show} onHide={handleClose} centered size="lg">
      <div className="modal-content">
        <div className="modal-header">
          <h5
            className="modal-title"
            style={{ color: cachedBranding?.page_TextColor }}
          >
            Upload CSV
          </h5>
          <div className="modal-option">
            <button type="button" className="btn" onClick={handleClose}>
              <img src="/images/close-modal.svg" />
            </button>
          </div>
        </div>
        <div className="modal-body">
          <div className="upload-border d-flex justify-content-between mb-2">
            <div className="d-flex w-75">
              <img src="/images/upload-file.svg" />
              <div
                className="d-flex justify-content-center flex-column mx-3"
                onDragOver={preventDefault}
                onDrop={handleDrop}
              >
                <h6 style={{ color: cachedBranding?.page_TextColor }}>
                  Drag and Drop or Upload
                </h6>
              </div>
            </div>
            <button
              className="main-btn w-25"
              onClick={handleFileButtonClick}
              style={{ backgroundColor: cachedBranding?.page_ButtonColor }}
            >
              Upload
            </button>
            <input
              type="file"
              id="fileInput"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              onChange={handleFileSelect}
              style={{ display: "none" }}
            />
          </div>
          <div className="py-2">
            <a
              className="text-decoration-underline main-text cursor-pointer"
              onClick={handleDownloadTemplate}
            >
              Download CSV Template
            </a>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default UploadCsv;
