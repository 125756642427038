import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "constants";
import { useFetchUserImage } from "hooks/users";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { cleanToken } from "utils";

function UserItem({ type, user, handleDelete, cachedBranding }) {
  const [show, setShow] = useState(false);
  const { data: userImage, refetch: refetchImage } = useFetchUserImage(
    user?.userId
  );

  useEffect(() => {
    if (!isEmpty(user)) {
      refetchImage();
    }
  }, [user]);

  const popoverClickRootClose = (
    <Popover id="popover-trigger-click-root-close" className="dropdown-menu">
      <button
        className="dropdown-item chat-dropdown"
        type="button"
        onClick={() => handleDelete(user.userId)}
      >
        Remove User
      </button>{" "}
    </Popover>
  );
  return (
    <>
      {"user" === type ? (
        <div className="member-card">
          <div className="member-name">
            <img
              src={
                !isEmpty(userImage?.data?.imageBytes)
                  ? `data:image/jpeg;base64,${userImage?.data?.imageBytes}`
                  : "/images/user-empty.svg"
              }
            />{" "}
            <span
              style={{
                color: cachedBranding?.page_TextColor,
              }}
            >
              {user?.fullName}
            </span>
          </div>
          <div className="dropdown">
            <OverlayTrigger
              trigger="click"
              rootClose
              placement="bottom"
              overlay={popoverClickRootClose}
            >
              <img src="/images/more-chat.svg" style={{ width: "5px" }} />
            </OverlayTrigger>
          </div>
        </div>
      ) : "temporaryAdmin" ? (
        <div className="admin-card">
          <div className="admin-name">
            <img
              src={
                !isEmpty(userImage?.data?.imageBytes)
                  ? `data:image/jpeg;base64,${userImage?.data?.imageBytes}`
                  : "/images/user-empty.svg"
              }
            />
            <span
              style={{
                color: cachedBranding?.page_TextColor,
              }}
            >
              {user?.fullName}
            </span>
          </div>
          <div className="d-flex align-items-center">
            {/* <span className="mx-3 fst-italic" style={{ fontSize: "12px" }}>
              Temporary Admin
            </span> */}
            <div className="dropdown ">
              <OverlayTrigger
                trigger="click"
                rootClose
                placement="bottom"
                overlay={popoverClickRootClose}
              >
                <img src="/images/more-chat.svg" style={{ width: "5px" }} />
              </OverlayTrigger>
            </div>
          </div>
        </div>
      ) : (
        <div className="admin-card">
          <div className="admin-name">
            <img src="/images/user1.png" />
            <span
              style={{
                color: cachedBranding?.page_TextColor,
              }}
            >
              {user?.fullName}
            </span>
          </div>
          <span className="fst-italic" style={{ fontSize: "12px" }}>
            Admin
          </span>
        </div>
      )}
    </>
  );
}

export default UserItem;
