import React, { useEffect, useState } from "react";
import RatingProgresBar from "./ProgressBar";
import { useRatings, useRatingsByGroup } from "hooks/Dashboard";

function RatingsCard({ cachedBranding, selected , dateFilterString}) {
  const [averageRating, setAverageRating] = useState(0);
  const [ratingData, setRatingData] = useState([]);
  const { data: ratingsData, refetch: refetchRatings } = useRatings(dateFilterString);
  const { data: groupRatingsData, refetch: refetchGroupRatings } = useRatingsByGroup(dateFilterString);
  useEffect(() => {
    if(selected == "Users"){
      setAverageRating(ratingsData?.data?.averageRating?.toFixed(2));
      setRatingData(ratingsData?.data?.ratings)
    }else{
      setAverageRating(groupRatingsData?.data?.averageRating?.toFixed(2));
      setRatingData(groupRatingsData?.data?.ratings)
    }

  }, [ratingsData, groupRatingsData,selected]);


  useEffect(() => {
    refetchRatings();
    refetchGroupRatings();
  }, [dateFilterString]);

  return (
    <div className="data-ratings">
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-8 col-xl-8">
          <div className="ratings">
            <div className="title-chart">
              <h6 style={{ color: cachedBranding?.page_TextColor }}>
                Response Ratings
              </h6>
            </div>
            <div className="rating-result">
              <div className="total-result">
                <h1 style={{ color: cachedBranding?.page_TextColor }}>
                  {averageRating}
                </h1>
                <img src="/images/star-rating.svg" />
                <span style={{ color: cachedBranding?.page_TextColor }}>
                  Average Rating
                </span>
              </div>
              <div className="chart-rating">
                {ratingData?.map((rating, index) => (
                  <RatingProgresBar
                    key={index}
                    widthPercentage={rating.ratingAverage}
                  />
                ))}
              </div>
              <div className="stars">
                <img src="/images/5-star.svg" />
                <img src="/images/4-star.svg" />
                <img src="/images/3-star.svg" />
                <img src="/images/2-star.svg" />
                <img src="/images/1-star.svg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RatingsCard;
