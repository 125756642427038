import React from "react";

function Invoices({ invoices }) {
  return (
    <div className="normal-admin-card">
      <h5>Invoices</h5>
      <div className="table-result">
        <div className="table-responsive-sm">
          <table className="table table-striped table-borderless table-name">
            <thead>
              <tr>
                <th scope="col" className="table-name">
                  Date
                </th>
                <th scope="col" className="table-name">
                  Invoice
                </th>
                <th scope="col" className="table-name">
                  Total Amount
                </th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {invoices &&
                invoices.map((invoice, index) => (
                  <tr key={index}>
                    <th scope="row" className="table-details w-25">
                      {invoice?.date}
                    </th>
                    <td className="w-25">{invoice?.invoiceNumber}</td>
                    <td className="table-details w-25">{invoice?.total}</td>
                    <td className="w-25">
                      <button className="btn">
                        <img src="/images/download-invoice.svg" />
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Invoices;
