import { Sidebar } from "components";
import { PageTitle, TabButton } from "components/Custom";
import { useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { Password, Session, SignIn } from "components/SecurityPolicies";
import { AnswersModel, CreateModelModal } from "components/SubjectiveAnswers";

function Models() {
  const location = useLocation();
  const queryClient = useQueryClient();
  const cachedBranding = queryClient.getQueryData(["branding"])?.data;
  const [showCreateModal, setShowCreateModal] = useState(false);

  return (
    <div
      className="container-fluid"
      style={{
        backgroundColor: cachedBranding?.page_BackgroundColor,
        minHeight: "100vh",
      }}
    >
      <div className="row flex-nowrap">
        <Sidebar handleTourClick={() => {}} />
        <div className="col-2 col-sm-3 col-md-2 col-lg-3 col-xl-2"></div>
        <div className="col-10 col-sm-9 col-md-10 col-lg-9 col-xl-10 px-sm-5">
          <div className="dashboard">
            <div className="page-title px-5">
              <div className="row w-100">
                <PageTitle
                  title="Subjective Answers"
                  time="29 May 2023 - 4:02 PM"
                />
                <div className="col-12 mt-4">
                  <div className="normal-card p-0">
                    <div className="border-bottom">
                      <div className="px-3 py-4">
                        <div className="row justify-content-between">
                          <div className="col-6 d-flex align-items-center">
                            <span
                              className="models-title"
                              style={{ color: cachedBranding?.page_TextColor }}
                            >
                              Models
                            </span>
                            <div className="search-bar w-50 ms-5">
                              <img
                                src="/images/search.svg"
                                className="search-ic2"
                              />
                              <input
                                className="search-inp inp-3"
                                type="text"
                                placeholder="Search User"
                              />
                            </div>
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-end cursor-pointer"
                            onClick={() => setShowCreateModal(true)}
                          >
                            <img src="/images/model-icon.svg" />
                            <span
                              className="ms-2 create-model"
                              style={{ color: cachedBranding?.page_TextColor }}
                            >
                              Create Model
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="px-3 py-4">
                      <div className="row">
                        <AnswersModel />
                        <AnswersModel />
                        <AnswersModel />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CreateModelModal
        show={showCreateModal}
        handleClose={() => setShowCreateModal(false)}
        cachedBranding={cachedBranding}
      />
    </div>
  );
}

export default Models;
