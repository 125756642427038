import React, { useState } from "react";
import OrderSummaryModal from "./OrderSummaryModal";
import AdditionalConsumptionsModal from "./AdditionalConsumptionsModal";

function SpecialTierCard({ plan, cachedBranding }) {
  const [showOrderSummary, setShowOrderSummary] = useState(false);
  const [showAdditionalConsumption, setShowAdditionalConsumption] =
    useState(false);
  const [selectedAddOn, setSelectedAddOn] = useState(null);

  return (
    <div className="normal-card d-flex flex-xl-row flex-lg-column flex-md-column justify-content-between">
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <div className="add-on-area flex-fill mb-md-3">
            <h5
              className="fw-semibold mb-3"
              style={{ color: cachedBranding?.page_TextColor }}
            >
              Add ons
            </h5>
            <div className="add-on-price">
              {plan?.addsOn &&
                plan?.addsOn.map((addOn, index) => (
                  <button
                    className="btn"
                    onClick={() => (
                      setShowOrderSummary(true), setSelectedAddOn(addOn)
                    )}
                    key={index}
                  >
                    <div className="add-on-card">
                      <div className="d-flex justify-content-between">
                        <h6
                          className="fw-semibold text-start"
                          style={{ color: cachedBranding?.page_TextColor }}
                        >
                          {addOn?.name}
                        </h6>
                        <span
                          className="fw-semibold"
                          style={{ color: cachedBranding?.page_TextColor }}
                        >
                          ${addOn?.price}
                        </span>
                      </div>
                      <div className="d-flex justify-content-start flex-column align-items-start">
                        <span className="fw-light">
                          {addOn?.minutes} minutes
                        </span>
                        <span className="fw-light">{addOn?.description}</span>
                        <span className="fw-light">Audio Listening</span>
                      </div>
                    </div>
                  </button>
                ))}
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <div className="special-tier flex-fill">
            <h5
              className="fw-semibold mb-3"
              style={{ color: cachedBranding?.page_TextColor }}
            >
              Request Special Tier
            </h5>
            <div className="d-flex justify-content-start">
              {plan?.tiers &&
                plan?.tiers.map((tier, index) => (
                  <button
                    className="btn"
                    onClick={() => setShowAdditionalConsumption(true)}
                    key={index}
                  >
                    <h6
                      className="fw-semibold p-3 border border-black border-1 rounded"
                      style={{ color: cachedBranding?.page_TextColor }}
                    >
                      {tier}
                    </h6>
                  </button>
                ))}
            </div>
          </div>
        </div>
      </div>
      <OrderSummaryModal
        show={showOrderSummary}
        handleBack={() => (setShowOrderSummary(false), setSelectedAddOn(null))}
        handleSubmit={() => setShowOrderSummary(false)}
        selectedAddOn={selectedAddOn}
        plan={plan}
      />
      <AdditionalConsumptionsModal
        show={showAdditionalConsumption}
        handleSubmit={() => {}}
        handleClose={() => setShowAdditionalConsumption(false)}
      />
    </div>
  );
}

export default SpecialTierCard;
