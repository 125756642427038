import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import SpinnerCircle from "components/Custom/SpinnerCircle";
import { API_URL } from "constants";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { cleanToken } from "utils";

function UploadQNAFileModal({
  active,
  handleClose,
  refetch,
  folderId,
  cachedBranding,
}) {
  const [selectedFile, setSelectedFile] = useState(null);

  const addFile = useMutation({
    mutationFn: (fields) => {
      return axios.post(API_URL + `/QNA/CreateMaterialFile`, fields, {
        headers: {
          Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
        },
      });
    },
    onSuccess: async (data) => {
      getQNA.mutate(data?.data?.materialId);
    },
    onError: (error) => {
      console.log("error: ", error);
    },
  });

  const getQNA = useMutation({
    mutationFn: (materialId) => {
      return axios.get(API_URL + `/QNA/FillQNA/${folderId}/${materialId}`, {
        headers: {
          Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
        },
      });
    },
    onSuccess: async (data) => {
      handleClose();
      refetch();
      toast.success("Uploaded successfully");
    },
    onError: (error) => {
      console.log("error: ", error);
    },
  });

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("FolderId", folderId);
    formData.append("FileToUpload", file);
    if (file) {
      setSelectedFile(file);
      addFile.mutate(formData);
    }
  };

  const handleFileButtonClick = () => {
    document.getElementById("fileInput").click();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const preventDefault = (e) => {
    e.preventDefault();
  };

  return (
    <Modal
      show={active}
      onHide={handleClose}
      className="topics-categories"
      centered
      size="lg"
    >
      <div className="modal-content">
        <div className="modal-header">
          <h5
            className="modal-title"
            id="exampleModalCenterTitle"
            style={{ color: cachedBranding?.page_TextColor }}
          >
            Upload Files
          </h5>
          <div className="modal-option">
            <button
              type="button"
              className="btn"
              data-bs-dismiss="modal"
              onClick={handleClose}
            >
              <img src="/images/close-modal.svg" />
            </button>
          </div>
        </div>
        <div className="modal-body">
          <div className="upload-border d-flex justify-content-between">
            <div className=" d-flex">
              <img src="/images/upload-file.svg" />
              <div
                className="d-flex flex-column mx-3"
                onDragOver={preventDefault}
                onDrop={handleDrop}
              >
                <h6 style={{ color: cachedBranding?.page_TextColor }}>
                  Drag and Drop or Upload
                </h6>
                <span
                  style={{
                    fontSize: "12px",
                    color: cachedBranding?.page_TextColor,
                  }}
                >
                  Accepted file types: .txt and .pdf
                </span>
              </div>
            </div>
            {addFile.isLoading ? (
              <SpinnerCircle />
            ) : (
              <button
                className="main-btn w-25"
                onClick={handleFileButtonClick}
                style={{ backgroundColor: cachedBranding?.page_ButtonColor }}
              >
                Upload
              </button>
            )}
            <input
              type="file"
              id="fileInput"
              accept=".txt, .pdf"
              onChange={handleFileSelect}
              style={{ display: "none" }} // Hide the input element
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default UploadQNAFileModal;
