import React from "react";
import { Modal } from "react-bootstrap";

function LoginPreviewModal({ show, handleClose }) {
  return (
    <Modal show={show} onHide={handleClose} centered size="xl">
      <div className="modal-content bg-light p-5">
        <div className="modal-option d-flex justify-content-end">
          <button type="button" className="btn" onClick={handleClose}>
            <img src="/images/close-modal.svg" />
          </button>
        </div>
        <div className="modal-body h-100 w-100 p-5 d-flex flex-column bg-light justify-content-center">
          <div className="d-flex justify-content-center m-5">
            <div className="normal-card shadow-sm w-75 d-flex flex-column justify-content-center m-5">
              <div className="d-flex justify-content-center mb-2">
                <img src="/images/bot-test.png" />
              </div>
              <div className="form-group mb-3">
                <label>Email Address</label>
                <input
                  type="email"
                  className="form-control profile-input"
                  placeholder="Enter your Email Address"
                />
              </div>

              <div className="form-group mb-3">
                <label>Password</label>
                <input type="password" className="form-control profile-input" />
              </div>

              <div className="d-flex justify-content-between mb-2">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    checked=""
                    readOnly
                  />
                  <label className="form-check-label">Remember me</label>
                </div>
                <a style={{ fontSize: "12px" }}>Forgot Passsword?</a>
              </div>

              <button
                className="btn main-btn mb-2"
                style={{ backgroundColor: "#0744e1" }}
              >
                Login
              </button>

              <div
                className="d-flex justify-content-between mb-2"
                style={{ fontSize: "12px" }}
              >
                <a>Terms and Conditions</a>
                <a>Need Support?</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default LoginPreviewModal;
