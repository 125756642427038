import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

function EditCompanyDetails({ show, handleClose, handleSubmit, data }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [companyPhone, setCompanyPhone] = useState("");

  useEffect(() => {
    setName(data?.name);
    setEmail(data?.email);
    setMobile(data?.mobile);
    setCompanyPhone(data?.companyPhone);
  }, [data]);

  return (
    <Modal show={show} onHide={handleClose} animation={false}>
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Edit Company Details</h4>
          <div className="modal-option">
            <button type="button" className="btn" onClick={handleClose}>
              <img src="/images/close.svg" />
            </button>
          </div>
        </div>
        <div className="modal-body editProfile flex-column align-items-center">
          <div className="form-group profile-field">
            <label for="exampleInputEmail1" className="mb-2">
              Name
            </label>
            <input
              type="text"
              className="form-control profile-input"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        </div>
        <div className="form-group profile-field mb-3 mx-3">
          <label for="exampleInputEmail1" className="mb-2">
            Email
          </label>
          <input
            type="email"
            className="form-control profile-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="form-group profile-field mb-3 mx-3">
          <label for="exampleInputEmail1" className="mb-2">
            Mobile
          </label>
          <input
            type="text"
            className="form-control profile-input"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
          />
        </div>
        <div className="form-group profile-field mb-3 mx-3">
          <label for="exampleInputEmail1" className="mb-2">
            Company Phone
          </label>
          <input
            type="text"
            className="form-control profile-input"
            value={companyPhone}
            onChange={(e) => setCompanyPhone(e.target.value)}
          />
        </div>

        <div className="modal-footer border-0 justify-content-center">
          <button
            type="button"
            className="btn cancel-btn rounded-pill w-25 py-2 px-4"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn main-admin-btn rounded-pill w-25 py-2 px-4"
            onClick={handleSubmit}
          >
            Save
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default EditCompanyDetails;
