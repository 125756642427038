import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { RoundedButton } from "components/Custom";
import { API_URL } from "constants";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { cleanToken } from "utils";

function ChangePasswordModal({ show, handleClose, userId }) {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [error, setError] = useState("");

  const changePassword = useMutation({
    mutationFn: (fields) => {
      return axios.post(
        API_URL +
          `/User/ResetPassword?userId=${fields?.userId}&newPassword=${fields?.newPassword}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${cleanToken(
              localStorage.getItem("token")
            )}`,
          },
        }
      );
    },
    onSuccess: async (data) => {
      console.log("data: ", data);
      handleClose();
      toast.success("Password successfully changed")
    },
    onError: (error) => {
      console.log("error: ", error);
    },
  });

  const handleSubmit = () => {
    if (newPassword.length < 6) {
      setError("Password must be at least 6 characters ");
    } else if (newPassword !== passwordRepeat) {
      setError("Passwords do not match");
    } else {
      setError(""); // Clear any previous error message
      changePassword.mutate({
        userId: userId,
        newPassword: newPassword,
      });
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered size="md">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Change Password</h4>
          <div className="modal-option">
            <button type="button" className="btn" onClick={handleClose}>
              <img src="/images/close-modal.svg" />
            </button>
          </div>
        </div>
        <div className="modal-body editProfile justify-content-around flex-column">
          {/* <div className="input-group w-100 mb-3">
            <label>Old Password</label>
            <input
              type="password"
              className="form-control profile-input w-100"
              placeholder="Enter Old Password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
          </div> */}
          <div className="input-group w-100 mb-3">
            <label>New Password</label>
            <input
              type="password"
              className="form-control profile-input w-100"
              placeholder="Enter Old Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <div className="input-group w-100 mb-3">
            <label>Repeat New Password</label>
            <input
              type="password"
              className="form-control profile-input w-100"
              placeholder="Repeat New Password"
              value={passwordRepeat}
              onChange={(e) => setPasswordRepeat(e.target.value)}
            />
            {error && <div className="error-message">{error}</div>}
          </div>
          <RoundedButton
            text="Save"
            handleOnClick={() => handleSubmit()}
            className="btn shadow-sm main-btn p-3 w-500 rounded-pill ms-3"
          />
        </div>
      </div>
    </Modal>
  );
}

export default ChangePasswordModal;
