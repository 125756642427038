import React, { useState } from "react";

function TemperatureTopP({ temperature, setTemperature, topP, setTopP }) {
  const [temperatureSelected, setTemperatureSelected] = useState(true);
  const [topPSelected, setTopPSelected] = useState(false);

  const handleTemperatureChange = () => {
    setTemperatureSelected(!temperatureSelected);
    setTopP(0);
    setTopPSelected(!topPSelected);
  };

  const handleTopPChange = () => {
    setTopPSelected(!topPSelected);
    setTemperature(0);
    setTemperatureSelected(!temperatureSelected);
  };

  return (
    <div>
      <div className="p-5">
        <p className="fw-semibold">Set Temperature</p>
        <div className="cont-wmessage">
          <img src="/images/info.svg" alt="vector" />
          <p className="limit-message">
            Controls randomness. Lowering the temperature means that the model
            will produce more repetitive and deterministic responses. Increasing
            the temperature will result in more unexpected or creative
            responses.
          </p>
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              checked={temperatureSelected}
              onChange={handleTemperatureChange}
            />
          </div>
        </div>
        <div className="mt-2 row">
          <div className="col-11">
            <input
              type="range"
              className="form-range mt-2"
              value={temperature}
              onChange={(e) => setTemperature(e.target.value)}
              disabled={!temperatureSelected}
            />
          </div>
          <div className="col-1">
            <input
              className="institute-limit form-control"
              value={temperature}
              onChange={(e) => setTemperature(e.target.value)}
              disabled={!temperatureSelected}
            />
          </div>
        </div>
      </div>
      <div className="px-5 pb-5 pt-3">
        <p className="fw-semibold">Set Top P</p>
        <div className="cont-wmessage">
          <img src="/images/info.svg" alt="vector" />
          <p className="limit-message">
            Controls randomness but uses a different method. Lowering Top P will
            narrow the model’s token selection to likelier tokens. Increasing
            Top P will let the model choose from tokens with both high and low
            likelihood.
          </p>
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              checked={topPSelected}
              onChange={handleTopPChange}
            />
          </div>
        </div>
        <div className="mt-2 row">
          <div className="col-11">
            <input
              type="range"
              className="form-range mt-2"
              value={topP}
              onChange={(e) => setTopP(e.target.value)}
              disabled={!topPSelected}
            />
          </div>
          <div className="col-1">
            <input
              className="institute-limit form-control"
              value={topP}
              onChange={(e) => setTopP(e.target.value)}
              disabled={!topPSelected}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TemperatureTopP;
