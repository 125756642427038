import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "constants";
import { useFetchUserImage } from "hooks/users";
import { isEmpty } from "lodash";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { cleanToken } from "utils";

function GroupMember({ member, groupId, refetchGroups }) {
  const queryClient = useQueryClient();
  const cachedBranding = queryClient.getQueryData(["branding"])?.data;

  const deleteUserFromGroup = useMutation({
    mutationFn: (fields) => {
      return axios.post(
        API_URL + `/Group/RemoveUserFromGroup/${groupId}/${member.userId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${cleanToken(
              localStorage.getItem("token")
            )}`,
          },
        }
      );
    },
    onSuccess: () => {
      refetchGroups();
      toast.success("Member successsfully deleted");
    },
    onError: (error) => {
      console.log("error: ", error);
    },
  });

  const handleDelete = () => {
    deleteUserFromGroup.mutate();
    // refetchImage()
  };

  useEffect(() => {
    if (!isEmpty(member)) {
      // refetchImage();
    }
  }, [member]);

  return (
    <div className="member-card">
      <div className="member-name">
        <img
          src={
            member.userImage
              ? `data:image/jpeg;base64,${member.userImage}`
              : "/images/user-empty.svg"
          }
          width="30"
          height="30"
          className="rounded-circle"
          alt="User"
        />

        <span
          style={{
            color: cachedBranding?.page_TextColor,
          }}
        >
          {member?.fullName}
        </span>
      </div>
      <div className="remove-usergrp" onClick={() => handleDelete()}>
        {" "}
        <img src="/images/remove.svg" />
      </div>
    </div>
  );
}

export default GroupMember;
