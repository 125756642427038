import React from "react";

function TagsDisplay({ items }) {
  const firstTwoItems = items.slice(0, 2); // Get the first two items
  const remainingCount = items.length - firstTwoItems.length; // Calculate the number of remaining items
//tags-card-display
  return (
    <div className="d-flex flex-wrap align-items-center">
      {/* Render the first two items */}
      <div className="d-flex flex-wrap ">{firstTwoItems.map((item, index) => (
        <span className="tag-card-grp" key={index}>{item?.tagName} </span>
      ))}</div>

      {/* Show the number of remaining items with a plus sign */}
      <div className="remaining-tags-cont">
      {remainingCount > 0 && (
        <span className="remaining-tags ">
          +{remainingCount}
        </span>
      )}</div>
    </div>
  );
}

export default TagsDisplay;
