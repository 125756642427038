import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "constants";
import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { cleanToken } from "utils";

function QnaCard({ materialId }) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const saveQna = useMutation({
    mutationFn: (fields) => {
      setIsLoading(true);
      return axios.post(API_URL + `/Material/PostQnA`, fields, {
        headers: {
          Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
        },
      });
    },
    onSuccess: async (data) => {
      navigate(-1);
      toast.success("Created successfully");
    },
    onError: (error) => {
      console.log("error: ", error);
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });

  const handleSaveQna = () => {
    saveQna.mutate({
      materialId: materialId,
      question: question,
      answer: answer,
    });
  };

  useEffect(() => {
    if (!open) {
      setQuestion("");
      setAnswer("");
    }
  }, [open]);

  return (
    <>
      <div className="normal-card d-flex align-items-start">
        <div className="w-100 d-flex align-items-start">
          <div className="d-flex qna-area collapse" id="collapseExample">
            <textarea
              className="w-50 me-3"
              placeholder="Create a Question"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
            ></textarea>
            <textarea
              className="w-50"
              placeholder="Add an Answer"
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
            ></textarea>
          </div>
          <div className="d-flex">
            {isLoading ? (
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              <a className="btn cursor-pointer" onClick={() => handleSaveQna()}>
                <img src="/images/save.svg" />
              </a>
            )}
            <button className="btn" type="button">
              <img src="/images/delete-user.svg" />
            </button>
            <button
              className="btn"
              type="button"
              onClick={() => setOpen(!open)}
            >
              <img src="/images/arrow-up.svg" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default QnaCard;
