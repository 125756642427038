import { isEmpty } from "lodash";
import React, { useState } from "react";

function NewChatButton({
  cachedBranding,
  handleSelect,
  isGroup = false,
  setShowNewGroupChat,
}) {
  const [isHovered, setIsHovered] = useState(false);
  const buttonStyle = {
    backgroundColor:
      isHovered && !isEmpty(cachedBranding?.page_ButtonColor)
        ? cachedBranding?.page_ButtonColor
        : isHovered && isEmpty(cachedBranding?.page_ButtonColor)
        ? "#0844e1"
        : "#fff",
    borderColor: !isEmpty(cachedBranding?.page_ButtonColor)
      ? cachedBranding?.page_ButtonColor
      : "#0844e1",
    color: isHovered
      ? "#fff"
      : !isHovered && !isEmpty(cachedBranding?.page_ButtonColor)
      ? cachedBranding?.page_ButtonColor
      : "#0844e1",
    fontSize: "15px",
  };

  return (
    <a
      className="btn w-100 rounded-pill px-3 p-2 mb-3 mt-2 d-flex cursor-pointer"
      style={buttonStyle}
      onClick={() =>
        !isGroup ? handleSelect(null, "new") : setShowNewGroupChat(true)
      }
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img src="/images/direct.svg" className="me-2" /> New Chat
    </a>
  );
}

export default NewChatButton;
