import { RoundedButton } from "components/Custom";
import React, { useState } from "react";
import AddTagsModal from "./AddTagsModal";

function Tags({ active, tags, group, refetchGroups, cachedBranding }) {
  const [show, setShow] = useState(false);
  return (
    <>
      <div className={`tab-pane fade ${active ? "show active" : ""}`}>
        <div className="details">
          <div className="details-name">
            <span
              className="mb-2"
              style={{
                color: cachedBranding?.page_TextColor,
              }}
            >
              Group Tags
            </span>
            <div className="d-flex flex-wrap mb-4 mt-3 group-topics">
              {tags &&
                tags.map((tag, index) => (
                  <span
                    key={index}
                    className="tag-m-right m-1"
                    style={{
                      color: cachedBranding?.page_TextColor,
                    }}
                  >
                    {tag?.tagName}
                  </span>
                ))}
            </div>
          </div>
          <RoundedButton
            text="Add Tags"
            className="w-100 main-btn"
            handleOnClick={() => setShow(true)}
          />
        </div>
      </div>
      <AddTagsModal
        show={show}
        handleClose={() => setShow(false)}
        tags={tags}
        group={group}
        refetchGroups={refetchGroups}
      />
    </>
  );
}

export default Tags;
