import React from "react";

function RoundedInput({ label, type, handleOnChange, placeholder }) {
  return (
    <div className="mb-3">
      <label htmlFor="exampleFormControlInput1" className="form-label">
        {label}
      </label>
      <input
        type={type}
        className="form-control profile-input"
        placeholder={placeholder}
        onChange={handleOnChange}
      />
    </div>
  );
}

export default RoundedInput;
