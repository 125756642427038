import React from "react";

function NewGroupCard({ groupName, setGroupName, cachedBranding }) {
  return (
    <div className="add-group">
      <h5
        className="fw-semibold mb-3"
        style={{
          color: cachedBranding?.page_TextColor,
        }}
      >
        Create New Group
      </h5>
      <div className="new-group-name">
        <div className="group-img">
          <img src="/images/group-empty.png" />
        </div>
        <div className="form-group newgroup-field">
          <div className="d-flex justify-content-between">
            <label
              className="group-label"
              style={{
                color: cachedBranding?.page_TextColor,
              }}
            >
              Group Name
            </label>
            {/* <span className="fs-6 text-body-tertiary fw-light">
              GID: 001234
            </span> */}
          </div>
          <input
            type="text"
            className="form-control newgroup-input"
            placeholder="Enter Group Name"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
}

export default NewGroupCard;
