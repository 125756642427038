import React, { useState } from "react";
import ChangePasswordModal from "./ChangePasswordModal";

function SettingsTab({ active, user, cachedBranding }) {
  const [showChangePasswordModal, setShowPasswordModal] = useState(false);

  return (
    <div className={`tab-pane fade ${active ? "show active" : ""}`}>
      <div className="details">
        <div className="details-name">
          <span style={{ color: cachedBranding?.page_TextColor }}>
            Username
          </span>
          <p>{user?.username}</p>
        </div>
        <button
          type="button"
          className="main-btn"
          style={{ backgroundColor: cachedBranding?.page_ButtonColor }}
          onClick={() => setShowPasswordModal(true)}
        >
          Change Password
        </button>
      </div>
      <ChangePasswordModal
        show={showChangePasswordModal}
        handleClose={() => setShowPasswordModal(false)}
        userId={user?.userId}
      />
    </div>
  );
}

export default SettingsTab;
