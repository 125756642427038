import { useFetchAuthUser, useFetchUserImage } from "hooks/users";
import { isEmpty } from "lodash";
import React, { useEffect, useState, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";

function UserItem({ secondaryColor }) {
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate();
  const { data: user } = useFetchAuthUser();
  const { data: userImage, refetch: refetchUserImage } = useFetchUserImage(
    user?.data?.userId
  );

  const dropdownRef = useRef(null);

  useEffect(() => {
    if (!isEmpty(user)) {
      refetchUserImage();
    }

    const handleDocumentClick = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        // Click was outside the dropdown, so close it
        setToggle(false);
      }
    };

    if (toggle) {
      document.addEventListener("click", handleDocumentClick);
    } else {
      document.removeEventListener("click", handleDocumentClick);
    }

    return () => {
      // Remove the event listener when the component unmounts
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [user, toggle]);

  const handleLogoutUser = async () => {
    await localStorage.removeItem("token");
    navigate("/");
  };

  const handleDropdownClick = (e) => {
    e.stopPropagation(); // Prevent the click from propagating to the document
    setToggle(!toggle);
  };

  return (
    <div className="dropdown pb-4">
      <div
        className="d-flex align-items-center text-white text-decoration-none dropdown-toggle cursor-pointer"
        onClick={handleDropdownClick}
      >
        {userImage?.data?.imageBytes ? (
          <img
            src={`data:image/jpeg;base64,${userImage?.data?.imageBytes}`}
            alt="hugenerd"
            width="30"
            height="30"
            className="rounded-circle"
          />
        ) : (
          <img
            src="/images/user-empty.svg"
            alt="hugenerd"
            width="30"
            height="30"
            className="rounded-circle"
          />
        )}
        <span
          className="d-none d-sm-inline mx-1"
          style={{ color: secondaryColor ? secondaryColor : "black" }}
        >
          {user?.data?.username}
        </span>
      </div>
      <ul
        ref={dropdownRef}
        className="dropdown-menu dropdown-menu-dark text-small shadow sidebar-user-dropdown"
        style={{ display: toggle ? "block" : "none" }}
      >
        <li>
          <NavLink className="dropdown-item" to="/profile">
            Profile
          </NavLink>
        </li>
        <li>
          <NavLink
            className="dropdown-item"
            to="/"
            onClick={() => handleLogoutUser()}
          >
            Sign out
          </NavLink>
        </li>
      </ul>
    </div>
  );
}

export default UserItem;
