import React, { useEffect } from "react";
import TableChatSettingHeader from "./TableChatSettingHeader";
import TableChatSettingsBody from "./TableChatSettingsBody";
import { Pagination } from "components/Custom";

const TableChatSettings = ({
  chatCategorization,
  refetchChatCategorization,
  page,
  setPage,
  categories,
  refetchCategories,
  cachedBranding,
}) => {
  const itemsPerPage = chatCategorization?.data?.pageSize;
  const totalItems = chatCategorization?.data?.totalCount;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  return (
    <div className="col-12">
      <div className="user-table">
        <div className="table-responsive-sm">
          <table className="table table-borderless ">
            <TableChatSettingHeader cachedBranding={cachedBranding} />
            <TableChatSettingsBody
              chatCategorization={chatCategorization}
              refetchChatCategorization={refetchChatCategorization}
              refetchCategories={refetchCategories}
              categories={categories}
              cachedBranding={cachedBranding}
            />
          </table>
        </div>
      </div>
      <Pagination totalPages={totalPages} page={page} setPage={setPage} />
    </div>
  );
};

export default TableChatSettings;
