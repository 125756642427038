import { useQueryClient } from "@tanstack/react-query";
import { isEmpty } from "lodash";
import React, { useState } from "react";

function RoundOutlinedButton({ text, color, className, handleClick }) {
  const queryClient = useQueryClient();
  const cachedBranding = queryClient.getQueryData(["branding"])?.data;
  const [isHovered, setIsHovered] = useState(false);

  const buttonStyle = {
    backgroundColor:
      isHovered && !isEmpty(cachedBranding?.page_ButtonColor)
        ? cachedBranding?.page_ButtonColor
        : isHovered && isEmpty(cachedBranding?.page_Button)
        ? "#0744e1"
        : "#fff",
    borderColor: !isEmpty(cachedBranding)
      ? cachedBranding?.page_ButtonColor
      : "#0744e1",
    color: isHovered
      ? "#fff"
      : !isEmpty(cachedBranding?.page_ButtonColor)
      ? cachedBranding?.page_ButtonColor
      : "#0744e1",
  };

  return (
    <button
      className={`btn rounded-pill mx-2 ${className}`}
      onClick={handleClick}
      style={buttonStyle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {text}
    </button>
  );
}

export default RoundOutlinedButton;
