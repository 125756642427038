import React from "react";
import Invoice from "./Invoice";

function InvoicesCard({ cachedBranding }) {
  let invoices = [
    {
      date: "June 9 2023",
      number: "1234567",
      value: "USD $100",
    },
    {
      date: "June 9 2023",
      number: "1234567",
      value: "USD $100",
    },
    {
      date: "June 9 2023",
      number: "1234567",
      value: "USD $100",
    },
    {
      date: "June 9 2023",
      number: "1234567",
      value: "USD $100",
    },
  ];

  return (
    <div className="normal-card">
      <h5 style={{ color: cachedBranding?.page_TextColor }}>Invoices</h5>
      <div className="table-result">
        <div className="table-responsive-sm">
          <table className="table table-striped table-borderless table-name">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="table-name"
                  style={{ color: cachedBranding?.page_TextColor }}
                >
                  Date
                </th>
                <th
                  scope="col"
                  className="table-name"
                  style={{ color: cachedBranding?.page_TextColor }}
                >
                  Invoice
                </th>
                <th
                  scope="col"
                  className="table-name"
                  style={{ color: cachedBranding?.page_TextColor }}
                >
                  Total Amount
                </th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {invoices.map((invoice, index) => (
                <Invoice
                  invoice={invoice}
                  key={index}
                  cachedBranding={cachedBranding}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default InvoicesCard;
