import React, { useState } from "react";
import AddTokensModal from "components/Subscription/AddTokensModal";

function TokenCard({ setShowAddTokensModal, tokens }) {
  return (
    <div className="normal-admin-card sub-token">
      <span>Token Balance</span>
      <div className="total-tokens d-flex justify-content-between">
        <div className="result d-flex align-items-center">
          <img className="me-3" src="/images/token.svg" />
          <h3 className="mb-0">{tokens}</h3>
        </div>
        <button
          type="button"
          className="btn"
          onClick={() => setShowAddTokensModal(true)}
        >
          <img className="add-token" src="/images/add.svg" />
        </button>
      </div>
    </div>
  );
}

export default TokenCard;
