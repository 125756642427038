import React from "react";

function Invoice({ invoice, cachedBranding }) {
  return (
    <tr>
      <th
        scope="row"
        className="table-details"
        style={{ color: cachedBranding?.page_TextColor }}
      >
        {invoice?.date}
      </th>
      <td className=" " style={{ color: cachedBranding?.page_TextColor }}>
        {invoice?.number}
      </td>
      <td
        className="table-details"
        style={{ color: cachedBranding?.page_TextColor }}
      >
        {invoice?.value}
      </td>
      <td className="w-25">
        <button className="btn">
          <img src="/images/download-invoice.svg" />
        </button>
      </td>
    </tr>
  );
}

export default Invoice;
