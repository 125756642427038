import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { RoundedButton } from "components/Custom";
import { API_URL } from "constants";
import { useFetchActiveIndexers } from "hooks/azure";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { cleanToken } from "utils";
import TabButton from "./TabButton";

function NewScriptModal({ show, handleClose, refetch, cachedBranding }) {
  const { data: projects } = useFetchActiveIndexers();
  const [language, setLanguage] = useState("HTML");
  const [project, setProject] = useState(
    "00000000-0000-0000-0000-000000000000"
  );
  const [botName, setBotName] = useState("");
  const [type, setType] = useState("internet");
  const [authType, setAuthType] = useState("Authenticated");

  const addScript = useMutation({
    mutationFn: () => {
      return axios.post(
        API_URL + `/api/BotScript/CreateBot`,
        {
          language: language,
          projectId: project,
          botName: botName,
          isProject: type == "internet" ? false : true,
          type: authType == "Authenticated" ? 0 : 1,
        },
        {
          headers: {
            Authorization: `Bearer ${cleanToken(
              localStorage.getItem("token")
            )}`,
          },
        }
      );
    },
    onSuccess: async (data) => {
      refetch();
      handleClose();
      setAuthType("Authenticated");
      toast.success("Successfully saved");
    },
    onError: (error) => {
      console.log("error: ", error);
    },
  });

  const handleSubmit = () => {
    addScript.mutate();
  };
  const Taboptions = [
    { value: "internet", Text: "Internet Data" },
    { value: "institute", Text: "Institute Data" },
  ];

  const TabAuthOptions = [
    { value: "Authenticated", Text: "Authenticated Access" },
    { value: "Guest", Text: "Guest" },
  ];
  return (
    <Modal show={show} onHide={handleClose} centered size="lg">
      <div className="modal-content">
        <div className="modal-header">
          <h4
            className="modal-title"
            style={{ color: cachedBranding?.page_TextColor }}
          >
            New Script
          </h4>
          <div className="modal-option">
            <button type="button" className="btn" onClick={handleClose}>
              <img src="/images/close-modal.svg" />
            </button>
          </div>
        </div>
        <div className="modal-body editProfile justify-content-around flex-column">
          <div className="row">
            <div className="w-50">
              <label
                className="script-label"
                style={{ color: cachedBranding?.page_TextColor }}
              >
                Bot Name
              </label>
              <input
                type="text"
                className="form-control profile-input"
                placeholder="Bot name"
                value={botName}
                onChange={(e) => setBotName(e.target.value)}
              />
            </div>
            <div className="w-50">
              <label
                className="script-label"
                style={{ color: cachedBranding?.page_TextColor }}
              >
                Language
              </label>
              <div className="color-pick">
                <select
                  className="form-select border border-0"
                  style={{ fontSize: "12px" }}
                  value={language}
                  onChange={(e) => setLanguage(e.target.value)}
                >
                  <option value="HTML">HTML</option>
                  {/* <option value="React">React</option>0 */}
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="w-50">
              <label
                className="script-label"
                style={{ color: cachedBranding?.page_TextColor }}
              >
                Type
              </label>

              <TabButton
                value={type}
                setValue={setType}
                TabOptions={Taboptions}
                cachedBranding={cachedBranding}
              />
            </div>
            {"institute" === type && (
              <div className="w-50">
                <label
                  className="script-label"
                  style={{ color: cachedBranding?.page_TextColor }}
                >
                  Project
                </label>
                <div className="color-pick">
                  <select
                    className="form-select border border-0"
                    style={{ fontSize: "12px" }}
                    value={project}
                    onChange={(e) => setProject(e.target.value)}
                  >
                    <option value="00000000-0000-0000-0000-000000000000">
                      Select a Project
                    </option>
                    {projects?.data.map((project, index) => (
                      <option
                        value={project?.containerId}
                        key={`${project.containerId}}`}
                      >
                        {project?.containerName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}
          </div>
          <div class="row w-50">
            {"institute" === type && (
              <div>
                <label
                  className="script-label"
                  style={{ color: cachedBranding?.page_TextColor }}
                >
                  Authentication
                </label>
                <TabButton
                  value={authType}
                  setValue={setAuthType}
                  TabOptions={TabAuthOptions}
                  cachedBranding={cachedBranding}
                />
              </div>
            )}
          </div>
          <div>
            <RoundedButton
              text="Done"
              handleOnClick={() => handleSubmit()}
              className="btn shadow-sm main-btn p-3 rounded-pill ms-3 float-end"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default NewScriptModal;
