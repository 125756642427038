import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

function BackButton() {
  const navigate = useNavigate();
  const location = useLocation();
  const [page, setPage] = useState("");

  useEffect(() => {
    const pathname = location.pathname;
    const parts = pathname.split("/");
    setPage(parts[1]);
  }, [location]);

  const navigateBack = () => {
    if ("edit-group" === page) {
      // Pass the 'type' state when navigating back to /users
      navigate("/users", { state: { type: "groups" } });
    } else {
      navigate(-1);
    }
  };

  return (
    <div className="me-3">
      <a className="cursor-pointer" onClick={() => navigateBack()}>
        <img src="/images/back.svg" />
      </a>
    </div>
  );
}

export default BackButton;
