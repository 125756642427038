import React, { useEffect, useState } from "react";
import GroupRow from "./GroupRow";
import NewGroupChatModal from "./NewGroupChatModal";
import { useQueryClient } from "@tanstack/react-query";
import NewChatButton from "./NewChatButton";
import { isEmpty } from "lodash";

function ChatGroups({
  active,
  handleSelect,
  groups,
  refetchGroups,
  setNewGroup,
  instituteChatsGroup,
  setIsInstitute,
  materialChatsGroup,
  refetchMaterialChatsGroup,
  isQna,
  setIsQna,
  chatSettings,
  refetchInstituteChatsGroup,
  setDocumentId,
}) {
  const [showNewGroupChat, setShowNewGroupChat] = useState(false);
  const [selected, setSelected] = useState(
    chatSettings?.data[0]?.showInternet
      ? "internet"
      : chatSettings?.data[0]?.showMaterials
      ? "institute"
      : "qna"
  );
  const queryClient = useQueryClient();
  const cachedBranding = queryClient.getQueryData(["branding"])?.data;

  useEffect(() => {
    if (chatSettings?.data[0]?.showInternet) {
      setSelected("internet");
      setIsInstitute(false);
      setIsQna(false);
    } else if (chatSettings?.data[0]?.showMaterials) {
      setSelected("institute");
      setIsInstitute(true);
      setIsQna(false);
    } else {
      setSelected("qna");
      setIsInstitute(false);
      setIsQna(true);
    }
  }, [active]);

  return (
    <div className={`tab-pane fade ${active ? "show active" : ""}`}>
      <div
        className="nav nav-tabs nav-fill data-filter px-1"
        style={{ height: "unset" }}
      >
        {chatSettings?.data[0]?.showInternet && (
          <a
            className={`nav-item chat-data-link data-link cursor-pointer`}
            onClick={() => setSelected("internet")}
            style={{
              backgroundColor:
                "internet" === selected &&
                !isEmpty(cachedBranding?.page_ButtonColor)
                  ? cachedBranding?.page_ButtonColor
                  : "internet" === selected &&
                    isEmpty(cachedBranding?.page_ButtonColor)
                  ? "#0844e1"
                  : "",
              color: "internet" === selected ? "#fff" : "",
            }}
          >
            Internet Data
          </a>
        )}
        {chatSettings?.data[0]?.showMaterials && (
          <a
            className={`nav-item chat-data-link data-link cursor-pointer`}
            onClick={() => (
              setSelected("institute"),
              handleSelect(null, "new"),
              setIsInstitute(true),
              setIsQna(false)
            )}
            style={{
              backgroundColor:
                "institute" === selected &&
                !isEmpty(cachedBranding?.page_ButtonColor)
                  ? cachedBranding?.page_ButtonColor
                  : "institute" === selected &&
                    isEmpty(cachedBranding?.page_ButtonColor)
                  ? "#0844e1"
                  : "",
              color: "institute" === selected ? "#fff" : "",
            }}
          >
            Institute Data
          </a>
        )}
        {chatSettings?.data[0]?.showQnA && (
          <a
            className={`nav-item chat-data-link data-link cursor-pointer`}
            onClick={() => (
              setSelected("qna"),
              handleSelect(null, "new"),
              setIsInstitute(false),
              setIsQna(true)
            )}
            style={{
              backgroundColor:
                "qna" === selected && !isEmpty(cachedBranding?.page_ButtonColor)
                  ? cachedBranding?.page_ButtonColor
                  : "qna" === selected &&
                    isEmpty(cachedBranding?.page_ButtonColor)
                  ? "#0844e1"
                  : "",
              color: "qna" === selected ? "#fff" : "",
            }}
          >
            Custom QnA
          </a>
        )}
      </div>
      {"internet" === selected ? (
        <div className="normal-list">
          <NewChatButton
            cachedBranding={cachedBranding}
            handleSelect={handleSelect}
            isGroup={true}
            setShowNewGroupChat={setShowNewGroupChat}
          />
          {groups &&
            groups.map((chat, index) => (
              <GroupRow
                chat={chat}
                key={index}
                handleSelect={handleSelect}
                cachedBranding={cachedBranding}
                refetchGroups={refetchGroups}
                refetchMaterialChatsGroup={refetchMaterialChatsGroup}
                refetchInstituteChatsGroup={refetchInstituteChatsGroup}
                setDocumentId={setDocumentId}
              />
            ))}
        </div>
      ) : "qna" === selected ? (
        <div className="normal-list">
          <NewChatButton
            cachedBranding={cachedBranding}
            handleSelect={handleSelect}
            isGroup={true}
            setShowNewGroupChat={setShowNewGroupChat}
          />
          {materialChatsGroup?.data &&
            materialChatsGroup?.data.map((chat, index) => (
              <GroupRow
                chat={chat}
                key={index}
                handleSelect={handleSelect}
                cachedBranding={cachedBranding}
                refetchGroups={refetchGroups}
                refetchMaterialChatsGroup={refetchMaterialChatsGroup}
                refetchInstituteChatsGroup={refetchInstituteChatsGroup}
                setDocumentId={setDocumentId}
              />
            ))}
        </div>
      ) : (
        <div className="normal-list">
          <NewChatButton
            cachedBranding={cachedBranding}
            handleSelect={handleSelect}
            isGroup={true}
            setShowNewGroupChat={setShowNewGroupChat}
          />
          {instituteChatsGroup?.data &&
            instituteChatsGroup?.data.map((chat, index) => (
              <GroupRow
                chat={chat}
                key={index}
                handleSelect={handleSelect}
                cachedBranding={cachedBranding}
                refetchGroups={refetchGroups}
                refetchMaterialChatsGroup={refetchMaterialChatsGroup}
                refetchInstituteChatsGroup={refetchInstituteChatsGroup}
              />
            ))}
        </div>
      )}
      <NewGroupChatModal
        show={showNewGroupChat}
        handleClose={() => setShowNewGroupChat(false)}
        handleSelect={handleSelect}
        setNewGroup={setNewGroup}
        cachedBranding={cachedBranding}
      />
    </div>
  );
}

export default ChatGroups;
