import React from "react";

function TokensCard({
  tokens,
  price,
  image,
  addCard = false,
  handleOnClick,
  selected,
}) {
  return (
    <div
      className={`token-card cursor-pointer ${
        selected ? "token-card-active" : ""
      }`}
      onClick={handleOnClick}
    >
      <img src={image} />
      <div className="token-details">
        {!addCard ? (
          <>
            <h3>{tokens} Tokens</h3>
            <span>{price}</span>
          </>
        ) : (
          <h3>Add More</h3>
        )}
      </div>
    </div>
  );
}

export default TokensCard;
