import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";
import DeleteAccountModal from "./DeleteAccountModal";
import EditUserModal from "./EditUserModal";
import { Pagination } from "components/Custom";
import { ConfirmDeleteModal } from "components/Admin/modal";

const UsersTable = forwardRef(
  (
    {
      selectedUsers,
      setSelectedUsers,
      page,
      users,
      refetchUsers,
      setPage,
      cachedBranding,
    },
    ref
  ) => {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState([]);

    const itemsPerPage = users?.data?.pageSize;
    const totalItems = users?.data?.totalCount;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    useEffect(() => {
      refetchUsers();
    }, []);

    useImperativeHandle(ref, () => ({
      handleRefetch,
    }));

    const handleRefetch = () => {
      refetchUsers({ pageNumber: page });
    };

    useEffect(() => {
      refetchUsers({ pageNumber: page });
    }, [page]);

    const handleCheckUser = (userId) => {
      if (selectedUsers.includes(userId)) {
        setSelectedUsers((selectedUsers) =>
          selectedUsers.filter((id) => id !== userId)
        );
      } else {
        setSelectedUsers((selectedUsers) => [...selectedUsers, userId]);
      }
    };

    return (
      <div className="col-12">
        <div className="user-table">
          <div className="table-responsive-md">
            <table className="table table-borderless tablecenter">
              <TableHeader cachedBranding={cachedBranding} />
              <TableBody
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
                setShowDeleteModal={setShowDeleteModal}
                setShowEditModal={setShowEditModal}
                setSelectedUser={setSelectedUser}
                users={users?.data?.items}
                refetchUsers={refetchUsers}
                handleCheckUser={handleCheckUser}
                cachedBranding={cachedBranding}
              />
            </table>
          </div>
        </div>
        <Pagination totalPages={totalPages} page={page} setPage={setPage} />
        <DeleteAccountModal
          cachedBranding={cachedBranding}
          show={showDeleteModal}
          handleClose={() => setShowDeleteModal(false)}
          handleSubmit={() => (
            setShowDeleteModal(false), setShowConfirmDeleteModal(true)
          )}
        />
        <ConfirmDeleteModal
          show={showConfirmDeleteModal}
          handleClose={() => (
            refetchUsers(),
            setShowConfirmDeleteModal(false),
            setSelectedUser(null)
          )}
          selectedUser={selectedUser}
        />
        <EditUserModal
          show={showEditModal}
          handleClose={() => (
            handleRefetch(), setShowEditModal(false), setSelectedUser(null)
          )}
          selectedUser={selectedUser}
          refetchUsers={refetchUsers}
          cachedBranding={cachedBranding}
        />
      </div>
    );
  }
);

export default UsersTable;
