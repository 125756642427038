import { RoundedButton } from "components/Custom";
import { isEmpty } from "lodash";
import React from "react";

function BotChatView({
  tempBot,
  topBannerColor,
  userChatColor,
  botChatColor,
  backgroundColor,
  welcomeMessage,
  bot,
  botColor = "#0744e1",
  buttonColor = "#0744e1",
  bubbleColor = "#f5f5f5",
  setBubbleColor,
  botIconContentType,
}) {
  return (
    <div className="normal-card px-0">
      <div className="chatbox-preview" style={{ height: "471px" }}>
        <div
          className="chatbox-header"
          style={{ backgroundColor: topBannerColor }}
        >
          <div className=" d-flex justify-content-between">
            <div>
              <img src="/images/arrow-left.svg" />
            </div>
            <div className="ps-4">
              <p className="fw-semibold">Question</p>
            </div>
            <div className="">
              <img src="/images/bot-maximiza.png" />
              <img src="/images/bot-minimize.png" />
              <img src="/images/bot-close.png" />
            </div>
          </div>
          <div className="row">
            <RoundedButton
              text="Delete"
              image="/images/delete.svg"
              color="rgba(255, 255, 255, 0.45)"
              fontColor="#000"
              className="w-50"
            />
            <RoundedButton
              text="Delete"
              image="/images/download-invoice.svg"
              color="rgba(255, 255, 255, 0.45)"
              fontColor="#000"
              className="w-50"
            />
          </div>
        </div>
        <div
          className="chatbox-body pt-4"
          style={{ backgroundColor: backgroundColor, height: "275px" }}
        >
          <div className="row">
            <div className="col-2">
              {tempBot ? (
                <img
                  src={URL.createObjectURL(tempBot)}
                  alt="Selected Bot"
                  className="p-2 m-2 mt-2 w-100"
                />
              ) : !isEmpty(bot) ? (
                <img
                  src={`data:${botIconContentType};base64,${bot}`}
                  className="p-2 m-2 mt-2 w-100"
                />
              ) : (
                <img
                  src="/images/default-bot.svg"
                  className="p-2 m-2 mt-2 w-100"
                />
              )}
            </div>
            <div className="col-10 px-3">
              <div
                className="gpt-bubble"
                style={{ backgroundColor: bubbleColor }}
              >
                <p className="mb-0" style={{ color: botChatColor }}>
                  {isEmpty(welcomeMessage)
                    ? "Ask anything you want"
                    : welcomeMessage}
                </p>
                <div
                  style={{
                    content: "",
                    position: "absolute",
                    width: 0,
                    height: 0,
                    border: "2.75rem solid transparent",
                    borderTop: "none",
                    borderBottomColor: bubbleColor,
                    top: "32%",
                    left: "-15px",
                    display: "block",
                    borderWidth: "2px 15px 11px 15px",
                  }}
                ></div>
              </div>
              <div className="guest-bubble position-relative">
                <p
                  className="mb-0"
                  style={{ color: userChatColor, backgroundColor: bubbleColor }}
                >
                  Hi
                </p>
                <div
                  style={{
                    position: "absolute",
                    width: 0,
                    height: 0,
                    border: "2.75rem solid transparent",
                    borderTop: "none",
                    borderBottomColor: backgroundColor,
                    top: "26%",
                    right: "13.5px",
                    display: "block",
                    borderWidth: "15px 16px 26px 0px",
                    backgroundColor: bubbleColor,
                    transform: "scaleX(-1) scaleY(-1)",
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div className="chatbox-footer">
          <div className="chatbox input-group mb-0">
            <input
              className="chat-inp"
              type="text"
              placeholder="Ask Anything!"
            />
          </div>
          <div className="chatbox-action">
            <button
              className="btn send-btn"
              type="button"
              style={{ backgroundColor: buttonColor }}
            >
              Send <img src="/images/send.svg" />
            </button>
            <button className="btn mic" type="button">
              <img src="/images/mic.svg" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BotChatView;
