import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "constants";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { cleanToken } from "utils";

function RenameFolderModal({ active, handleClose, folder, refetch }) {
  const [folderName, setFolderName] = useState("");

  useEffect(() => {
    if (!isEmpty(folder)) {
      setFolderName(folder?.folderName);
    }
  }, [folder]);

  const editFolderName = useMutation({
    mutationFn: (fields) => {
      return axios.post(
        API_URL +
          `/Material/EditFolderName?FolderId=${fields?.FolderId}&FolderName=${fields?.FolderName}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${cleanToken(
              localStorage.getItem("token")
            )}`,
          },
        }
      );
    },
    onSuccess: async (data) => {
      console.log("data: ", data);
      handleClose();
      refetch();
    },
    onError: (error) => {
      console.log("error: ", error);
    },
  });

  const handleSubmit = () => {
    editFolderName.mutate({
      FolderId: folder?.folderId,
      FolderName: folderName,
    });
  };

  return (
    <Modal
      show={active}
      onHide={handleClose}
      className="topics-categories"
      centered
      size="md"
    >
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalCenterTitle">
            Rename Folder
          </h5>
          <div className="modal-option">
            <button type="button" className="btn" onClick={handleClose}>
              <img src="/images/close-modal.svg" />
            </button>
          </div>
        </div>
        <div className="modal-body">
          <div className="d-flex align-items-center mb-3">
            <div className="form-group profile-field w-100 mx-2">
              <label className="fw-medium">Folder Name</label>
              <input
                type="text"
                className="form-control profile-input"
                placeholder="Enter Folder Name"
                value={folderName}
                onChange={(e) => setFolderName(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer d-flex align-items-end">
          <button
            type="button"
            className="btn main-btn w-25 mx-3"
            onClick={handleSubmit}
            style={{ backgroundColor: "#0744e1" }}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn-secondary w-25"
            onClick={handleClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default RenameFolderModal;
