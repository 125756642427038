import React from "react";

function TableHeader({ cachedBranding }) {
  return (
    <thead>
      <tr>
        <th
          className="table-header"
          scope="col"
          style={{ color: cachedBranding?.page_TextColor }}
        >
          Name
        </th>
        <th
          className="table-header"
          scope="col"
          style={{ color: cachedBranding?.page_TextColor }}
        >
          Email
        </th>
        <th
          className="table-header"
          scope="col"
          style={{ color: cachedBranding?.page_TextColor }}
        >
          Mobile
        </th>
        <th
          className="table-header"
          scope="col"
          style={{ color: cachedBranding?.page_TextColor }}
        >
          Department
        </th>
        <th
          className="table-header headercenter"
          scope="col"
          style={{ color: cachedBranding?.page_TextColor }}
        >
          Role
        </th>
        <th
          className="table-header headercenter"
          scope="col"
          style={{ color: cachedBranding?.page_TextColor }}
        >
          Actions
        </th>
      </tr>
    </thead>
  );
}

export default TableHeader;
