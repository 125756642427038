import React from "react";

function DataCards() {
  return (
    <div className="row mb-4 d-flex align-items-end justify-content-end">
      <div className="col-10">
        <div className="d-flex">
          <div className="light-main shadow-1 w-33 p-3 me-2 d-flex flex-column text-white rounded me-4">
            <span className="fs-7">Total Customer</span>
            <div className="d-flex align-items-center">
              <img src="/images/user-ico.svg" />
              <h2 className="ps-3 fw-bold mb-0">300,000</h2>
            </div>
          </div>
          <div className="bg-white shadow-1 w-33 p-3 me-2 d-flex flex-column dark-text rounded">
            <span className="fs-7">New Customer in Last Week</span>
            <div className="d-flex align-items-center">
              <img className="dark-filter" src="/images/user-ico.svg" />
              <h2 className="ps-3 fw-bold mb-0">10</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="col-2 d-flex justify-content-end">
        <button className="btn second-outline rounded-pill fw-medium px-3">
          <img className="pe-2 dark-filter" src="/images/export.svg" />
          Export
        </button>
      </div>
    </div>
  );
}

export default DataCards;
