import React, { useState } from "react";
import ScriptModal from "./ScriptModal";

function ScriptRow({ bot, cachedBranding }) {
  const [showScriptModal, setShowScriptModal] = useState(false);

  return (
    <tr className="table-tile">
      <th>
        <span style={{ color: cachedBranding?.page_TextColor }}>
          {bot.botName}
        </span>
      </th>
      <td className="script-table-body">
        <span style={{ color: cachedBranding?.page_TextColor }}>
          {bot.botType}
        </span>
      </td>
      <td className="script-table-body">
        <span style={{ color: cachedBranding?.page_TextColor }}>
          {bot.version}
        </span>
      </td>
      <td className="script-table-body">
        <span style={{ color: cachedBranding?.page_TextColor }}>
          {bot.folderName}
        </span>
      </td>
      <td className="script-table-body">
        <span style={{ color: cachedBranding?.page_TextColor }}>
          {bot.language}
        </span>
      </td>
      <td className="script-table-body">
        <div className="cursor-pointer">
          <img src="/images/active-circle.svg" />
          <span className="px-2">Active</span>
          <img src="/images/arrow-down-filled.svg" />
        </div>
      </td>
      <td className="script-table-body">
        <img
          className="me-2 cursor-pointer"
          height={20}
          width={16}
          src="/images/copy.svg"
          onClick={() => setShowScriptModal(true)}
        />
        <img
          className="cursor-pointer"
          height={20}
          width={16}
          src="/images/download-ico.svg"
        />
      </td>
      <ScriptModal
        show={showScriptModal}
        handleClose={() => setShowScriptModal(false)}
        scriptData={bot.scriptt}
      />
    </tr>
  );
}

export default ScriptRow;
