import { useFetchRoles } from "hooks/roles";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";

function ChangeRoleModal({
  show,
  handleClose,
  selectedUsers,
  handleRoleChange,
}) {
  const [selectedRole, setSelectedRole] = useState(""); // Track the selected role
  const { data: roles, refetch: refetchRoles } = useFetchRoles();

  // Function to handle role change
  const handleChangeRole = async () => {
    if (selectedRole && selectedUsers.length > 0) {
      const updatedUsers = selectedUsers.map((userId) => ({
        userId,
        roleId: selectedRole,
      }));

      try {
        // Send the bulk update request to the API using 'handleRoleChange'
        await handleRoleChange(updatedUsers);
        handleClose(); // Close the modal after successful update
      } catch (error) {
        console.error("Error updating user roles:", error);
      }
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      {/* Modal content */}
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Change Role</h4>
          <div className="modal-option">
            <button type="button" className="btn" onClick={handleClose}>
              <img src="/images/close-modal.svg" alt="Close" />
            </button>
          </div>
        </div>
        <div className="modal-body editProfile">
          <div className="form-group profile-long">
            <label htmlFor="exampleInputEmail1">Role</label>
            <select
              className="form-control profile-input"
              onChange={(e) => setSelectedRole(e.target.value)}
            >
              {/* Render role options */}
              {roles?.data &&
                roles?.data.map((role) => (
                  <option key={role?.roleId} value={role?.roleId}>
                    {role?.roleName}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn normal-btn"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn main-btn w-50"
            onClick={handleChangeRole} // Call 'handleChangeRole' on button click
            style={{ backgroundColor: "#0744e1" }}
          >
            Change all
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default ChangeRoleModal;
