import React, { useState, useEffect } from "react";
import Individual from "./Individual";
import ChatGroups from "./ChatGroups";
import Direct from "./Direct";
import { useFetchGroupChats } from "hooks/chat";
import { isEmpty } from "lodash";
import { useFetchQnaByMaterialId } from "hooks/materials";

function Chats({
  handleSelect,
  chatsTotal,
  individualChats,
  refetchIndividualChats,
  groups,
  refetchGroups,
  refetchFavorites,
  setNewGroup,
  cachedBranding,
  setIsInstitute,
  isInstitute,
  instituteChats,
  instituteChatsGroup,
  setSelectedChat,
  refetchInstituteChats,
  refetchInstituteChatsGroup,
  selectedTab,
  setSelectedTab,
  setChatType,
  materialChats,
  refetchMaterialChats,
  isQna,
  setIsQna,
  materialChatsGroup,
  refetchMaterialChatsGroup,
  chatSettings,
  setDocumentId,
}) {
  const [chatCount, setChatCount] = useState(0);
  const [selected, setSelected] = useState(
    chatSettings?.data[0]?.showInternet
      ? "internet"
      : chatSettings?.data[0]?.showMaterials
      ? "institute"
      : "qna"
  );
  useEffect(() => {
    if (selected == "internet") {
      setChatCount(individualChats?.length);
    }
    if (selected == "institute") {
      setChatCount(instituteChats?.data?.length);
    }
    if (selected == "qna") {
      setChatCount(materialChats?.data?.length);
    }
  }, [selected, materialChats, instituteChats, individualChats]);
  return (
    <div className="main-chat">
      <div className="nav nav-tabs nav-fill nav-chat">
        <a
          className={`nav-item chat-link cursor-pointer`}
          onClick={() => (
            setSelectedTab("individual"),
            setIsInstitute(false),
            setChatType("new")
          )}
          style={{
            color:
              "individual" === selectedTab &&
              !isEmpty(cachedBranding?.page_ButtonColor)
                ? cachedBranding?.page_ButtonColor
                : "individual" === selectedTab &&
                  isEmpty(cachedBranding?.page_ButtonColor)
                ? "#0844e1"
                : "",
            borderBottomWidth: "individual" === selectedTab ? "3px" : "",
            borderBottomColor:
              "individual" === selectedTab &&
              !isEmpty(cachedBranding?.page_ButtonColor)
                ? cachedBranding?.page_ButtonColor
                : "individual" === selectedTab &&
                  isEmpty(cachedBranding?.page_ButtonColor)
                ? "#0844e1"
                : "",
          }}
        >
          Individual {individualChats?.length !== 0 && <span>{chatCount}</span>}
        </a>
        <a
          className={`nav-item chat-link cursor-pointer`}
          onClick={() => (
            setSelectedTab("group"), setIsInstitute(false), setChatType("new")
          )}
          style={{
            color:
              "group" === selectedTab &&
              !isEmpty(cachedBranding?.page_ButtonColor)
                ? cachedBranding?.page_ButtonColor
                : "group" === selectedTab &&
                  isEmpty(cachedBranding?.page_ButtonColor)
                ? "#0844e1"
                : "",
            borderBottomWidth: "group" === selectedTab ? "3px" : "",
            borderBottomColor:
              "group" === selectedTab &&
              !isEmpty(cachedBranding?.page_ButtonColor)
                ? cachedBranding?.page_ButtonColor
                : "group" === selectedTab &&
                  isEmpty(cachedBranding?.page_ButtonColor)
                ? "#0844e1"
                : "",
          }}
        >
          Groups {groups?.length !== 0 && <span>{groups?.length}</span>}
        </a>
      </div>

      <div className="tab-content">
        <Individual
          active={"individual" === selectedTab ? true : false}
          handleSelect={handleSelect}
          individualChats={individualChats}
          refetchIndividualChats={refetchIndividualChats}
          refetchFavorites={refetchFavorites}
          cachedBranding={cachedBranding}
          setIsInstitute={setIsInstitute}
          isInstitute={isInstitute}
          instituteChats={instituteChats}
          setSelectedChat={setSelectedChat}
          refetchInstituteChats={refetchInstituteChats}
          refetchInstituteChatsGroup={refetchInstituteChatsGroup}
          materialChats={materialChats}
          refetchMaterialChats={refetchMaterialChats}
          isQna={isQna}
          setIsQna={setIsQna}
          refetchMaterialChatsGroup={refetchMaterialChatsGroup}
          chatSettings={chatSettings}
          setDocumentId={setDocumentId}
          selected={selected}
          setSelected={setSelected}
        />
        <ChatGroups
          active={"group" === selectedTab ? true : false}
          handleSelect={handleSelect}
          groups={groups}
          refetchGroups={refetchGroups}
          setNewGroup={setNewGroup}
          cachedBranding={cachedBranding}
          setIsInstitute={setIsInstitute}
          isInstitute={isInstitute}
          instituteChatsGroup={instituteChatsGroup}
          materialChatsGroup={materialChatsGroup}
          refetchMaterialChatsGroup={refetchMaterialChatsGroup}
          isQna={isQna}
          setIsQna={setIsQna}
          chatSettings={chatSettings}
          refetchInstituteChatsGroup={refetchInstituteChatsGroup}
          setDocumentId={setDocumentId}
        />
        <Direct active={"direct" === selectedTab ? true : false} />
      </div>
    </div>
  );
}

export default Chats;
