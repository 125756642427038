import React from "react";

function Tag({ role, roleId }) {
  const roleClassName = (roleId) => {
    switch (roleId) {
      case 0:
        return "owner";
      case 1:
        return "superAdmin";
      case 2:
        return "subscriptionMaster";
      case 3:
        return "resourceManager";
      case 4:
        return "dataAnalyst";
      case 5:
        return "userAdmin";
      case 6:
        return "admin";
      case 7:
        return "usertag";
    }
  };
  return (
    <div className="tag">
      <div
        className={`${roleClassName(roleId)} text-center`}
        // style={{
        //   color: color,
        //   backgroundColor: backgroundColor,
        //   border: `1.2px solid ${borderColor}`,
        //   width: "120px",
        // }}
      >
        <span>{role}</span>
      </div>
    </div>
  );
}

export default Tag;
