import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "constants";
import React, { useState } from "react";
import { cleanToken } from "utils";
import DeleteChatModal from "./DeleteChatModal";

function FavoriteItem({
  chat,
  handleSelect,
  refetchFavorites,
  refetchIndividualChats,
  cachedBranding,
  setSelectedChat,
  refetchInstituteChatsGroup,
  refetchInstituteChats,
}) {
  const [showActions, setShowActions] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const addFavorite = useMutation({
    mutationFn: () => {
      return axios.post(
        API_URL +
          `/Chat/SetChatAsFavorite/${chat?.chatId}/${!chat?.isFavorite}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${cleanToken(
              localStorage.getItem("token")
            )}`,
          },
        }
      );
    },
    onSuccess: async (data) => {
      console.log("data: ", data);
      refetchFavorites();
      refetchIndividualChats();
    },
    onError: (error) => {
      console.log("error: ", error);
    },
  });

  const handleFavorite = () => {
    addFavorite.mutate();
  };

  return (
    <div
      className="cursor-pointer"
      onClick={() => handleSelect(chat?.chatId, "old")}
    >
      <div className="individual-card">
        <div className="d-flex align-items-center w-100">
          {0 === chat?.chatType ? (
            <img src="/images/internet-data.svg" width={28} height={28} />
          ) : 1 === chat?.chatType ? (
            <img src="/images/institute-data.svg" style={{ width: "28px" }} />
          ) : (
            <img src="/images/custom-qna.svg" style={{ width: "28px" }} />
          )}
          <div className="individual-info ms-2">
            <h6
              className="m-0"
              style={{
                color: cachedBranding?.page_TextColor,
              }}
            >
              {chat?.chatTitle}
            </h6>
            <div
              style={{
                backgroundColor:
                  0 === chat?.chatType
                    ? "#BDC4FF"
                    : 1 === chat?.chatType
                    ? "#AAFDFF"
                    : "#EBF4FF",
                width: "70px",
                borderRadius: "20px",
              }}
              className="text-center mt-2"
            >
              <span className="">
                {0 === chat?.chatType
                  ? "Internet"
                  : 1 === chat?.chatType
                  ? "Institute"
                  : "QnA"}
              </span>
            </div>
          </div>
        </div>
        <div className="direct-actions">
          {chat?.isFavorite ? (
            <img src="/images/favorite.svg" style={{ width: "18px" }} />
          ) : (
            <img src="/images/fave.svg" style={{ width: "18px" }} />
          )}
          <div className="dropdown">
            <button
              className="px-0 bg-transparent border-0"
              type="button"
              onClick={() => setShowActions(!showActions)}
            >
              <img src="/images/more-chat.svg" style={{ width: "5px" }} />
            </button>
            <div
              className={`dropdown-menu ${
                showActions ? "show" : ""
              } favorite-dropdown`}
            >
              {chat?.isFavorite ? (
                <button
                  className="dropdown-item chat-dropdown"
                  type="button"
                  onClick={() => handleFavorite()}
                  style={{
                    color: cachedBranding?.page_TextColor,
                  }}
                >
                  Unfavorite{" "}
                  <img src="/images/favorite.svg" style={{ width: "18px" }} />
                </button>
              ) : (
                <button
                  className="dropdown-item chat-dropdown"
                  type="button"
                  onClick={() => handleFavorite()}
                  style={{
                    color: cachedBranding?.page_TextColor,
                  }}
                >
                  Favorite <img src="/images/fave.svg" />
                </button>
              )}
              <button
                className="dropdown-item chat-dropdown"
                type="button"
                style={{
                  color: cachedBranding?.page_TextColor,
                }}
                onClick={() => setShowDeleteModal(true)}
              >
                Delete <img src="/images/delete-user.svg" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <DeleteChatModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        chat={chat}
        setSelectedChat={setSelectedChat}
        refetchFavorites={refetchFavorites}
        refetchIndividualChats={refetchIndividualChats}
        refetchInstituteChats={refetchInstituteChats}
        refetchInstituteChatsGroup={refetchInstituteChatsGroup}
        handleSelect={handleSelect}
      />
    </div>
  );
}

export default FavoriteItem;
