import React from "react";

function ComingSoon() {
  return (
    <div>
      <h4 className="text-center mt-4 fw-bold">Coming Soon</h4>
      <h5 className="text-center mt-3 fw-bold">Frequency Penalty</h5>
      <p className="text-center mt-3 fw-medium px-5">
        Reduce the chance of repeating a token proportionally based on how often
        it has appeared in the text so far. This decreases the likelihood of
        repeating the exact same text in a response. 
      </p>
    </div>
  );
}

export default ComingSoon;
