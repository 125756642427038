import React from 'react'

const ImagesDisplay = ({groupMembers}) => {

   const firstThreeMembers=groupMembers.slice(0,3)

   const remainingCount= groupMembers.length - firstThreeMembers.length
 
  return (
    <div>
  {firstThreeMembers.map((item,index)=>{
    return(
    <img key={index} className='images-group-card' src={item.userImage
        ? `data:image/jpeg;base64,${item.userImage}`
        : "/images/user-empty.svg"} alt=""/>)
  })}
   {remainingCount > 0 && (
        <span className='remaining-members'>
          +{remainingCount}
        </span>
      )}
    </div>
  )
}

export default ImagesDisplay
