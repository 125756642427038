import React from "react";
import { Modal } from "react-bootstrap";
import { RoundedButton } from "components/Custom";

function AddSupportModal({
  show,
  handleClose,
  supportText,
  setSupportText,
  edit = true,
}) {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">
            {edit ? "Add Support Message" : "Support Message"}
          </h5>
          <div className="modal-option">
            <button type="button" className="btn" onClick={handleClose}>
              <img src="/images/close-modal.svg" />
            </button>
          </div>
        </div>
        <div className="modal-body">
          <div className="mb-3">
            {edit ? (
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows="8"
                value={supportText}
                onChange={(e) => setSupportText(e.target.value)}
              ></textarea>
            ) : (
              <div>{supportText}</div>
            )}
          </div>
        </div>
        {edit && (
          <div className="modal-footer">
            <RoundedButton
              text="Confirm"
              className="btn main-btn"
              handleOnClick={handleClose}
              style={{ backgroundColor: "#0744e1" }}
            />
          </div>
        )}
      </div>
    </Modal>
  );
}

export default AddSupportModal;
