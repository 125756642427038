import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

function SubMenuItem({ title, to, primaryColor, secondaryColor, textColor }) {
  const [materials, setMaterials] = useState(false);
  const [qna, setQna] = useState(false);

  useEffect(() => {
    if ("materials" === window.location.pathname.split("/")[1]) {
      setMaterials(true);
    }
    if ("qna" === window.location.pathname.split("/")[1]) {
      setQna(true);
    }
  }, []);

  return (
    <li className="w-100 mb-1">
      <NavLink
        to={to}
        className={`nav-link sub-navlink align-middle  d-flex align-items-center ${
          window.location.pathname == to ||
          (materials && "Manage Materials" === title) ||
          (qna && "Questions and Answers" === title)
            ? "active"
            : ""
        }`}
      >
        <i
          className="menu-icon icon-menu-arrow fs-5"
          style={{
            color:
              window.location.pathname == to ||
              (materials && "Manage Materials" === title) ||
              (qna && "Questions and Answers" === title)
                ? secondaryColor
                : textColor,
          }}
        />{" "}
        <span
          className="ms-1 d-none d-sm-inline"
          style={{
            color:
              window.location.pathname == to ||
              (materials && "Manage Materials" === title) ||
              (qna && "Questions and Answers" === title)
                ? secondaryColor
                : textColor,
          }}
        >
          {title}
        </span>
      </NavLink>
    </li>
  );
}

export default SubMenuItem;
