import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "../constants";
import { cleanToken } from "utils";
import { isEmpty } from "lodash";

const fetchFavoriteChats = async () => {
  return await axios.get(API_URL + `/Chat/GetAllFavorites`, {
    headers: {
      Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
    },
  });
};

const fetchIndividualChats = async (searchText) => {
  return await axios.get(
    !isEmpty(searchText)
      ? API_URL + `/Chat/GetAllIndividualChats?SearchField=${searchText}`
      : API_URL + `/Chat/GetAllIndividualChats`,
    {
      headers: {
        Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
      },
    }
  );
};

const fetchGroupChats = async (searchText) => {
  return await axios.get(
    !isEmpty(searchText)
      ? API_URL + `/Chat/GetAllGroupChats?SearchField=${searchText}`
      : API_URL + `/Chat/GetAllGroupChats`,
    {
      headers: {
        Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
      },
    }
  );
};

const fetchChatById = async (chatId) => {
  return await axios.get(API_URL + `/Chat/GetChatDetailsById/${chatId}`, {
    headers: {
      Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
    },
  });
};

const fetchAllIndividualMaterialChats = async (projectId, searchText) => {
  return await axios.get(
    !isEmpty(searchText)
      ? API_URL +
          `/Chat/GetAllIndividualMaterialChats/${projectId}?SearchField=${searchText}`
      : API_URL + `/Chat/GetAllIndividualMaterialChats/${projectId}`,
    {
      headers: {
        Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
      },
    }
  );
};

const fetchAllIndividualInstituteChats = async (containerId, searchText) => {
  return await axios.get(
    !isEmpty(searchText)
      ? API_URL +
          `/Chat/GetAllIndividualInstituteChats/${containerId}?SearchField=${searchText}`
      : API_URL + `/Chat/GetAllIndividualInstituteChats/${containerId}`,
    {
      headers: {
        Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
      },
    }
  );
};

const fetchAllGroupInstituteChats = async (containerId, searchText) => {
  return await axios.get(
    !isEmpty(searchText)
      ? API_URL +
          `/Chat/GetAllGroupInstituteChats/${containerId}?SearchField=${searchText}`
      : API_URL + `/Chat/GetAllGroupInstitueChats/${containerId}`,
    {
      headers: {
        Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
      },
    }
  );
};

const fetchAllGroupMaterialChats = async (projectId, searchText) => {
  return await axios.get(
    !isEmpty(searchText)
      ? API_URL +
          `/Chat/GetAllGroupMaterialChats/${projectId}?SearchField=${searchText}`
      : API_URL + `/Chat/GetAllGroupMaterialChats/${projectId}`,
    {
      headers: {
        Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
      },
    }
  );
};

export const useFetchFavoriteChats = () => {
  return useQuery({
    queryKey: ["favoriteChats"],
    queryFn: () => fetchFavoriteChats(),
    enabled: true,
  });
};

export const useFetchIndividualChats = (searchText) => {
  return useQuery({
    queryKey: ["individualChats"],
    queryFn: () => fetchIndividualChats(searchText),
    enabled: false,
  });
};

export const useFetchGroupChats = (searchText) => {
  return useQuery({
    queryKey: ["groupChats"],
    queryFn: () => fetchGroupChats(searchText),
    enabled: true,
  });
};

export const useFetchChatById = (chatId) => {
  return useQuery({
    queryKey: ["chatDetails"],
    queryFn: () => fetchChatById(chatId),
    enabled: false,
  });
};

export const useFetchIndividualMaterialChats = (projectId, searchText) => {
  return useQuery({
    queryKey: ["individualMaterialAll"],
    queryFn: () => fetchAllIndividualMaterialChats(projectId, searchText),
    enabled: false,
  });
};

export const useFetchIndividualInstituteChats = (containerId, searchText) => {
  return useQuery({
    queryKey: ["individualInstituteAll"],
    queryFn: () => fetchAllIndividualInstituteChats(containerId, searchText),
    enabled: false,
  });
};

export const useFetchGroupInstituteChats = (containerId, searchText) => {
  return useQuery({
    queryKey: ["groupInstituteAll"],
    queryFn: () => fetchAllGroupInstituteChats(containerId, searchText),
    enabled: false,
  });
};

export const useFetchGroupMaterialChats = (projectId, searchText) => {
  return useQuery({
    queryKey: ["individualMaterialGroup"],
    queryFn: () => fetchAllGroupMaterialChats(projectId, searchText),
    enabled: false,
  });
};
