import React from "react";
import { Modal } from "react-bootstrap";

function Profile({ show, handleClose, handleSubmit }) {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="wt-user modal-walkthrough"
      size="lg"
    >
      <div className="modal-content p-0">
        <div className="speech right p-5 m-0">
          <div className="row">
            <div className="col-12 d-flex justify-content-end mb-3 me-4">
              <button type="button" className="btn" onClick={handleClose}>
                <img src="/images/close-modal.svg" />
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-6 pe-4">
              <img src="/images/wt-user.svg" />
            </div>
            <div className="col-6 p-0 px-5">
              <h5 className="fw-semibold">Profile</h5>
              <p style={{ fontSize: "14px" }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                ac massa non nulla hendrerit mollis. Pellentesque tortor urna,
                cursus sed neque sagittis, interdum laoreet dolor. Nunc varius
                nulla nec egestas maximus. Donec efficitur non metus dapibus
                lacinia. Morbi tincidunt sed leo ut ultricies. Mauris eu ligula
                ac augue facilisis elementum sed eget enim.
              </p>
              <div
                className="d-flex justify-content-end"
                onClick={handleSubmit}
              >
                {/* <a
                  className="btn main-btn mt-4 px-4 cursor-pointer"
                  style={{ backgroundColor: "#0744e1" }}
                >
                  Next
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default Profile;
