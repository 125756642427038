import { Sidebar } from "components";
import { PageTitle } from "components/Custom";
import {
  BalanceCard,
  BillingCard,
  InvoicesCard,
  SpecialTierCard,
} from "components/Subscription";
import PlanCard from "components/Subscription/PlanCard";
import { AddTokens, YourPlan } from "components/WalkThrough";
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

function Subscription() {
  const queryClient = useQueryClient();
  const cachedBranding = queryClient.getQueryData(["branding"])?.data;
  const location = useLocation();
  const [showAddTokens, setShowAddTokens] = useState(
    location?.state?.showModal || false
  );
  // const [showYourPlan, setShowYourPlan] = useState(false);
  // const [selectedAddOn, setSelectedAddOn] = useState({});
  const navigate = useNavigate();

  const plan = {
    tokenBalance: "10,000",
    name: "######",
    renewalDate: "######",
    daysTillRenewal: 30,
    autoRenewal: true,
    gptVersion: "######",
    tokens: "10,000",
    consumptions: "FREE",
    integration: "FREE",
    support: "FREE",
    paymentMethod: "######",
    benefits: [
      "Support 5/7",
      "Intial Consumption",
      "Dashboard",
      "Bandwind Monitoring",
      "Questions & Answers Monitoring",
    ],
    addsOn: [
      {
        renewalDate: "######",
        daysTillRenewal: 10,
        name: "Voice Record",
        description: "Azura Cognitive Services",
        minutes: 1000,
        price: 0,
        tax: 0,
      },
      {
        renewalDate: "######",
        daysTillRenewal: 30,
        name: "Image",
        description: "Azura Cognitive Services",
        minutes: 0,
        price: 0,
        tax: 0,
      },
    ],
    tiers: ["Educational Tier", "High Consumptions Tier"],
    billingAddress: {
      fullName: "######",
      companyName: "######",
      buildingName: "######",
      areaName: "######",
      cityName: "Gotham",
      postalNumber: "0000",
      countryName: "USA",
      trn: 111,
    },
  };

  const cardInfo = {
    number: "1111 1111 1111 1111",
    expiryMonth: "05",
    expiryYear: "23",
    securityCode: "123",
  };

  const handleQuickTourClick=()=>{
    navigate("/users",{ state: { showAddUser: true } })
    window.scrollTo(0, 0)
  }

  return (
    <div
      className="container-fluid"
      style={{ backgroundColor: cachedBranding?.page_BackgroundColor }}
    >
      <div className="row flex-nowrap">
        <Sidebar
          handleTourClick={handleQuickTourClick}
        />
        <div className="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-2"></div>
        <div
          className="col-10 col-sm-9 col-md-9 col-lg-9 col-xl-10"
          style={{ padding: "0 5%" }}
        >
          <div className="dashboard">
            <div className="page-title">
              <div className="row w-100">
                <PageTitle title="Subscription" time="29 May 2023 - 4:02 PM" />
              </div>
            </div>
            <BalanceCard
              tokens={plan?.tokenBalance}
              cachedBranding={cachedBranding}
            />
            <PlanCard plan={plan} cachedBranding={cachedBranding} />
            <SpecialTierCard plan={plan} cachedBranding={cachedBranding} />
            <BillingCard
              plan={plan}
              cardInfo={cardInfo}
              cachedBranding={cachedBranding}
            />
            <InvoicesCard cachedBranding={cachedBranding} />
          </div>
        </div>
      </div>
      <AddTokens
        show={showAddTokens}
        handleClose={() => setShowAddTokens(false)}
        handleSubmit={() =>
          navigate("/company-profile", { state: { showModal: true } })
        }
        cachedBranding={cachedBranding}
      />
      {/* <YourPlan
        show={showYourPlan}
        handleClose={() => setShowYourPlan(false)}
        handleSubmit={() =>
          navigate("/company-profile", { state: { showModal: true } })
        }
      /> */}
    </div>
  );
}

export default Subscription;
