import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "constants";
import React, { useEffect, useState } from "react";
import { Collapse } from "react-bootstrap";
import toast from "react-hot-toast";
import { cleanToken } from "utils";

function MaterialFileQuestionCard({ qna, refetch, cachedBranding }) {
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");

  useEffect(() => {
    setQuestion(qna?.question);
    setAnswer(qna?.answer);
  }, []);

  const deleteQna = useMutation({
    mutationFn: () => {
      return axios.delete(
        API_URL + `/QNA/DeleteSourceQNA/${qna?.materialQuestionId}`,
        {
          headers: {
            Authorization: `Bearer ${cleanToken(
              localStorage.getItem("token")
            )}`,
          },
        }
      );
    },
    onSuccess: async (data) => {
      console.log("data: ", data);
      toast.success("Deleted!");
      refetch();
    },
    onError: (error) => {
      console.log("error: ", error);
    },
  });

  const handleDelete = () => {
    deleteQna.mutate();
  };

  const save = useMutation({
    mutationFn: () => {
      return axios.post(
        API_URL + `/QNA/UpdateSourceQNA`,
        {
          materialQuestionId: qna?.materialQuestionId,
          question: question,
          answer: answer,
        },
        {
          headers: {
            Authorization: `Bearer ${cleanToken(
              localStorage.getItem("token")
            )}`,
          },
        }
      );
    },
    onSuccess: async (data) => {
      console.log("data: ", data);
      refetch();
    },
    onError: (error) => {
      console.log("error: ", error);
    },
  });

  const handleSave = () => {
    save.mutate();
  };

  return (
    <div className=" normal-card d-flex align-items-start">
      <div className={`w-100 d-flex align-items-start justify-content-end`}>
        {!editMode ? (
          <Collapse in={open}>
            <div className={`d-flex qna-area`}>
              <span
                className={`${open ? "qna" : "qna-view"}`}
                style={{ color: cachedBranding?.page_TextColor }}
              >
                {qna?.question}
              </span>
              <span
                className={`${open ? "qna" : "qna-view"}`}
                style={{ color: cachedBranding?.page_TextColor }}
              >
                {qna?.answer}
              </span>
            </div>
          </Collapse>
        ) : (
          <Collapse in={open}>
            <div className="d-flex qna-area">
              <textarea
                className="w-50 me-3"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
              ></textarea>
              <textarea
                className="w-50"
                value={answer}
                onChange={(e) => setAnswer(e.target.value)}
              ></textarea>
            </div>
          </Collapse>
        )}
        <div className="d-flex">
          {editMode ? (
            <button
              className="btn"
              type="button"
              onClick={() => (handleSave(), setEditMode(false))}
            >
              <img src="/images/save.svg" />
            </button>
          ) : (
            <button
              className="btn"
              type="button"
              onClick={() => setEditMode(true)}
            >
              <img src="/images/edit-user.svg" />
            </button>
          )}
          <button className="btn" type="button" onClick={() => handleDelete()}>
            <img src="/images/delete-user.svg" />
          </button>
          <button
            className="btn"
            type="button"
            onClick={() => setOpen(!open)}
            aria-controls="example-collapse-text"
            aria-expanded={open}
          >
            <img src="/images/arrow-down.svg" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default MaterialFileQuestionCard;
