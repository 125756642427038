import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import SpinnerCircle from "components/Custom/SpinnerCircle";
import { API_URL } from "constants";
import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap"; // Import Spinner from react-bootstrap
import toast from "react-hot-toast";
import { cleanToken } from "utils";

function CreateIndexModal({
  active,
  handleClose,
  refetchContainer,
  containerId,
}) {
  const [index, setIndex] = useState(0);

  const createIndex = useMutation({
    mutationFn: (fields) => {
      return axios.post(
        API_URL +
          `/Azure/CreateSearchIndexer/${containerId}?scheduleOption=${index}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${cleanToken(
              localStorage.getItem("token")
            )}`,
          },
        }
      );
    },
    onSuccess: async (data) => {
      console.log("data: ", data);
      handleClose();
      refetchContainer();
      toast.success("Index Created!");
    },
    onError: (error) => {
      console.log("error: ", error);
    },
  });

  const handleSubmit = () => {
    createIndex.mutate();
  };

  return (
    <Modal
      show={active}
      onHide={handleClose}
      className="topics-categories"
      centered
      size="md"
    >
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalCenterTitle">
            Create Index
          </h5>
          <div className="modal-option">
            <button type="button" className="btn" onClick={handleClose}>
              <img src="/images/close-modal.svg" />
            </button>
          </div>
        </div>
        <div className="modal-body">
          <div className="d-flex align-items-center mb-3">
            <div className="form-group profile-field w-100 mx-2">
              <label className="fw-medium">Schedule of Indexing</label>
              <div className="color-pick">
                <select
                  className="form-select border border-0"
                  style={{ fontSize: "12px" }}
                  value={index}
                  onChange={(e) => setIndex(e.target.value)}
                >
                  <option value={0}>Once</option>
                  <option value={1}>Daily</option>
                  <option value={2}>Hourly</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer d-flex align-items-end">
          {createIndex.isLoading ? ( // Check if the mutation is in progress
            <SpinnerCircle />
          ) : (
            <>
              <button
                type="button"
                className="btn main-btn w-25 mx-3"
                onClick={handleSubmit}
                style={{ backgroundColor: "#0744e1" }}
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-secondary w-25"
                onClick={handleClose}
              >
                Cancel
              </button>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default CreateIndexModal;
