import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "../constants";
import { cleanToken } from "utils";

const fetchCompanyBranding = async () => {
  return await axios.get(API_URL + `/Branding/GetBranding`, {
    headers: {
      Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
    },
  });
};

export const useFetchCompanyBranding = (navigate) => {
  return useQuery({
    queryKey: ["branding"],
    queryFn: () => fetchCompanyBranding(),
    enabled: true,
    refetchOnWindowFocus: false,
    onError: () => {
      navigate("/");
    },
  });
};
