import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "constants";
import { useFetchGroupImage } from "hooks/groups";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { cleanToken } from "utils";

function EditGroupCard({
  groupName,
  setGroupName,
  refetchGroup,
  groupId,
  cachedBranding,
}) {
  const { data: image, refetch } = useFetchGroupImage(groupId);

  useEffect(() => {
    if (groupId) {
      refetch();
    }
  }, [groupId]);

  const uploadGroupImage = useMutation({
    mutationFn: (fields) => {
      return axios.post(API_URL + `/Group/UploadGroupImage`, fields, {
        headers: {
          Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
        },
      });
    },
    onSuccess: async (data) => {
      refetch();
      refetchGroup();
      toast.success("Image successfully uploaded")
    },
    onError: (error) => {
      console.log("error: ", error);
    },
  });

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("FileToUpload", file);
    formData.append("GroupId", groupId);
    if (file) {
      uploadGroupImage.mutate(formData);
    }
  };

  const renderInitials = (name) => {
    const words = name.split(" ");
    const firstLetters = [];
    for (const word of words) {
      if (word.length > 0) {
        firstLetters.push(word[0]);
      }
    }
    const result = firstLetters.join("");
    return (
      <div className="group-initials2 position-relative">
        <span>{result}</span>
      </div>
    );
  };

  return (
    <div className="add-group">
      <h5
        className="fw-semibold mb-3"
        style={{ color: cachedBranding?.page_TextColor }}
      >
        Edit Group
      </h5>
      <div className="new-group-name">
        <div className="group-img">
          {image?.data?.imageBytes ? (
            <img
              src={`data:image/jpeg;base64,${image?.data?.imageBytes}`}
              className=" user-image"
            />
          ) : (
            renderInitials(groupName)
          )}
          <label htmlFor="file-upload" className="custom-file-upload">
            Edit Photo
          </label>
          <input
            id="file-upload"
            type="file"
            accept="image/*"
            onChange={handleImageChange}
          />
        </div>
        <div className="form-group newgroup-field">
          <div className="d-flex justify-content-between">
            <label
              htmlFor="exampleInputEmail1"
              className="group-label"
              style={{ color: cachedBranding?.page_TextColor }}
            >
              Group Name
            </label>
          </div>
          <input
            type="email"
            className="form-control newgroup-input"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
}

export default EditGroupCard;
