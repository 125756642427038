import React from "react";

function DirectRow({ chat }) {
  return (
    <div className="direct-card">
      <img src="../images/user1.png" />
      <div className="direct-info new">
        <h6 className="text-black">{chat?.name}</h6>
        <span>{chat?.lastMessage}</span>
      </div>
      {chat?.missedMessage !== 0 && (
        <div className="new-count main-bg d-flex align-items-center justify-content-center text-center rounded-circle text-white p-2 fw-semibold">
          <span>{chat?.missedMessage}</span>
        </div>
      )}
      <div className="direct-actions">
        <div className="dropdown">
          <button className="px-0 bg-transparent border-0 delete" type="button">
            <img src="/images/delete-user.svg" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default DirectRow;
