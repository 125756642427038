import { useQueryClient } from "@tanstack/react-query";
import { useFetchUserImage } from "hooks/users";
import { isEmpty } from "lodash";
import React, { useEffect } from "react";

function Admin({ admin }) {
  const queryClient = useQueryClient();
  const cachedBranding = queryClient.getQueryData(["branding"])?.data;

  return (
    <div className="admin-card">
      <div className="admin-name">
        <img
          src={
            admin.userImage
              ? `data:image/jpeg;base64,${admin.userImage}`
              : "/images/user-empty.svg"
          }
          width="30"
          height="30"
          className="rounded-circle"
          alt="User"
        />
        <span
          style={{
            color: cachedBranding?.page_TextColor,
          }}
        >
          {admin?.fullName}
        </span>
      </div>
    </div>
  );
}

export default Admin;
