import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { RoundedButton } from "components/Custom";
import { API_URL } from "constants";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { cleanToken } from "utils";

function AddTagsModal({ show, handleClose, tags, group, refetchGroups }) {
  const [tagText, setTagText] = useState("");

  const addTag = useMutation({
    mutationFn: () => {
      return axios.post(
        API_URL + `/Group/AddTagToGroup/${tagText}/${group?.groupId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${cleanToken(
              localStorage.getItem("token")
            )}`,
          },
        }
      );
    },
    onSuccess: async (data) => {
      console.log("data: ", data);
      setTagText("");
      refetchGroups();
      handleClose();
      toast.success("Tag successfully added")
    },
    onError: (error) => {
      console.log("error: ", error);
    },
  });

  const handleAdd = () => {
    addTag.mutate();
  };

  return (
    <Modal show={show} onHide={handleClose} centered size="md">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Add Tags</h4>
          <div className="modal-option">
            <button type="button" className="btn" onClick={handleClose}>
              <img src="/images/close-modal.svg" />
            </button>
          </div>
        </div>
        <div className="modal-body editProfile justify-content-around flex-column">
          <div className="input-group align-items-center mb-1">
            <input
              type="text"
              className="form-control newgroup-input p-1 px-3 rounded-pill"
              placeholder="Enter tag"
              value={tagText}
              onChange={(e) => setTagText(e.target.value)}
            />
            <RoundedButton
              text="Add Tag"
              handleOnClick={() => handleAdd()}
              className="btn shadow-sm main-btn p-3 w-500 rounded-pill ms-3"
            />
          </div>
          {/* <div className="details-name">
            <div className="group-topics mb-4 mt-3">
              {tags &&
                tags.map((tag, index) => (
                  <>
                    <span key={index}>
                      {tag?.tagName}{" "}
                      <img
                        src="/images/remove.svg"
                        className="remove-btn cursor-pointer"
                      />
                    </span>{" "}
                  </>
                ))}
            </div>
          </div> */}
        </div>
        {/* <div className="modal-footer">
          <RoundedButton
            text="Save tags"
            handleOnClick={() => {}}
            className="main-btn"
          />
        </div> */}
      </div>
    </Modal>
  );
}

export default AddTagsModal;
