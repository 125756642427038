import React from "react";

function WarningMessage({ cachedBranding }) {
  return (
    <div className="cont-wmessage">
      <img src="/images/notice.png" alt="vector" />
      <p
        className="w-message"
        style={{ color: cachedBranding?.page_TextColor }}
      >
        Ai category is On, the token consumption is double.
      </p>
    </div>
  );
}

export default WarningMessage;
