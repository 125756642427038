function TabButton({ value, setValue, TabOptions, cachedBranding }) {
  return (
    <div className="nav nav-tabs nav-fill data-filter px-1 mt-1">
      {TabOptions &&
        TabOptions.map((option) => (
          <a
            className={`nav-item data-link cursor-pointer`}
            onClick={() => setValue(option.value)}
            style={{
              backgroundColor:
                option.value === value ? cachedBranding?.page_ButtonColor : "",
              color: option.value === value ? "#fff" : "",
            }}
          >
            {option.Text}
          </a>
        ))}
    </div>
  );
}

export default TabButton;
