import React from "react";
import { Modal } from "react-bootstrap";

function Chat({ show, handleClose, handleSubmit, cachedBranding }) {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="chatt-plan modal-walkthrough"
      size="lg"
    >
      <div className="modal-content p-0">
        <div className="speech left p-2  m-0">
          <div className="row">
            <div className="col-12 d-flex justify-content-end mb-3 me-4">
              <button type="button" className="btn" onClick={handleClose}>
                <img src="/images/close-modal.svg" />
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-4 pe-4">
              <img src="/images/chat-console.svg" className="chat-svg-walk" />
            </div>
            <div className="col-8 p-0 px-5">
              <h5 className="fw-semibold">Chat Console</h5>
              <ol style={{ fontSize: "14px" }}>
                <li>Toggle between Individual chat and Group chat ways.</li>
                <li>Toggle between internet data and institute data..</li>
                <li>Click on New Chat and start chatting!</li>
                <li>
                  View history of chats, you can favorite and search in chats as
                  well!
                </li>
                <li>
                  Choose whether you want the AI-generated categories or manual
                  assignment of Q&A to categories.
                </li>
                <li>You can add a category for manual assignment.</li>
              </ol>
              <div className="d-flex justify-content-end">
                <a
                  className="btn main-btn mt-4 px-4 cursor-pointer"
                  style={{ backgroundColor: cachedBranding?.page_ButtonColor }}
                  onClick={handleSubmit}
                >
                  Next
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default Chat;
