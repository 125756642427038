import React, { useEffect, useState } from "react";
// import TagsDisplay from "./TagsDisplay";
import { useFetchGroupImage } from "hooks/groups";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
// import ImagesDisplay from "./ImagesDisplay";

function AnswersModel({ group, refetchGroups, selected, cachedBranding }) {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const { data: image, refetch: refetchImage } = useFetchGroupImage(
    group?.groupId
  );

  useEffect(() => {
    if (!isEmpty(group?.groupId)) {
      refetchImage();
    }
  }, [group]);

  return (
    <div className="col-4">
      <button
        type="button"
        className="group-btn"
        onClick={() => navigate("/subjective-answers/model")}
      >
        <div className="group-card">
          <div className="group-name">
            <div className="col-9">
              <h5 style={{ color: cachedBranding?.page_TextColor }}>
                Sample Model
              </h5>
            </div>
            <div
              className="model-numbers"
              style={{ color: cachedBranding?.page_TextColor }}
            >
              90
            </div>
          </div>
          <div className="group-details">
            <div>
              <div className="group-count p-0 pt-2">
                <span
                  style={{ color: cachedBranding?.page_TextColor }}
                  className="teacher-title"
                >
                  Teacher
                </span>
              </div>
              <div className="users-group">
                <img
                  className="images-group-card"
                  src={"/images/user-empty.svg"}
                  alt=""
                />
                <span
                  className="teacher-name mt-1"
                  style={{ color: cachedBranding?.page_TextColor }}
                >
                  Ana Smith
                </span>
              </div>
            </div>
            <div>
              <div className="group-count p-0 border-0 m-0">
                <span
                  style={{ color: cachedBranding?.page_TextColor }}
                  className="teacher-title"
                >
                  Students (25)
                </span>
              </div>
              <div className="users-group">
                <img
                  className="images-group-card"
                  src={"/images/user-empty.svg"}
                  alt=""
                />
                <img
                  className="images-group-card"
                  src={"/images/user-empty.svg"}
                  alt=""
                />
                <img
                  className="images-group-card"
                  src={"/images/user-empty.svg"}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </button>
    </div>
  );
}

export default AnswersModel;
