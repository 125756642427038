import React from "react";
import { Modal } from "react-bootstrap";

function LockModal({ show, handleClose, handleSubmit }) {
  return (
    <Modal show={show} onHide={handleClose}>
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Lock Customer</h4>
          <div className="modal-option">
            <button type="button" className="btn" onClick={handleClose}>
              <img src="/images/close.svg" />
            </button>
          </div>
        </div>
        <div className="modal-body editProfile">
          Are you sure you want to lock this customer?
        </div>
        <div className="modal-footer d-flex justify-content-center">
          <button
            type="button"
            className="btn cancel-btn rounded-pill w-25 px-4"
            onClick={handleClose}
          >
            No
          </button>
          <button
            type="button"
            className="btn main-admin-btn rounded-pill w-25 px-4"
            onClick={handleSubmit}
          >
            Yes
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default LockModal;
