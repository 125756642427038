import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "constants";
import { isEmpty } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { cleanToken } from "utils";

function Edit({ handleOnSubmit, user, refetch, image, cachedBranding }) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [phone, setPhone] = useState("");
  const [department, setDepartment] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const fileInputRef = useRef(null);
  const [roleId, setRoleId] = useState();
  const [username,setUsername]=useState("")

  useEffect(() => {
    if (!isEmpty(user)) {
      setFirstName(user?.firstName);
      setLastName(user?.lastName);
      setEmail(user?.displayEmail);
      setMobile(user?.mobile);
      setPhone(user?.phone);
      setDepartment(user?.department);
      setRoleId(user?.roleId);
      setUsername(user?.username)
    }
  }, [user]);

  const editProfile = useMutation({
    mutationFn: (fields) => {
      return axios.put(API_URL + `/User/EditUser/${user?.userId}`, fields, {
        headers: {
          Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
        },
      });
    },
    onSuccess: async (data) => {

      refetch();
      handleOnSubmit();
      toast.success("Saved Successfully")

    },
    onError: (error) => {
      console.log("error: ", error);
    },
  });

  const uploadProfilePicture = useMutation({
    mutationFn: (fields) => {
      return axios.post(API_URL + `/User/UploadUserImage`, fields, {
        headers: {
          Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
        },
      });
    },
    onSuccess: async (data) => {
      console.log("data: ", data);
      refetch();
      toast.success("Uploaded Successfully")
    },
    onError: (error) => {
      console.log("error: ", error);
    },
  });

  const handleSubmit = () => {
    editProfile.mutate({
      firstName: firstName,
      lastName: lastName,
      displayEmail: email,
      mobile: mobile,
      phone: phone,
      department: department,
      roleId: roleId,
      username: username,
    });
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size <= 5 * 1024 * 1024) {
        const formData = new FormData();
        formData.append("FileToUpload", file);
        uploadProfilePicture.mutate(formData);
      } else {
        toast.error("File size exceeds the maximum limit of 5MB.");
        event.target.value = null;
      }
    }
  };

  const handleSelectImage = () => {
    // Trigger the file input using the ref
    fileInputRef.current.click();
  };

  return (
    <>
      <div className="d-flex w-100 align-items-center mb-3">
        <div className="d-flex w-50">
          {image?.imageBytes ? (
            <img
              src={`data:image/jpeg;base64,${image?.imageBytes}`}
              className=" user-image"
            />
          ) : (
            <img src="/images/user-empty.svg" className="user-image" />
          )}
          <input
            type="file"
            ref={fileInputRef} // Associate the ref with the file input
            accept="image/*"
            onChange={handleImageChange}
            style={{ display: "none" }}
          />
          <div
            style={{
              position: "relative",
              left: "-27px",
              marginLeft: 0,
            }}
          >
            <button className="btn main-bg p-1 rounded-circle border-light">
              <img
                className=""
                src="/images/edit-user.svg"
                style={{ filter: "invert(1)", width: "18px" }}
                onClick={handleSelectImage}
              />
            </button>
          </div>
        </div>

        <div className="w-100">
          <div className="form-group profile-field w-100">
            <label style={{ color: cachedBranding?.page_TextColor }}>
              First Name
            </label>
            <input
              type="text"
              className="form-control profile-input w-100"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          {/* <span style={{ fontSize: "12px" }}>UID:1234</span> */}
        </div>
      </div>

      <div className="details d-flex w-100 flex-wrap justify-content-between">
        <div className="form-group profile-field">
          <label style={{ color: cachedBranding?.page_TextColor }}>
            Last Name
          </label>
          <input
            type="text"
            className="form-control profile-input"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>

        {/* <div className="form-group profile-field">
          <label for="exampleInputEmail1">Username</label>
          <input
            type="text"
            className="form-control profile-input"
            on
          />
        </div> */}

        <div className="form-group profile-field">
          <label style={{ color: cachedBranding?.page_TextColor }}>Email</label>
          <input
            type="email"
            className="form-control profile-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div className="form-group profile-field">
          <label style={{ color: cachedBranding?.page_TextColor }}>
            Mobile
          </label>
          <input
            type="number"
            className="form-control profile-input"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
          />
        </div>

        <div className="form-group profile-field">
          <label style={{ color: cachedBranding?.page_TextColor }}>Phone</label>
          <input
            type="phone"
            className="form-control profile-input"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>

        <div className="form-group profile-field">
          <label style={{ color: cachedBranding?.page_TextColor }}>
            Department
          </label>
          <input
            type="text"
            className="form-control profile-input"
            value={department}
            onChange={(e) => setDepartment(e.target.value)}
          />
        </div>

        {/* <div className="details-name w-50">
          <span>Role</span>
          <p>Super Admin</p>
        </div>
        <div className="form-group profile-field">
          <label for="exampleInputEmail1">Change Password</label>
          <input
            type="password"
            className="form-control profile-input"
            id="exampleInputEmail1"
            placeholder="**********"
          />
        </div> */}
      </div>
      <button
        className="btn main-btn rounded-pill w-100 mt-5"
        onClick={handleSubmit}
        style={{ backgroundColor: cachedBranding?.page_ButtonColor }}
      >
        Save Changes
      </button>
    </>
  );
}

export default Edit;
