import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { RoundedButton } from "components/Custom";
import { API_URL } from "constants";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { cleanToken } from "utils";

const NewCategoryModal = ({ show, handleClose, refetchCategories }) => {
  const [categoryName, setCategoryName] = useState("");

  const addNewCategory = useMutation({
    mutationFn: () => {
      return axios.post(
        API_URL + `/api/Category/SaveCategory/${categoryName}`,{},
        {
          headers: {
            Authorization: `Bearer ${cleanToken(
              localStorage.getItem("token")
            )}`,
          },
        }
      );
    },
    onSuccess: () => {
      refetchCategories()
      setCategoryName("")
      toast.success("Successfully added")
      handleClose()
    
    },
    onError: (error) => {
      console.log("error: ", error);
    },
  });

  const handleSubmit = () => {
    addNewCategory.mutate();
  };

  return (
    <Modal show={show} onHide={handleClose} centered >
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">New Category</h4>
          <div className="modal-option">
            <button type="button" className="btn" onClick={handleClose}>
              <img src="/images/close-modal.svg" />
            </button>
          </div>
        </div>
        <div className="modal-body editProfile justify-content-around flex-column">
          <div className="row px-2">
            <div className="w-50">
              <label className="mb-4">Category Name</label>
              </div>
              <input
                type="text"
                className="form-control profile-input"
                placeholder="category name"
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
              />
            
          </div>
          <div>
            <RoundedButton
              text=" Submit "
              handleOnClick={() => handleSubmit()}
              className="btn shadow-sm main-btn p-3 rounded-pill ms-3 float-end"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default NewCategoryModal;
