import React, { useState } from "react";
import {
  CompanyName,
  TokenCard,
  PlanDetails,
  CompanyDetails,
  Payment,
  Invoices,
  Nav,
} from "components/Admin";
import AddTokensModal from "components/Subscription/AddTokensModal";
import { AddPlugin, EditCompanyDetails } from "components/Admin/modal";
import { AddMoreModal } from "components/Custom";
import ChangePlanModal from "components/Subscription/ChangePlanModal";

function CustomerDetails() {
  const [showAddTokensModal, setShowAddTokensModal] = useState(false);
  const [showAddMoreModal, setShowAddMoreModal] = useState(false);
  const [showChangePlanModal, setShowChangePlanModal] = useState(false);
  const [showAddPluginModal, setShowAddPluginModal] = useState(false);
  const [showEditCompanyDetailsModal, setShowEditCompanyDetailsModal] =
    useState(false);
  const companyDetails = {
    id: 1,
    companyName: "####",
    tokens: 20000,
    plan: "####",
    span: "Monthly",
    expiry: "May 05, 2023 (in 30 days)",
    gptVersion: "GPT 3 Da Vinci",
    tokens: 100000,
    consumptions: "Free",
    integration: "Free",
    support: "Free",
    services: [
      "Support 5/7",
      "Initial Consumption",
      "Dashboard",
      "Bandwind Monitoring",
      "Questions & Answers Monitoring",
    ],
    addOns: [
      {
        type: "Voice Record",
        price: "$0",
        services: [
          "1,000 minutes",
          "Azure Cognitive Services",
          "Audio Listening",
        ],
      },
    ],
    contactPerson: {
      name: "####",
      email: "####@gmail.com",
      mobile: "+####",
      companyPhone: "####",
    },
    billingAddress: {
      fullName: "####",
      companyName: "####",
      buildingName: "####",
      areaName: "####",
      cityName: "####",
      postalNumber: "0000",
      countryName: "USA",
      trn: 111,
    },
  };

  const cardInfo = {
    number: "1111 1111 1111 1111",
    expiryMonth: "05",
    expiryYear: "23",
    securityCode: "123",
  };

  const invoices = [
];

  return (
    <div className="row">
      <div className="col-2">
        <Nav />
      </div>
      <div className="col-10 px-5 pt-4">
        <CompanyName companyName={companyDetails?.companyName} />
        <TokenCard
          tokens={companyDetails?.tokens}
          setShowAddTokensModal={setShowAddTokensModal}
        />
        <PlanDetails
          setShowChangePlanModal={setShowChangePlanModal}
          setShowAddPluginModal={setShowAddPluginModal}
          companyDetails={companyDetails}
        />
        <CompanyDetails
          setShowEditCompanyDetailsModal={setShowEditCompanyDetailsModal}
          contactPerson={companyDetails?.contactPerson}
        />
        <Payment
          billingAddress={companyDetails?.billingAddress}
          cardInfo={cardInfo}
        />
        <Invoices invoices={invoices} />
      </div>
      <EditCompanyDetails
        show={showEditCompanyDetailsModal}
        handleClose={() => setShowEditCompanyDetailsModal(false)}
        handleSubmit={() => {}}
        data={companyDetails?.contactPerson}
      />
      <AddPlugin
        show={showAddPluginModal}
        handleClose={() => setShowAddPluginModal(false)}
        handleSubmit={() => {}}
      />
      <ChangePlanModal
        show={showChangePlanModal}
        handleClose={() => setShowChangePlanModal(false)}
        handleSubmit={() => {}}
      />
      <AddTokensModal
        show={showAddTokensModal}
        handleClose={() => setShowAddTokensModal(false)}
        handleAddMore={() => (
          setShowAddTokensModal(false), setShowAddMoreModal(true)
        )}
        adminModal={true}
      />
      <AddMoreModal
        show={showAddMoreModal}
        handleClose={() => setShowAddMoreModal(false)}
        handleBackPress={() => (
          setShowAddMoreModal(false), setShowAddTokensModal(true)
        )}
        adminModal={true}
        handleSubmit={() => {}}
      />
    </div>
  );
}

export default CustomerDetails;
