import React from "react";
import GroupMember from "./GroupMember";

function GroupMemberList({ members ,groupId,refetchGroups}) {
  return (
    <div className="members-list">
      {members ? (
        members.map((member, index) => (
          <GroupMember member={member} groupId={groupId} key={index}  refetchGroups={refetchGroups}/>
        ))
      ) : (
        <p>no members</p>
      )}
    </div>
  );
}

export default GroupMemberList;
