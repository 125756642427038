import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

function UpdateBillingAddressModal({ show, handleClose, billingAddress }) {
  const [fullName, setFullName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [buildingName, setBuildingName] = useState("");
  const [areaName, setAreaName] = useState("");
  const [cityName, setCityName] = useState("");
  const [postalNumber, setPostalNumber] = useState("");
  const [trn, setTrn] = useState(null);

  useEffect(() => {
    setFullName(billingAddress?.fullName);
    setCompanyName(billingAddress?.companyName);
    setBuildingName(billingAddress?.buildingName);
    setAreaName(billingAddress?.areaName);
    setCityName(billingAddress?.cityName);
    setPostalNumber(billingAddress?.postalNumber);
    setTrn(billingAddress?.trn);
  }, [billingAddress]);

  return (
    <Modal show={show} onHide={handleClose} centered>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalCenterTitle">
            Update Billing Address
          </h5>
          <div className="modal-option">
            <button type="button" className="btn" onClick={handleClose}>
              <img src="/images/close-modal.svg" />
            </button>
          </div>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-12 mb-3">
              <label for="exampleFormControlInput1" className="form-label">
                Full Name
              </label>
              <input
                type="text"
                className="form-control profile-input"
                placeholder="Enter Full Name"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
            </div>
            <div className="col-6 mb-3 w-50">
              <label for="exampleFormControlInput1" className="form-label">
                Company Name
              </label>
              <input
                type="text"
                className="form-control profile-input"
                placeholder="Enter Company Name"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </div>
            <div className="col-6 mb-3 w-50">
              <label for="exampleFormControlInput1" className="form-label">
                Building Name
              </label>
              <input
                type="text"
                className="form-control profile-input"
                placeholder="Enter Building Name"
                value={buildingName}
                onChange={(e) => setBuildingName(e.target.value)}
              />
            </div>
            <div className="col-6 mb-3 w-50">
              <label for="exampleFormControlInput1" className="form-label">
                Area Name
              </label>
              <input
                type="text"
                className="form-control profile-input"
                placeholder="Enter Area Name"
                value={areaName}
                onChange={(e) => setAreaName(e.target.value)}
              />
            </div>
            <div className="col-6 mb-3 w-50">
              <label for="exampleFormControlInput1" className="form-label">
                City Name
              </label>
              <input
                type="text"
                className="form-control profile-input"
                placeholder="Enter City Name"
                value={cityName}
                onChange={(e) => setCityName(e.target.value)}
              />
            </div>
            <div className="col-6 mb-3 w-50">
              <label for="exampleFormControlInput1" className="form-label">
                Postal Number
              </label>
              <input
                type="text"
                className="form-control profile-input"
                placeholder="Enter Postal Number"
                value={postalNumber}
                onChange={(e) => setPostalNumber(e.target.value)}
              />
            </div>
            <div className="col-6 mb-3 w-50">
              <label for="exampleFormControlInput1" className="form-label">
                Country Name
              </label>
              <select
                className="form-select form-select-lg profile-input"
                aria-label=".form-select-lg example"
              >
                <option selected>Select Country</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
            <div className="col-6 mb-3 w-50">
              <label for="exampleFormControlInput1" className="form-label">
                TRN
              </label>
              <input
                type="number"
                className="form-control profile-input"
                value={trn}
                onChange={(e) => setTrn(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer d-flex">
          <button
            type="button"
            className="btn btn-secondary w-25"
            style={{ height: "45px" }}
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn main-btn w-25"
            style={{ backgroundColor: "#0744e1" }}
          >
            Confirm
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default UpdateBillingAddressModal;
