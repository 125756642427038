import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "constants";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { cleanToken } from "utils";

function PromptDescriptionModal({ active, handleClose, container, refetch }) {
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (!isEmpty(container)) {
      setDescription(container?.actAsPrompt);
    }
  }, [container]);

  const editPrompt = useMutation({
    mutationFn: () => {
      return axios.post(
        API_URL +
          `/Azure/ContainerPrompt`,
        {
          "containerId": container?.containerId,
           "actAsPrompt": description
        },
        {
          headers: {
            Authorization: `Bearer ${cleanToken(
              localStorage.getItem("token")
            )}`,
          },
        }
      );
    },
    onSuccess: async (data) => {
      console.log("data: ", data);
      handleClose();
      refetch();
      toast.success("Renamed successfully");
    },
    onError: (error) => {
      console.log("error: ", error);
    },
  });

  return (
    <Modal
      show={active}
      onHide={handleClose}
      className="topics-categories"
      centered
      size="md"
    >
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalCenterTitle">
            Prompt Description
          </h5>
          <div className="modal-option">
            <button type="button" className="btn" onClick={handleClose}>
              <img src="/images/close-modal.svg" />
            </button>
          </div>
        </div>
        <div className="modal-body">
          <div className="d-flex align-items-center mb-0">
            <div className="form-group profile-field w-100 mx-2">
              <label className="fw-medium">
                Prompt Engineering Response Description
              </label>
              <textarea
                type="text"
                className="form-control mb-0"
                value={description}
                rows={3}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer d-flex align-items-end">
          <button
            type="button"
            className="btn main-btn w-25 mx-3"
            onClick={editPrompt.mutate}
            style={{ backgroundColor: "#0744e1" }}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn-secondary w-25"
            onClick={handleClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default PromptDescriptionModal;
