import React from "react";
import DirectRow from "./DirectRow";
import NewChatButton from "./NewChatButton";

function Direct({ active }) {
  const directChats = [
    {
      id: 1,
      name: "Andrew Williams",
      lastMessage: "Lorem Ipsum dolor, for design purpose only",
      missedMessage: 0,
    },
    {
      id: 2,
      name: "John Doe",
      lastMessage: "Testing again!",
      missedMessage: 4,
    },
    {
      id: 3,
      name: "Ed Kemper",
      lastMessage: "Hey All!",
      missedMessage: 2,
    },
  ];

  return (
    <div className={`tab-pane fade ${active ? "show active" : ""}`}>
      <div className="normal-list">
        <NewChatButton
        // cachedBranding={cachedBranding}
        // handleSelect={handleSelect}
        />
        {directChats &&
          directChats.map((chat, index) => (
            <DirectRow chat={chat} key={index} />
          ))}
      </div>
    </div>
  );
}

export default Direct;
