import React, { useRef } from "react";

function AddsOnCard({ handleSelect, item, addsOn }) {
  const checkboxRef = useRef(null);
  return (
    <div
      className="add-on-card w-25 cursor-pointer"
      onClick={() => handleSelect()}
    >
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          <input
            className="form-check-input"
            type="checkbox"
            ref={checkboxRef}
            // onChange={() => handleSelect()}
            checked={addsOn}
          />
          <label
            className="form-check-label px-2 fs-5 fw-semibold"
            htmlFor="exampleRadios1"
          >
            {item.type}
          </label>
        </div>
        <span className="fw-semibold">{item.price}</span>
      </div>
      {item?.services &&
        item?.services.map((service, index) => (
          <span className="fw-light" key={index}>
            {service}
          </span>
        ))}
    </div>
  );
}

export default AddsOnCard;
