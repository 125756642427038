import React, { useState } from "react";
import { Modal } from "react-bootstrap";

function AddMoreModal({
  show,
  handleClose,
  handleBackPress,
  adminModal = false,
  handleSubmit,
}) {
  const [amount, setAmount] = useState(0);

  return (
    <Modal size="lg" show={show} onHide={handleClose} animation={false}>
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Additional Tokens</h4>
          <div className="modal-option">
            <button type="button" className="btn" data-bs-dismiss="modal">
              <img src="../src/assets/svg/close.svg" />
            </button>
          </div>
        </div>
        <div className="modal-body editProfile flex-column align-items-center">
          <div className="form-group profile-field w-100">
            <label for="exampleInputEmail1" className="mb-2">
              Token Amount
            </label>
            <input
              type="number"
              className="form-control profile-input"
              id="exampleInputEmail1"
              placeholder="Enter Token Amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </div>
        </div>
        <div className="modal-footer border-0 justify-content-center">
          {!adminModal ? (
            <button
              type="button"
              className="btn cancel-btn rounded-pill w-25 py-2 px-4"
              onClick={handleClose}
            >
              Cancel
            </button>
          ) : (
            <button
              type="button"
              className="btn cancel-btn rounded-pill w-25 py-2 px-4"
              onClick={handleBackPress}
            >
              Back
            </button>
          )}
          <button
            type="button"
            className="btn main-admin-btn rounded-pill w-25 py-2 px-4"
            onClick={handleSubmit}
          >
            Confirm
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default AddMoreModal;
