import React from "react";
import { useQueryClient } from "@tanstack/react-query";

function StatCard({ title, image, total }) {
  const queryClient = useQueryClient();
  const cachedBranding = queryClient.getQueryData(["branding"])?.data;

  return (
    <div className="col-md-6 col-lg-6 col-xl-3">
      <div className="data-cards">
        <span
          style={{ color: cachedBranding?.page_TextColor, fontSize: "16px" }}
        >
          {title}
        </span>
        <div className="result">
          <img src={image} />
          <h3 style={{ color: cachedBranding?.page_TextColor }}>{total}</h3>
        </div>
      </div>
    </div>
  );
}

export default StatCard;
