import React from "react";
import { Sidebar } from "components";
import { useNavigate, useParams } from "react-router-dom";
import { useFetchQnaByMaterialId } from "hooks/materials";
import { useQueryClient } from "@tanstack/react-query";
import { QnaCard } from "components/Qna";

function MaterialCustomQna() {
  const navigate = useNavigate();
  const { materialId } = useParams();
  const { data: qnas, isLoading } = useFetchQnaByMaterialId(materialId);
  const queryClient = useQueryClient();
  const cachedBranding = queryClient.getQueryData(["branding"])?.data;

  return (
    <div className="container-fluid">
      <div className="row flex-nowrap">
        <Sidebar handleTourClick={() => {}} />
        <div className="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-2"></div>
        <div
          className="col-10 col-sm-9 col-md-9 col-lg-9 col-xl-10"
          style={{ padding: "0 5%" }}
        >
          <div className="dashboard">
            <div className="page-title">
              <div className="row w-100">
                <div className="col-12 d-flex align-items-center">
                  <div className="me-3">
                    <a className="cursor-pointer" onClick={() => navigate(-1)}>
                      <img src="/images/back.svg" />
                    </a>
                  </div>
                  <div className="main-title">
                    <h3 style={{ color: cachedBranding?.page_TextColor }}>
                      Manage Materials
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            {isLoading ? (
              <div>Loading...</div>
            ) : (
              <>
                <div className="normal-card sub-token">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center w-75">
                      <div className="d-flex flex-column w-50">
                        {/* <h6 className="me-5 mb-0">Advance Mathematics</h6> */}
                        <span style={{ color: cachedBranding?.page_TextColor }}>
                          {qnas.length} QnA pairs
                        </span>
                      </div>
                      <div className="search-bar w-50">
                        {/* <img src="/images/search.svg" className="search-ic" />
                        <input
                          className="search-inp"
                          type="text"
                          placeholder="Search..."
                        /> */}
                      </div>
                    </div>
                    <div className=" d-flex">
                      <button className="light-btn p-2 rounded mx-3 border-0">
                        + Custom QnA
                      </button>
                    </div>
                  </div>
                  <div className="d-flex align-items-start">
                    <div className="d-flex justify-content-between w-100 mt-4 mb-0">
                      <h6
                        className="w-50"
                        style={{ color: cachedBranding?.page_TextColor }}
                      >
                        Questions
                      </h6>
                      <h6
                        className="w-50 ms-5 ps-4"
                        style={{ color: cachedBranding?.page_TextColor }}
                      >
                        Answers
                      </h6>
                    </div>
                    <div className="d-flex w-25"></div>
                  </div>
                </div>
                <QnaCard materialId={materialId} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MaterialCustomQna;
