import React from "react";
import Admin from "./Admin";

function AdminList({ admins }) {
  return (
    <div className="members-list">
      {admins &&
        admins.map((admin, index) => <Admin admin={admin} key={index} />)}
    </div>
  );
}

export default AdminList;
