import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "../constants";
import { cleanToken } from "utils";

const fetchActiveIndexers = async () => {
  return await axios.get(API_URL + `/Azure/GetActiveIndexers`, {
    headers: {
      Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
    },
  });
};

const fetchContainers = async () => {
  return await axios.get(API_URL + `/Azure/GetContainers`, {
    headers: {
      Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
    },
  });
};

const fetchMaterialsByContainer = async (containerId) => {
  return await axios.get(
    API_URL + `/Azure/GetMaterialByContainer/${containerId}`,
    {
      headers: {
        Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
      },
    }
  );
};

const fetchContainerById = async (containerId) => {
  return await axios.get(API_URL + `/Azure/GetContainerById/${containerId}`, {
    headers: {
      Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
    },
  });
};

export const useFetchActiveIndexers = () => {
  return useQuery({
    queryKey: ["activeIndexers"],
    queryFn: () => fetchActiveIndexers(),
    enabled: true,
  });
};

export const useFetchContainers = () => {
  return useQuery({
    queryKey: ["containers"],
    queryFn: () => fetchContainers(),
    enabled: true,
  });
};

export const useFetchMaterialsByContainer = (containerId) => {
  return useQuery({
    queryKey: ["materials"],
    queryFn: () => fetchMaterialsByContainer(containerId),
    enabled: false,
  });
};

export const useFetchContainerById = (containerId) => {
  return useQuery({
    queryKey: ["container"],
    queryFn: () => fetchContainerById(containerId),
    enabled: false,
  });
};
