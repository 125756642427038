import React from "react";

function Payment({ billingAddress, cardInfo }) {
  return (
    <div className="normal-admin-card d-flex flex-xl-row flex-lg-column flex-md-column justify-content-between justify-content-md-between w-100">
      <div className="d-flex flex-column w-50 flex-fill mb-md-5">
        <h5 className="fw-semibold mb-4">Payment Method</h5>
        <h6 className="fw-semibold mb-3">Credit Card</h6>
        <div className="">
          <img src="/images/visa.png" />
          <span className="fw-medium mb-3">{cardInfo?.number}</span>
        </div>
      </div>
      <div className="d-flex flex-column flex-fill w-50">
        <h5 className="fw-semibold mb-4">Billing Address</h5>
        <h6 className="fw-semibold mb-3">{billingAddress?.fullName}</h6>
        <span className="fw-light">
          {billingAddress?.companyName}, {billingAddress?.building}
        </span>
        <span className="fw-light">
          {billingAddress?.areaName}, {billingAddress?.cityName},{" "}
          {billingAddress?.postalNumber}, {billingAddress?.countryName}
        </span>
        <span className="fw-light">TRN: {billingAddress?.trn}</span>
      </div>
    </div>
  );
}

export default Payment;
