import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import SpinnerCircle from "components/Custom/SpinnerCircle";
import { API_URL } from "constants";
import React from "react";
import toast from "react-hot-toast";
import { cleanToken } from "utils";

function FileHeader({ cachedBranding, document, refetchChat, setDocumentId }) {
  const removeDocument = useMutation({
    mutationFn: () => {
      return axios.post(
        API_URL + `/Azure/RemoveDocumentByChatId/${document.chatId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${cleanToken(
              localStorage.getItem("token")
            )}`,
          },
        }
      );
    },
    onSuccess: async (data) => {
      console.log("data: ", data);
      setDocumentId("");
      refetchChat();
    },
    onError: () => {
      toast.error("Something went wrong.");
    },
  });

  return (
    <div className="chat-header-mode chat-header">
      <div className="row">
        <div className="col pe-0">
          <img src="/images/pdf.svg" />
        </div>
        <div className="col">
          <p
            className="m-0"
            style={{
              color: cachedBranding?.page_TextColor,
              fontWeight: 500,
              whiteSpace: "nowrap",
            }}
          >
            {document?.doumentName}
          </p>
        </div>
      </div>
      <div className="chat-actions d-flex">
        <div className="mt-1">
          <p
            className="m-0 fs-7"
            style={{ color: cachedBranding?.page_TextColor, fontWeight: 400 }}
          >
            {document?.isFocusNotShared ? "Focus Mode" : "Shared Mode"}
          </p>
        </div>
        <button className="btn" onClick={removeDocument.mutate}>
          {removeDocument.isLoading ? (
            <SpinnerCircle style={{ height: 20, width: 20 }} />
          ) : (
            <img src="/images/close-grey.svg" height={20} width={20} />
          )}
        </button>
      </div>
    </div>
  );
}

export default FileHeader;
