import React from "react";
import "bootstrap/dist/css/bootstrap.css";

function Header() {
  return (
    <div className="row pt-3 mb-3 d-flex align-item-center">
      <div className="col-8">
        <h3 className="fw-semibold">Customer List</h3>
      </div>
    </div>
  );
}

export default Header;
