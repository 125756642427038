import ReactApexChart from "react-apexcharts";
import { useNavigate } from "react-router-dom";
import ChartModal from "./ChartModal";
import { useEffect, useState } from "react";

function LineChart({
  show,
  handleClose,
  chart,
  setShow,
  cachedBranding,
  consumption,
}) {
  const navigate = useNavigate();
  const [consum, setconsum] = useState();

  useEffect(() => {
    let token = [];
    consumption?.data &&
      consumption.data.forEach((c) => {
        token.push(c.tokenCount);
      });
    setconsum(token);
  }, [consumption]);

  const chartSettings = {
    series: [
      {
        name: "Consumption",
        data: consum,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "area",
        toolbar: {
          show: true,
          tools: {
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
            download: `<img src="/images/download-ico.svg" />`,
            customIcons: [
              {
                icon: `<img src="/images/full-screen-ico.svg" height="14" width="14"  class="ps-2" />`,
                index: 0,
                click: () => setShow(true),
                title: "Zoom In",
              },
            ],
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        categories: consumption?.data?.map((c) => c.date) || [],
      },
    },
  };

  return (
    <div>
      <ReactApexChart
        options={chartSettings.options}
        series={chartSettings.series}
        type="area"
        height={350}
      />
      <ChartModal
        show={show}
        handleClose={handleClose}
        chart={chart}
        cachedBranding={cachedBranding}
        child={
          <ReactApexChart
            options={chartSettings.options}
            series={chartSettings.series}
            type="area"
            height={350}
          />
        }
        title="Consumption"
      />
    </div>
  );
}

export default LineChart;
