import React from "react";
import { Modal } from "react-bootstrap";

function Welcome({ show, handleClose, handleSubmit, cachedBranding }) {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="modal-walkthrough"
      centered
    >
      <div className="modal-content p-3">
        <div className="modal-body">
          <div className="d-flex flex-column align-items-center">
            <img src="/images/main-bot.svg" className="mb-3" />
            <p
              className="mb-3"
              style={{ color: cachedBranding?.page_TextColor }}
            >
              Setting up AiDucator is a straightforward process that empowers
              you to unlock its full potential. Follow these step-by-step
              instructions to configure your AiDucator account:{" "}
            </p>
            <a
              className="btn main-btn rounded-pill mb-2 w-50 cursor-pointer"
              onClick={handleSubmit}
              style={{ backgroundColor: cachedBranding?.page_ButtonColor }}
            >
              Start Quick Tour
            </a>
            <button
              type="button"
              className="btn"
              onClick={handleClose}
              style={{ color: cachedBranding?.page_TextColor }}
            >
              Skip Tour
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default Welcome;
