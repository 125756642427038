import React from "react";

function PlanDetails({
  setShowChangePlanModal,
  setShowAddPluginModal,
  companyDetails,
}) {
  return (
    <div className="normal-admin-card ">
      <div className="plan">
        <div className="your-plan">
          <h5>Plan</h5>
          <h5 className="mb-0">{companyDetails?.plan}</h5>
          <span>{companyDetails?.span}</span>
          <p className="fw-light mb-0">Subscription renewal date</p>
          <p className="fw-semibold mb-0">{companyDetails?.expiry}</p>
          <button
            className="btn main-admin-btn rounded-pill mt-3 p-2"
            onClick={() => setShowChangePlanModal(true)}
          >
            Change Plan
          </button>
        </div>
        <div className="includes">
          <h5 className="fw-semibold mb-3">Includes in plan</h5>
          <div className="features">
            <div className="f-list">
              <div className="feature-list">
                <span className="fw-medium">GPT Version:</span>
                <h6 className="fw-semibold mb-0 text-end">
                  {companyDetails?.gptVersion}
                </h6>
              </div>
              <div className="feature-list">
                <span className="fw-medium">Tokens:</span>
                <h6 className="fw-semibold mb-0">{companyDetails?.tokens}</h6>
              </div>
              <div className="feature-list">
                <span className="fw-medium">Consumptions:</span>
                <h6 className="fw-semibold mb-0">
                  {companyDetails?.consumptions}
                </h6>
              </div>
              <div className="feature-list">
                <span className="fw-medium">Integration:</span>
                <h6 className="fw-semibold mb-0">
                  {companyDetails?.integration}
                </h6>
              </div>
              <div className="feature-list">
                <span className="fw-medium">Support:</span>
                <h6 className="fw-semibold mb-3">{companyDetails?.support}</h6>
              </div>
              <h6 className="fw-semibold">Admin Console</h6>
            </div>
            <div className="f-list2">
              {companyDetails?.services &&
                companyDetails?.services.map((service, index) => (
                  <h6 className="fw-semibold mb-3" key={index}>
                    {service}
                  </h6>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className="add-ons w-100">
        <h5 className="fw-semibold mb-4">Add ons:</h5>
        {companyDetails?.addOns &&
          companyDetails?.addOns.map((addOn, index) => (
            <div className="features mt-2" key={index}>
              <div className="add-on-card">
                <div className="d-flex justify-content-between">
                  <h6 className="fw-semibold text-start">{addOn?.type}</h6>
                  <span className="fw-semibold">{addOn?.price}</span>
                </div>
                <div className=" d-flex justify-content-start flex-column align-items-start">
                  {addOn?.services &&
                    addOn?.services.map((service, index) => (
                      <span className="fw-light" key={index}>
                        {service}
                      </span>
                    ))}
                </div>
              </div>
            </div>
          ))}
        <button
          className="btn main-admin-btn rounded-pill mt-3 p-2 w-15"
          onClick={() => setShowAddPluginModal(true)}
        >
          + Add-on
        </button>
      </div>
    </div>
  );
}

export default PlanDetails;
