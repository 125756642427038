import React, { useState, useEffect } from "react";

function Password({ cachedBranding }) {
  return (
    <div>
      <div className="normal-card">
        <div className="px-3">
          <p
            className="mb-0 policies-label"
            style={{ color: cachedBranding?.page_TextColor }}
          >
            Password Settings
          </p>
          <p
            className="mb-0 policies-label-sub"
            style={{ color: cachedBranding?.page_TextColor }}
          >
            Age and History
          </p>
          <div className="row pt-2">
            <div className="col-4">
              <p
                className="password-settings-label mb-1"
                style={{ color: cachedBranding?.page_TextColor }}
              >
                Minimum Password Age
              </p>
              <div className="switch-ai-g">
                <div className="form-check form-switch">
                  <input
                    type="number"
                    className="password-input notification-input"
                    // checked={isChecked}
                    // onChange={() => setIsChecked(!isChecked)}
                  />
                </div>
                <p className="range mt-2">
                  day(s) <br />
                  Range 0-365
                </p>
              </div>
              <div className="cont-wmessage">
                <img src="/images/info.svg" alt="vector" />
                <p
                  className="w-message"
                  style={{ color: cachedBranding?.page_TextColor }}
                >
                  Use 0 for “Never Express”
                </p>
              </div>
            </div>
            <div className="col-4">
              <p
                className="password-settings-label mb-1"
                style={{ color: cachedBranding?.page_TextColor }}
              >
                Enforce Password History
              </p>
              <div className="switch-ai-g">
                <div className="form-check form-switch">
                  <input
                    type="number"
                    className="password-input notification-input"
                    // checked={isChecked}
                    // onChange={() => setIsChecked(!isChecked)}
                  />
                </div>
                <p className="range mt-2">
                  Password(s) <br />
                  Range 0-99
                </p>
              </div>
              <div className="cont-wmessage">
                <img src="/images/info.svg" alt="vector" />
                <p
                  className="w-message"
                  style={{ color: cachedBranding?.page_TextColor }}
                >
                  Use 0 for “Never Express”
                </p>
              </div>
            </div>
            <div className="col-4">
              <p
                className="password-settings-label mb-1"
                style={{ color: cachedBranding?.page_TextColor }}
              >
                Minimum Password Length
              </p>
              <div className="switch-ai-g">
                <div className="form-check form-switch">
                  <input
                    type="number"
                    className="password-input notification-input"
                    // checked={isChecked}
                    // onChange={() => setIsChecked(!isChecked)}
                  />
                </div>
                <p className="range mt-2">
                  Character(s) <br />
                  Range 5-22
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="normal-card">
        <div className="px-3">
          <p
            className="mb-0 policies-label"
            style={{ color: cachedBranding?.page_TextColor }}
          >
            Password Complexity
          </p>
          {/* <div className="switch-ai-g">
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                // checked={isChecked}
                // onChange={() => setIsChecked(!isChecked)}
              />
            </div>
          </div> */}
          <select
            className="form-select border mb-4 mt-2"
            style={{
              fontSize: "12px",
              border: "0.5px solid #939393 !important",
            }}
          >
            <option value="">Select</option>
          </select>
          <div className="cont-wmessage">
            <img src="/images/info.svg" alt="vector" />
            <p
              className="w-message"
              style={{ color: cachedBranding?.page_TextColor }}
            >
              Password requirements apply to password for OneLogin If a user is
              authenticated by an external directory such as Active Directory,
              settings on the directory determine which policy applies.
            </p>
          </div>
        </div>
      </div>
      <div className="normal-card">
        <div className="px-3">
          <p
            className="mb-0 policies-label"
            style={{ color: cachedBranding?.page_TextColor }}
          >
            One-time Password
          </p>
          <div className="switch-ai-g">
            <p
              className="no-margin"
              style={{ color: cachedBranding?.page_TextColor }}
            >
              OTP Auth Required
            </p>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                // checked={isChecked}
                // onChange={() => setIsChecked(!isChecked)}
              />
            </div>
          </div>
          <div className="d-flex flex-row justify-content-between w-50 mt-2">
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                // checked={isLimited}
                // onChange={() => setIsLimited(true)}
              />
              <label
                className="form-check-label otp-label"
                htmlFor="flexRadioDefault1"
                style={{ color: cachedBranding?.page_TextColor }}
              >
                Email Test
              </label>
            </div>
            <div className="form-check w-50">
              <input
                className="form-check-input"
                type="radio"
                // checked={!isLimited}
                // onChange={() => setIsLimited(false)}
                id="flexRadioDefault2"
              />
              <label
                className="form-check-label otp-label"
                htmlFor="flexRadioDefault2"
                style={{ color: cachedBranding?.page_TextColor }}
              >
                OneLogin SMS
              </label>
            </div>
          </div>
          <div className="cont-wmessage">
            <img src="/images/info.svg" alt="vector" />
            <p
              className="w-message"
              style={{ color: cachedBranding?.page_TextColor }}
            >
              Changes to OTP factors won’t affect Password Update settings.
            </p>
          </div>
          <div className="switch-ai-g mt-3">
            <p
              className="no-margin"
              style={{ color: cachedBranding?.page_TextColor }}
            >
              Allow users to unlock their accounts
            </p>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                // checked={isChecked}
                // onChange={() => setIsChecked(!isChecked)}
              />
            </div>
          </div>
          <div className="cont-wmessage">
            <img src="/images/info.svg" alt="vector" />
            <p
              className="w-message"
              style={{ color: cachedBranding?.page_TextColor }}
            >
              Account unlock cannot be combined with the default Email Factor.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Password;
