import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "constants";
import React from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { cleanToken } from "utils";

function DeleteProceedModal({ show, handleClose, type, info }) {
  const deleteUser = useMutation({
    mutationFn: (fields) => {
      return axios.delete(
        API_URL + `/User/DeleteUser?userId=${fields?.userId}`,
        {
          headers: {
            Authorization: `Bearer ${cleanToken(
              localStorage.getItem("token")
            )}`,
          },
        }
      );
    },
    onSuccess: async (data) => {
      console.log("data: ", data);
      handleClose();
      toast.success("User successfully deleted");
    },
    onError: (error) => {
      console.log("error: ", error);
    },
  });

  const deleteGroup = useMutation({
    mutationFn: (fields) => {
      return axios.delete(
        API_URL + `/Group/DeleteGroup?groupId=${fields?.groupId}`,
        {
          headers: {
            Authorization: `Bearer ${cleanToken(
              localStorage.getItem("token")
            )}`,
          },
        }
      );
    },
    onSuccess: async (data) => {
      console.log("data: ", data);
      handleClose();
      toast.success("Group successfully deleted");
    },
    onError: (error) => {
      console.log("error: ", error);
    },
  });

  const handleSubmit = () => {
    if ("user" === type) {
      deleteUser.mutate({
        userId: info?.userId,
      });
    } else {
      deleteGroup.mutate({
        groupId: info?.groupId,
      });
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Attention! </h4>
          <div className="modal-option">
            <button type="button" className="btn" onClick={handleClose}>
              <img src="/images/close-modal.svg" />
            </button>
          </div>
        </div>
        <div className="modal-body editProfile justify-content-around flex-column align-items-center text-center">
          <div className="">
            <img src="/images/warning.png" className="w-75" />
          </div>
          {"group" === type ? "Group's" : "User's"} data will be removed, <br />{" "}
          Do you want to proceed?
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn normal-btn"
            onClick={handleClose}
          >
            No
          </button>
          <button
            type="button"
            className="btn delete-btn"
            onClick={handleSubmit}
          >
            Yes
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default DeleteProceedModal;
