import MaterialsTableHeader from "components/Materials/MaterialsTableHeader";
import React from "react";
import QNAMaterialsTableRow from "./QNAMaterialsTableRow";

function QNAMaterialsTable({ files, refetch, cachedBranding, isQna = false }) {
  return (
    <div className="">
      <div className="table-result">
        <div className="table-responsive-sm">
          <table className="table table-hover">
            <MaterialsTableHeader cachedBranding={cachedBranding} />
            <tbody>
              {files &&
                files.map((file, index) => (
                  <QNAMaterialsTableRow
                    file={file}
                    refetch={refetch}
                    cachedBranding={cachedBranding}
                    key={index}
                    isQna={isQna}
                  />
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default QNAMaterialsTable;
