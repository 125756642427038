import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "constants";
import { useFetchUserImage } from "hooks/users";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { cleanToken } from "utils";

function SetAdminUserItem({ user, cachedBranding, selected, onClick }) {
  const [show, setShow] = useState(false);
  const { data: userImage, refetch: refetchImage } = useFetchUserImage(
    user?.userId
  );

  useEffect(() => {
    if (!isEmpty(user)) {
      refetchImage();
    }
  }, [user]);
  return (
    <div
      className={`member-card cursor-pointer ${
        selected === user?.userId ? "selected-user" : ""
      }`}
      onClick={onClick}
    >
      <div className="member-name">
        <img
          src={
            !isEmpty(userImage?.data?.imageBytes)
              ? `data:image/jpeg;base64,${userImage?.data?.imageBytes}`
              : "/images/user-empty.svg"
          }
        />{" "}
        <span
          style={{
            color: cachedBranding?.page_TextColor,
          }}
        >
          {user?.fullName}
        </span>
      </div>
    </div>
  );
}

export default SetAdminUserItem;
