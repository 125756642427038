import React, { useState } from "react";
import moment from "moment";

function FilterDropdown({ days, handleSetDays, handleOpenCustomDates, dateFilterString, setDateFilterString }) {
  const [toggle, setToggle] = useState(false);

  const handleSelect = (e) => {
    handleSetDays(e);
    setToggle(false);

    //
    var startDate = "";
    var endDate = "";

    startDate = moment(new Date()).add(e*-1, 'days').format("yyyy-MM-DD");
    endDate = moment(new Date()).add(1).format("yyyy-MM-DD");
    setDateFilterString(`and Chats.CreatedOn >= '${startDate}' and Chats.CreatedOn <= '${endDate}'`)
  };

  return (
    <div className="month-filter">
      <div className="dropdown">
        <button
          onClick={() => setToggle(!toggle)}
          className="month dropdown-toggle"
          type="button"
        >
          <img src="/images/cal-ico.svg" />
          {days} days
        </button>
        <div
          className="dropdown-menu"
          style={{ display: toggle ? "block" : "none" }}
        >
          <a
            className="dropdown-item cursor-pointer"
            onClick={() => handleSelect(1)}
          >
            1 day
          </a>
          <a
            className="dropdown-item cursor-pointer"
            onClick={() => handleSelect(3)}
          >
            3 days
          </a>
          <a
            className="dropdown-item cursor-pointer"
            onClick={() => handleSelect(7)}
          >
            7 days
          </a>
          <a
            className="dropdown-item cursor-pointer"
            onClick={() => handleSelect(30)}
          >
            30 days
          </a>
          <a
            className="dropdown-item cursor-pointer"
            onClick={() => handleSelect(90)}
          >
            90 days
          </a>
          <a
            className="dropdown-item cursor-pointer"
            onClick={() => (handleOpenCustomDates(), setToggle(false))}
          >
            Custom
          </a>
        </div>
      </div>
    </div>
  );
}

export default FilterDropdown;
