import { Sidebar } from "components";
import { BackButton, PageTitle, RoundedButton } from "components/Custom";
import React, { useState } from "react";
import { AddTagsCard, AddUsersCard, NewGroupCard } from "components/Users";
import { AddGroup } from "components/WalkThrough";
import { useNavigate, useLocation } from "react-router-dom";
import { cleanToken } from "utils";
import axios from "axios";
import { API_URL } from "constants";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";

function NewGroup() {
  const location = useLocation();
  const [showNewGroup, setShowNewGroup] = useState(
    location?.state?.showModal || false
  );
  const navigate = useNavigate();
  const tags = ["Education", "Math", "Formula", "Algebra"];
  const [groupName, setGroupName] = useState("");
  const queryClient = useQueryClient();
  const cachedBranding = queryClient.getQueryData(["branding"])?.data;

  const saveGroup = useMutation({
    mutationFn: () => {
      return axios.post(
        API_URL + `/Group/CreateGroup/${groupName}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${cleanToken(
              localStorage.getItem("token")
            )}`,
          },
        }
      );
    },
    onSuccess: async (data) => {
      navigate(`/edit-group/${data?.data?.newGroupId}`);
      toast.success("Group is successfully created");
    },
    onError: (error) => {
      console.log("error: ", error);
    },
  });

  const handleSaveGroup = () => {
    saveGroup.mutate();
  };

  return (
    <div className="container-fluid">
      <div className="row flex-nowrap">
        <Sidebar handleTourClick={() => setShowNewGroup(true)} />
        <div className="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-2"></div>
        <div className="col-10 col-sm-9 col-md-9 col-lg-9 col-xl-10 px-sm-5">
          <div className="dashboard">
            <div className="page-title">
              <div className="row w-100">
                <div className="col-7 col-lg-7 col-xl-8 d-flex align-items-center mb-4">
                  <BackButton />
                  <PageTitle title="New Group" time="29 May 2023 - 4:02 PM" />
                </div>

                <div className="col-5 col-lg-5 col-xl-4 filter">
                  <div className="add-user">
                    <RoundedButton
                      text="Save Group"
                      className="main-btn"
                      handleOnClick={handleSaveGroup}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="row">
                    <div className="col-6">
                      <NewGroupCard
                        groupName={groupName}
                        setGroupName={setGroupName}
                        cachedBranding={cachedBranding}
                      />
                      {/* <AddTagsCard tags={tags} /> */}
                    </div>
                    {/* <div className="col-6">
                      <AddUsersCard />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddGroup
        show={showNewGroup}
        handleClose={() => setShowNewGroup(false)}
        handleSubmit={() =>
          navigate("/chat-console", {
            state: { showModal: true },
          })
        }
      />
    </div>
  );
}

export default NewGroup;
