import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "../constants";
import { cleanToken } from "utils";

const fetchTiers = async () => {
  return await axios.get(API_URL + `/Subscription/GetTiers`, {
    headers: {
      Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
    },
  });
};

export const useFetchTiers = () => {
  return useQuery({
    queryKey: ["fetchTiers"],
    queryFn: () => fetchTiers(),
    enabled: true,
  });
};



const fetchSubscription = async () => {
  return await axios.get(API_URL + `/Subscription/GetSubscription`, {
    headers: {
      Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
    },
  });
};

export const useFetchSubscription = () => {
  return useQuery({
    queryKey: ["fetchSubscription"],
    queryFn: () => fetchSubscription(),
    enabled: true,
  });
};
