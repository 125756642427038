import React from "react";
import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import SelectableUser from "./SelectableUser";
import { useFetchCompanyUsers } from "hooks/users";
import GroupNavTab from "./GroupNavTab";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "constants";
import { cleanToken } from "utils";
import toast from "react-hot-toast";

function AddUserModal({
  show,
  handleClose,
  group,
  refetchGroup,
  usersNotMembers,
  refetchMembers,
}) {
  const queryClient = useQueryClient();
  const cachedBranding = queryClient.getQueryData(["branding"])?.data;
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [selected, setSelected] = useState("members");

  const handleSelectMember = (memberId) => {
    if (selectedMembers.includes(memberId)) {
      setSelectedMembers(selectedMembers.filter((id) => id !== memberId));
    } else {
      setSelectedMembers([...selectedMembers, memberId]);
    }
  };

  const addMembers = useMutation({
    mutationFn: (fields) => {
      return axios.post(API_URL + `/Group/AddUserToGroupBulk`, fields, {
        headers: {
          Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
        },
      });
    },
    onSuccess: async () => {
      setSelectedMembers([]);
      refetchGroup();
      refetchMembers();
     toast.success("The member has been added successfully")
    },
    onError: (error) => {
      console.error("Mutation error:", error);
      setSelectedMembers([]);
    },
  });

  const handleSubmit = () => {
    const data = {
      groupId: group?.groupId,
      isAdmin: "admin" === selected ? true : false,
      userIds: selectedMembers,
    };
    addMembers.mutate(data);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose} centered size="md">
      <div className="modal-content">
        <div className="modal-header">
          <h4
            className="modal-title"
            style={{ color: cachedBranding?.page_TextColor }}
          >
            Add User
          </h4>
          <div className="modal-option">
            <button type="button" className="btn" onClick={handleClose}>
              <img src="/images/close-modal.svg" alt="Close" />
            </button>
          </div>
        </div>
        <div className="modal-body editProfile justify-content-around flex-column">
          <div className="add-members">
            <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
              <GroupNavTab
                text="Members"
                active={"members" === selected ? true : false}
                handleOnClick={() => setSelected("members")}
              />
              <GroupNavTab
                text="Admin"
                active={"admin" === selected ? true : false}
                handleOnClick={() => setSelected("admin")}
              />
            </div>

            <div className="tab-content" id="nav-tabContent">
              <div
                className={`tab-pane fade ${
                  "members" === selected ? "show active" : ""
                }`}
              >
                <div className="members-list2">
                  {usersNotMembers?.data &&
                    usersNotMembers?.data?.map((member, index) => (
                      <SelectableUser
                        member={member}
                        key={index}
                        isSelected={selectedMembers.includes(member.id)}
                        onSelect={(memberId) => handleSelectMember(memberId)}
                      />
                    ))}
                </div>
              </div>
              <div
                className={`tab-pane fade ${
                  "admin" === selected ? "show active" : ""
                }`}
              >
                <div className="members-list2">
                  {usersNotMembers?.data &&
                    usersNotMembers?.data.map((member, index) => (
                      <SelectableUser
                        member={member}
                        key={index}
                        isSelected={selectedMembers.includes(member.id)}
                        onSelect={(memberId) => handleSelectMember(memberId)}
                      />
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn main-btn"
            onClick={handleSubmit}
            style={{ backgroundColor: "#0744e1" }}
          >
            Submit
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default AddUserModal;
