import React from "react";
import { useQueryClient } from "@tanstack/react-query";

function Pagination({ totalPages, page, setPage }) {
  const queryClient = useQueryClient();
  const cachedBranding = queryClient.getQueryData(["branding"])?.data;
  const getPageNumbers = () => {
    const pages = [];

    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);
    }

    return pages;
  };

  return (
    <nav className="paging">
      <ul className="pagination">
        <li className={`page-item ${page === 1 ? "disabled" : ""}`}>
          <a
            className="page-link cursor-pointer"
            onClick={() => setPage(page - 1)}
          >
            Previous
          </a>
        </li>
        {getPageNumbers().map((p) => (
          <li className={`page-item ${p === page ? "active" : ""}`} key={p}>
            <a
              className="page-link cursor-pointer"
              onClick={() => setPage(p)}
              style={{
                backgroundColor:
                  p === page ? cachedBranding?.page_ButtonColor : "",
                color: p === page ? "#fff" : cachedBranding?.page_TextColor,
              }}
            >
              {p}
            </a>
          </li>
        ))}
        <li className={`page-item ${page === totalPages ? "disabled" : ""}`}>
          <a
            className="page-link cursor-pointer"
            onClick={() => setPage(page + 1)}
            style={{ color: cachedBranding?.page_TextColor }}
          >
            Next
          </a>
        </li>
      </ul>
    </nav>
  );
}

export default Pagination;
