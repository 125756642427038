import React from "react";
import { Modal, Spinner } from "react-bootstrap";
import { useIsFetching } from "@tanstack/react-query";

function LoadingOverlay({ children }) {
  const isFetching = useIsFetching();

  return (
    <>
      {/* {isFetching > 0 && (
        <Modal
          show={true}
          backdrop="static"
          keyboard={false}
          centered
          className="transparent-modal"
          contentClassName="transparent-content"
        >
          <div className="d-flex align-items-center justify-content-center">
            <Spinner animation="border" role="status" variant="dark"></Spinner>
          </div>
        </Modal>
      )} */}
      {children}
    </>
  );
}

export default LoadingOverlay;
