import { NavLink } from "react-router-dom";
import SidebarButton from "./SidebarButton";
import UserItem from "./UserItem";
import { TrialButton } from "components/Custom";
import { useFetchRoutes } from "hooks/roles";
import { useFetchCompanyBranding } from "hooks/branding";
import { isEmpty } from "lodash";
import { useQueryClient } from "@tanstack/react-query";
import "./Sidebar.scss";
import { useEffect } from "react";

function Sidebar({ handleTourClick, primaryColor, secondaryColor, textColor }) {
  const { data } = useFetchRoutes();
  const { data: branding, refetch } = useFetchCompanyBranding();
  const queryClient = useQueryClient();
  const cachedBranding = queryClient.getQueryData(["branding"])?.data;

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div
      className="col-2 col-sm-4 col-md-2 col-lg-3 col-xl-2 px-sm-2 px-0 header"
      style={{
        background: primaryColor
          ? primaryColor
          : branding?.data?.sideBar_PrimaryColor,
        overflowY: "auto",
      }}
    >
      {/* <div className="sidebar-container d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100"> */}
      <div className="sidebar-container ">
        <NavLink
          to="/dashboard"
          // className="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none "
          className="logo-sidebar"
        >
          {!isEmpty(cachedBranding?.sideBarLogo) ? (
            <img
              src={`data:${cachedBranding?.sideBarLogoContentType};base64,${cachedBranding?.sideBarLogo}`}
              height={70}
              // className="mt-3 ms-5 mb-3"
              className="logo-sidebar"
            />
          ) : (
            <img src="/images/ai-logo.svg" className="mt-3 ms-5 mb-3" />
          )}
        </NavLink>
        <ul
          className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start"
          id="menu"
        >
          {data?.data.map(
            (route, index) =>
              !route.isSetting && (
                <SidebarButton
                  title={route?.routeTitle}
                  to={route?.routeTo}
                  icon={route?.routeIcon}
                  key={index}
                  data={data?.data}
                  primaryColor={
                    primaryColor
                      ? primaryColor
                      : branding?.data?.sideBar_PrimaryColor
                  }
                  secondaryColor={
                    secondaryColor
                      ? secondaryColor
                      : branding?.data?.sideBar_SecondaryColor
                  }
                  textColor={
                    textColor ? textColor : branding?.data?.sideBar_TextColor
                  }
                />
              )
          )}
        </ul>
        {/* <TrialButton /> */}
        <div className="my-3 cursor-pointer" onClick={handleTourClick}>
          <img src="/images/idea.svg" />
          <span
            style={{
              // color: cachedBranding?.sideBar_TextColor,
              color:"white"
            }}
          >
            {" "}
            Quick Tour
          </span>
        </div>
        <UserItem 
        secondaryColor={"white"}
        // secondaryColor={cachedBranding?.sideBar_TextColor}
         />
      </div>
    </div>
  );
}

export default Sidebar;
