import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "constants";
import React from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { cleanToken } from "utils";

function DeleteMaterialModal({ show, handleClose, file, refetch }) {
  const deleteMaterial = useMutation({
    mutationFn: () => {
      return axios.delete(
        API_URL + `/Material/DeleteMaterial/${file?.materialId}`,
        {
          headers: {
            Authorization: `Bearer ${cleanToken(
              localStorage.getItem("token")
            )}`,
          },
        }
      );
    },
    onSuccess: async (data) => {
      handleClose();
      refetch();
      toast.success("Deleted successfully");
    },
    onError: (error) => {
      console.log("error: ", error);
    },
  });

  const handleSubmit = () => {
    deleteMaterial.mutate();
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Delete File</h4>
          <div className="modal-option">
            <button type="button" className="btn" onClick={handleClose}>
              <img src="/images/close-modal.svg" />
            </button>
          </div>
        </div>
        <div className="modal-body editProfile">
          Are you sure you want to delete this file?
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn normal-btn"
            onClick={handleClose}
          >
            No
          </button>
          <button
            type="button"
            className="btn delete-btn"
            onClick={handleSubmit}
          >
            Yes
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default DeleteMaterialModal;
