import React from "react";
import TableRow from "./TableRow";

function TableBody({
  selectedUsers,
  setSelectedUsers,
  setShowDeleteModal,
  setShowEditModal,
  setSelectedUser,
  users,
  refetchUsers,
  handleCheckUser,
  cachedBranding,
}) {
  return (
    <tbody>
      {users &&
        users.map((user, index) => (
          <TableRow
            user={user}
            setSelectedUsers={setSelectedUsers}
            selectedUsers={selectedUsers}
            setShowDeleteModal={setShowDeleteModal}
            setShowEditModal={setShowEditModal}
            setSelectedUser={setSelectedUser}
            refetchUsers={refetchUsers}
            key={index}
            handleCheckUser={handleCheckUser}
            cachedBranding={cachedBranding}
          />
        ))}
    </tbody>
  );
}

export default TableBody;
