import React, { Children } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { isEmpty } from "lodash";

function RoundedButton({
  text,
  image,
  handleOnClick,
  style,
  type = "main-btn",
  className = "",
  color,
  fontColor = "#FFFFFF",
  disabled = false,
}) {
  const queryClient = useQueryClient();
  const cachedBranding = queryClient.getQueryData(["branding"])?.data;

  return (
    <button
      className={`btn ${type} ${className}`}
      style={
        (style,
        {
          backgroundColor: !isEmpty(color)
            ? color
            : !isEmpty(cachedBranding?.page_ButtonColor) && "main-btn" === type
            ? cachedBranding?.page_ButtonColor
            : "main-btn" === type
            ? "#0844e1"
            : "",
          color: fontColor,
        })
      }
      onClick={() => handleOnClick()}
      disabled={disabled}
    >
      {image && <img src={image} className="me-2" />}
      {text}
    </button>
  );
}

export default RoundedButton;
