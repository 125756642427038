import React from "react";
import { Modal } from "react-bootstrap";

function ChartModal({
  show,
  handleClose,
  chart,
  child,
  title,
  cachedBranding,
}) {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="topics-categories"
      centered
      size="lg"
    >
      <div className="modal-content">
        <div className="modal-header">
          <h4
            className="modal-title"
            style={{ color: cachedBranding?.page_TextColor }}
          >
            {title}
          </h4>
          <div className="modal-option">
            <button type="button" className="btn" onClick={() => handleClose()}>
              <img src="/images/close-modal.svg" />
            </button>
          </div>
        </div>
        <div className="modal-body">{child}</div>
      </div>
    </Modal>
  );
}

export default ChartModal;
