import React from "react";
import UsageDetail from "./UsageDetail";

function UserDetails({ active, user, type, cachedBranding }) {
  return (
    <div className={`tab-pane fade ${active ? "show active" : ""}`}>
      <div className="details">
        <UsageDetail
          item="Email"
          value={user?.displayEmail}
          cachedBranding={cachedBranding}
        />
        <UsageDetail
          item="Mobile"
          value={user?.mobile}
          cachedBranding={cachedBranding}
        />
        <UsageDetail
          item="Phone"
          value={user?.phone}
          cachedBranding={cachedBranding}
        />
        <UsageDetail
          item="Department"
          value={user?.department ? user?.department : " "}
          cachedBranding={cachedBranding}
        />
        <UsageDetail
          item="Role"
          value={user?.roleName}
          cachedBranding={cachedBranding}
        />
      </div>
    </div>
  );
}

export default UserDetails;
