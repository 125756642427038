import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { RoundedButton } from "components/Custom";
import UserItem from "components/Users/UserItem";
import { API_URL } from "constants";
import { useFetchGroupById } from "hooks/groups";
import { useFetchFolders } from "hooks/materials";
import { isEmpty } from "lodash";
import React, { useCallback, useState } from "react";
import { Modal } from "react-bootstrap";
import { cleanToken } from "utils";
import { generateUUID } from "utils";
import SetAdminUserItem from "./SetAdminUserItem";

function SetAdminModal({
  show,
  handleClose,
  cachedBranding,
  groupId,
  chatId,
  refetchGroups,
  refetchMaterialChatsGroup,
  refetchInstituteChatsGroup,
  setShowActions,
}) {
  const [group, setGroup] = useState("");
  const { data, refetch } = useFetchGroupById(groupId);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleUserClick = useCallback((userId) => {
    setSelectedUser(userId); // Set the selected user's ID in the state
  }, []);

  const save = useMutation({
    mutationFn: () => {
      return axios.post(
        API_URL + `/Chat/SaveTemporaryAdmin/${chatId}/${selectedUser}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${cleanToken(
              localStorage.getItem("token")
            )}`,
          },
        }
      );
    },
    onSuccess: async (data) => {
      console.log("data: ", data);
      setShowActions(false);
      refetchGroups();
      refetchMaterialChatsGroup();
      refetchInstituteChatsGroup();
      handleClose();
      refetch();
    },
    onError: (error) => {
      console.log("error: ", error);
    },
  });

  const handleSubmit = () => {
    save.mutate();
  };

  return (
    <Modal show={show} onHide={handleClose} centered size="md">
      <div className="modal-content">
        <div className="modal-header">
          <h4
            className="modal-title"
            style={{ color: cachedBranding?.page_TextColor }}
          >
            Set Chat Owner
          </h4>
          <div className="modal-option">
            <button type="button" className="btn" onClick={handleClose}>
              <img src="/images/close-modal.svg" />
            </button>
          </div>
        </div>
        <div className="modal-body editProfile justify-content-around flex-column">
          <div className="row mb-3">
            <div className="w-100">
              <div className="search-bar position-relative">
                <img src="/images/search.svg" className="search-ic" />
                <input
                  className="search-inp"
                  type="text"
                  placeholder="Search User"
                  // onChange={(e) => setSearchText(e.target.value)}
                  // value={searchText}
                />
              </div>
            </div>
          </div>
          <div className="mt-2">
            {data?.data[0]?.groupMemberUserResponse &&
              data?.data[0].groupMemberUserResponse.map((member, index) => (
                <SetAdminUserItem
                  user={member}
                  cachedBranding={cachedBranding}
                  onClick={() => handleUserClick(member.userId)} // Handle user click
                  selected={selectedUser}
                />
              ))}
              {data?.data[0]?.groupAdminUserResponse &&
              data?.data[0].groupAdminUserResponse.map((member, index) => (
                <SetAdminUserItem
                  user={member}
                  cachedBranding={cachedBranding}
                  onClick={() => handleUserClick(member.userId)} // Handle user click
                  selected={selectedUser}
                />
              ))}
          </div>
          <div>
            <RoundedButton
              text="Done"
              handleOnClick={() => handleSubmit()}
              className="btn shadow-sm main-btn p-3 rounded-pill ms-3 float-end w-100"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default SetAdminModal;
