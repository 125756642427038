import { useQuery, useMutation } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "../constants";
import { cleanToken } from "utils";

const fetchGroups = async () => {
  return await axios.get(API_URL + `/Group/GetGroups`, {
    headers: {
      Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
    },
  });
};

const fetchGroupById = async (groupId) => {
  return await axios.get(API_URL + `/Group/GetGroupsById/${groupId}`, {
    headers: {
      Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
    },
  });
};

const fetchGroupImage = async (groupId) => {
  return await axios.get(API_URL + `/Group/GetGroupImage/${groupId}`, {
    headers: {
      Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
    },
  });
};

//fetch groups by pageSize and pageNumber
const fetchCompanyGroups = async (data) => {
  return await axios.get(
    API_URL + `/Group/GetGroups/${data?.pageSize}/${data?.pageNumber}`,
    {
      headers: {
        Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
      },
    }
  );
};

const fetchUserNotInGroup = async (groupId) => {
  return await axios.get(API_URL + `/Group/GetUsersNotInGroup/${groupId}`, {
    headers: {
      Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
    },
  });
};

const exportGroups = async () => {
  return await axios.get(API_URL + "/Group/ExportGroups", {
    headers: {
      Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
    },
    responseType: "blob",
  });
};

const exportUsersInGroup = async (groupId) => {
  return await axios.get(API_URL + `/Group/ExportUsersInGroup/${groupId}`, {
    headers: {
      Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
    },
    responseType: "blob",
  });
};
//hooks
export const useFetchGroups = () => {
  return useQuery({
    queryKey: ["groups"],
    queryFn: () => fetchGroups(),
    enabled: true,
  });
};

export const useFetchGroupById = (groupId) => {
  return useQuery({
    queryKey: ["group"],
    queryFn: () => fetchGroupById(groupId),
    enabled: true,
  });
};

export const useFetchGroupImage = (groupId) => {
  return useQuery({
    queryKey: ["groupImage", groupId],
    queryFn: () => fetchGroupImage(groupId),
    enabled: false,
  });
};

//hook for fetching groups by pageSize and pageNumber
export const useFetchCompanyGroups = (data) => {
  return useQuery({
    queryKey: ["groupsbypage"],
    queryFn: () => fetchCompanyGroups(data),
    enabled: false,
  });
};

export const useFetchUsersNotInGroup = (groupId) => {
  return useQuery({
    queryKey: ["usersNotMembers", groupId],
    queryFn: () => fetchUserNotInGroup(groupId),
    enabled: false,
  });
};

export const useExportGroup = () => {
  return useMutation(() => exportGroups());
};

export const useExportUsersInGroup = (groupId) => {
  return useMutation(() => exportUsersInGroup(groupId));
};
