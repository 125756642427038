import React from "react";

function CompanyDetails({ setShowEditCompanyDetailsModal, contactPerson }) {
  return (
    <div className="normal-admin-card">
      <div className="d-flex justify-content-between">
        <h5>Company Details</h5>
        <button
          className="btn"
          onClick={() => setShowEditCompanyDetailsModal(true)}
        >
          <img src="/images/edit.svg" />
        </button>
      </div>
      <div className="add-group mt-3">
        <div className="details d-flex w-100 flex-wrap">
          <div className="details-name w-33">
            <span>Name</span>
            <p>{contactPerson?.name}</p>
          </div>
          <div className="details-name w-33">
            <span>Email</span>
            <p>{contactPerson?.email}</p>
          </div>
          <div className="details-name w-33">
            <span>Mobile</span>
            <p>{contactPerson?.mobile}</p>
          </div>
          <div className="details-name w-33">
            <span>Company Phone</span>
            <p>{contactPerson?.companyPhone}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyDetails;
