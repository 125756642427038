import React, { useEffect, useState } from "react";
import GroupModal from "./GroupModal";
import TagsDisplay from "./TagsDisplay";
import { useFetchGroupImage } from "hooks/groups";
import { isEmpty } from "lodash";
import ImagesDisplay from "./ImagesDisplay";
import SpinnerCircle from "components/Custom/SpinnerCircle";

function GroupCard({ group, refetchGroups, selected, cachedBranding }) {
  const [show, setShow] = useState(false);
  const {
    data: image,
    refetch: refetchImage,
    isFetching: isFetchingImage,
  } = useFetchGroupImage(group?.groupId);

  useEffect(() => {
    if (!isEmpty(group?.groupId)) {
      refetchImage();
    }
  }, [group]);

  const renderInitials = (name) => {
    const words = name.split(" ");
    const firstLetters = [];
    for (const word of words) {
      if (word.length > 0) {
        firstLetters.push(word[0]);
      }
    }
    const result = firstLetters.join("");
    return <div>{result}</div>;
  };

  return (
    <>
      <button type="button" className="group-btn" onClick={() => setShow(true)}>
        <div className="group-card">
          <div className="group-name">
            {image?.data?.imageBytes ? (
              isFetchingImage ? (
                <SpinnerCircle />
              ) : (
                <img
                  src={`data:image/jpeg;base64,${image?.data?.imageBytes}`}
                  className="group-imageic"
                />
              )
            ) : (
              <div className="group-initials">
                {renderInitials(group?.groupName)}
              </div>
            )}
            <div className="col-6">
              <h5 style={{ color: cachedBranding?.page_TextColor }}>
                {group?.groupName}
              </h5>
            </div>
          </div>
          <div className="group-details">
            <div className="group-count">
              <span style={{ color: cachedBranding?.page_TextColor }}>
                ({group?.adminCount}) Admin{" "}
              </span>
              <span style={{ color: cachedBranding?.page_TextColor }}>
                ({group?.memberCount}) Members
              </span>
            </div>
            {/* <div className="users-group">
              {group?.groupUserResponse &&
                group?.groupUserResponse.map((user, index) => (
                  <div key={index}>{renderInitials(user?.fullName)}</div>
                ))}
            </div> */}
            <div>
              <ImagesDisplay groupMembers={group?.groupMemberUserResponse} />
            </div>
            <div className="group-topics">
              <TagsDisplay items={group?.groupTagResponse} />
            </div>
          </div>
        </div>
      </button>
      <GroupModal
        show={show}
        handleClose={() => setShow(false)}
        info={group}
        refetchGroups={refetchGroups}
        refetchUsers={() => {}}
        groupImage={image}
        renderInitials={renderInitials}
      />
    </>
  );
}

export default GroupCard;
