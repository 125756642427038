import { TabButton } from "components/Custom";
import React, { useState } from "react";
import MaxResponse from "./MaxResponse";
import TemperatureTopP from "./TemperatureTopP";
import ComingSoon from "components/Custom/ComingSoon";

function InternetSettings({
  cachedBranding,
  showInternetDataInChatConsole,
  setShowInternetDataInChatConsole,
}) {
  return (
    <div className="py-4">
      <div className="normal-card">
        <div className="px-3">
          <div className="switch-ai-g">
            <p
              className="no-margin"
              style={{ color: cachedBranding?.page_TextColor }}
            >
              Show Internet Data in chat console
            </p>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                checked={showInternetDataInChatConsole}
                onChange={() =>
                  setShowInternetDataInChatConsole(
                    !showInternetDataInChatConsole
                  )
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InternetSettings;
