import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import TokensCard from "./TokensCard";
import { RoundedButton } from "components/Custom";

function AddTokensModal({
  show,
  handleClose,
  handleAddMore,
  adminModal = false,
  setSelectedPlan,
  selectedPlan,
  cachedBranding,
}) {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <div className="modal-content">
        <div className="modal-header">
          <div className="token-title">
            <h5
              className="modal-title"
              id="exampleModalCenterTitle"
              style={{ color: cachedBranding?.page_TextColor }}
            >
              Additional Tokens
            </h5>
            <span>Token = words of 4 characters</span>
          </div>
          <div className="modal-option">
            <button type="button" className="btn" onClick={() => handleClose()}>
              <img src="/images/close-modal.svg" />
            </button>
          </div>
        </div>
        <div className="modal-body">
          <div className="tokens">
            <TokensCard
              tokens="1,000"
              price="$100"
              image="/images/1k.svg"
              handleOnClick={() => setSelectedPlan(1)}
              selected={1 === selectedPlan ? true : false}
            />
            <TokensCard
              tokens="5,000"
              price="$100"
              image="/images/5k.svg"
              handleOnClick={() => setSelectedPlan(2)}
              selected={2 === selectedPlan ? true : false}
            />
            <TokensCard
              tokens="10,000"
              price="$100"
              image="/images/10k.svg"
              handleOnClick={() => setSelectedPlan(3)}
              selected={3 === selectedPlan ? true : false}
            />
            <TokensCard
              tokens="50,000"
              price="$100"
              image="/images/50k.svg"
              handleOnClick={() => setSelectedPlan(4)}
              selected={4 === selectedPlan ? true : false}
            />
            <TokensCard
              tokens="80,000"
              price="$100"
              image="/images/80k.svg"
              handleOnClick={() => setSelectedPlan(5)}
              selected={5 === selectedPlan ? true : false}
            />
            <TokensCard
              tokens="100,000"
              price="$100"
              image="/images/100k.svg"
              handleOnClick={() => setSelectedPlan(6)}
              selected={6 === selectedPlan ? true : false}
            />
            <TokensCard
              image="/images/more-tokens.svg"
              addCard={true}
              handleOnClick={handleAddMore}
            />
          </div>
        </div>
        <div className="modal-footer d-flex">
          <RoundedButton
            text="Cancel"
            style={{ width: "25%", height: "45px" }}
            type="btn-secondary"
            handleOnClick={handleClose}
          />
          {!adminModal ? (
            <RoundedButton
              text="Next"
              style={{ width: "25%" }}
              handleOnClick={() => {}}
            />
          ) : (
            <RoundedButton
              text="Confirm"
              style={{ width: "25%" }}
              handleOnClick={() => {}}
            />
          )}
        </div>
      </div>
    </Modal>
  );
}

export default AddTokensModal;
