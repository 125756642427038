import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "../constants";
import { cleanToken } from "utils";

const fetchCompanyBots = async () => {
  return await axios.get(API_URL + `/api/BotScript/GetCompanyBots`, {
    headers: {
      Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
    },
  });
};

export const useFetchCompanyBots = () => {
  return useQuery({
    queryKey: ["companyBots"],
    queryFn: () => fetchCompanyBots(),
    enabled: true,
  });
};

///

const fetchBotScript = async (botId) => {
  return await axios.get(API_URL + `/api/ChatBot/GetBotScript/${botId}`, {
    headers: {
      Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
    },
  });
};

export const useFetchBotScript = (botId) => {
  return useQuery({
    queryKey: ["fetchBotScript"],
    queryFn: () => fetchBotScript(botId),
    enabled: true,
  });
};
