import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "../constants";
import { cleanToken } from "utils";

const validateToken = async () => {
  return await axios.get(API_URL + `/auth/validate`, {
    headers: {
      Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
    },
  });
};

export const useValidateToken = () => {
  return useQuery({
    queryKey: ["validation"],
    queryFn: () => validateToken(),
    enabled: false,
    refetchOnWindowFocus: false,
  });
};
