import React from "react";

function Details({ user, image, cachedBranding }) {
  return (
    <>
      <div className="d-flex w-100 align-items-center mb-3">
        {image?.imageBytes ? (
          <img
            src={`data:image/jpeg;base64,${image?.imageBytes}`}
            className="mx-2 user-image"
          />
        ) : (
          <img src="/images/user-empty.svg" className="w-25 mx-2" />
        )}
        <div className="mx-2">
          <h6 style={{ color: cachedBranding?.page_TextColor }}>
            {user?.firstName + " " + user?.lastName}
          </h6>
          {/* <span>UID: {user?.uid}</span> */}
        </div>
      </div>
      <div className="details d-flex w-100 flex-wrap">
        {/* <div className="details-name w-50">
          <span>Display Name</span>
          <p>{user?.displayName}</p>
        </div> */}

        <div className="details-name w-50">
          <span>Username</span>
          <p style={{ color: cachedBranding?.page_TextColor }}>
            {user?.username}
          </p>
        </div>

        <div className="details-name w-50">
          <span>Email</span>
          <p style={{ color: cachedBranding?.page_TextColor }}>
            {user?.displayEmail}
          </p>
        </div>

        <div className="details-name w-50">
          <span>Mobile</span>
          <p style={{ color: cachedBranding?.page_TextColor }}>
            {user?.mobile}
          </p>
        </div>

        <div className="details-name w-50">
          <span>Phone</span>
          <p style={{ color: cachedBranding?.page_TextColor }}>{user?.phone}</p>
        </div>

        <div className="details-name w-50">
          <span>Department</span>
          <p style={{ color: cachedBranding?.page_TextColor }}>
            {user?.department}
          </p>
        </div>

        <div className="details-name w-50">
          <span>Role</span>
          <p style={{ color: cachedBranding?.page_TextColor }}>
            {user?.roleName}
          </p>
        </div>

        {/* <div className="details-name d-flex justify-content-between align-items-center w-50">
          <div className="password">
            <span>Password</span>
            <p>{user?.password}</p>
          </div>
          <img src="/images/view.svg" />
        </div> */}
      </div>
      {/* <button
        className="btn rounded-pill w-100 bg-danger-subtle text-danger mt-5"
        data-bs-toggle="modal"
        data-bs-target="#delete-account"
      >
        {" "}
        <img className="delete" src="/images/delete-user.svg" />
        <span className="mx-2 delete">Delete Account</span>
      </button> */}
    </>
  );
}

export default Details;
