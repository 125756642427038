function RatingProgresBar({ widthPercentage }) {
    return (
        <div className="displayRowProgress">
         <div className="progressBar">
            <div style={{width: widthPercentage, backgroundColor: "#0744e1"}} className="innerProgress" ></div>
        </div> 
        <div className="percentage">{widthPercentage}%</div>
        </div>
    );
}

export default RatingProgresBar;