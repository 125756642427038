import { Sidebar } from "components";
import { BackButton, PageTitle, RoundedButton } from "components/Custom";
import React from "react";
import { useQueryClient } from "@tanstack/react-query";

function Model() {
  const queryClient = useQueryClient();
  const cachedBranding = queryClient.getQueryData(["branding"])?.data;

  return (
    <div className="container-fluid">
      <div className="row flex-nowrap">
        <Sidebar handleTourClick={() => {}} />
        <div className="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-2"></div>
        <div
          className="col-10 col-sm-9 col-md-9 col-lg-9 col-xl-10"
          style={{ padding: "0 5%" }}
        >
          <div className="dashboard">
            <div className="page-title">
              <div className="row w-100">
                <div className="col-9 d-flex align-items-center">
                  <BackButton />
                  <PageTitle
                    title="Sample Model"
                    time="29 May 2023 - 4:02 PM"
                  />
                </div>
                <div className="col-3 d-flex align-items-center">
                  <RoundedButton
                    text="Send outcome to all student"
                    handleOnClick={() => {}}
                    className=" ms-3 float-end w-100"
                  />
                </div>
              </div>
            </div>
            <div className="normal-card sub-token p-0">
              <div className="row">
                <div className="col-3 border-end">
                  <div className="p-3">
                    <h2
                      className="model-title"
                      style={{ color: cachedBranding?.page_TextColor }}
                    >
                      Students (25)
                    </h2>
                    <div className="search-bar mt-3">
                      <img src="/images/search.svg" className="search-ic2" />
                      <input
                        className="search-inp inp-3"
                        type="text"
                        placeholder="Search"
                      />
                    </div>
                  </div>
                  <div className="student-list mt-3">
                    <div className="px-3">
                      <span
                        style={{
                          color: cachedBranding?.page_TextColor,
                          fontSize: "14px",
                          fontWeight: 400,
                        }}
                      >
                        Nicole Lee
                      </span>
                    </div>
                    <hr />
                    <div className="px-3">
                      <span
                        style={{
                          color: cachedBranding?.page_TextColor,
                          fontSize: "14px",
                          fontWeight: 400,
                        }}
                      >
                        Nicole Lee
                      </span>
                    </div>
                    <hr />
                    <div className="px-3">
                      <span
                        style={{
                          color: cachedBranding?.page_TextColor,
                          fontSize: "14px",
                          fontWeight: 400,
                        }}
                      >
                        Nicole Lee
                      </span>
                    </div>
                    <hr />
                    <div className="px-3">
                      <span
                        style={{
                          color: cachedBranding?.page_TextColor,
                          fontSize: "14px",
                          fontWeight: 400,
                        }}
                      >
                        Nicole Lee
                      </span>
                    </div>
                    <hr />
                    <div className="px-3">
                      <span
                        style={{
                          color: cachedBranding?.page_TextColor,
                          fontSize: "14px",
                          fontWeight: 400,
                        }}
                      >
                        Nicole Lee
                      </span>
                    </div>
                    <hr />
                    <div className="px-3">
                      <span
                        style={{
                          color: cachedBranding?.page_TextColor,
                          fontSize: "14px",
                          fontWeight: 400,
                        }}
                      >
                        Nicole Lee
                      </span>
                    </div>
                    <hr />
                    <div className="px-3">
                      <span
                        style={{
                          color: cachedBranding?.page_TextColor,
                          fontSize: "14px",
                          fontWeight: 400,
                        }}
                      >
                        Nicole Lee
                      </span>
                    </div>
                    <hr />
                  </div>
                </div>
                <div className="col-9 py-3 px-4">
                  <p
                    style={{ color: cachedBranding?.page_TextColor }}
                    className="model-student-label mb-0"
                  >
                    Student
                  </p>
                  <p
                    style={{ color: cachedBranding?.page_TextColor }}
                    className="model-student-name"
                  >
                    Nicole Lee
                  </p>
                  <div className="row mt-4">
                    <div className="col-3">
                      <p
                        style={{ color: cachedBranding?.page_TextColor }}
                        className="model-student-label mb-0"
                      >
                        Result
                      </p>
                      <p
                        style={{ color: cachedBranding?.page_TextColor }}
                        className="model-student-name"
                      >
                        85
                      </p>
                    </div>
                    <div className="col-3">
                      <p
                        style={{ color: cachedBranding?.page_TextColor }}
                        className="model-student-label mb-0"
                      >
                        Result
                      </p>
                      <p
                        style={{ color: cachedBranding?.page_TextColor }}
                        className="model-student-name"
                      >
                        85
                      </p>
                    </div>
                  </div>
                  <div className=" mt-4">
                    <p
                      style={{ color: cachedBranding?.page_TextColor }}
                      className="model-student-label mb-0"
                    >
                      Discovering Mistakes
                    </p>
                    <p
                      style={{ color: cachedBranding?.page_TextColor }}
                      className="model-student-name mb-0"
                    >
                      Lorem ipsum dolor this is a dummy content for design
                      purpose only.
                    </p>
                    <p
                      style={{ color: cachedBranding?.page_TextColor }}
                      className="model-student-name mb-0"
                    >
                      Lorem ipsum dolor this is a dummy content for design
                      purpose only.
                    </p>
                    <p
                      style={{ color: cachedBranding?.page_TextColor }}
                      className="model-student-name mb-0"
                    >
                      Lorem ipsum dolor this is a dummy content for design
                      purpose only.
                    </p>
                  </div>
                  <div className=" mt-4">
                    <p
                      style={{ color: cachedBranding?.page_TextColor }}
                      className="model-student-label mb-0"
                    >
                      Recommendation
                    </p>
                    <textarea
                      className="w-100 mt-2 notification-input p-2"
                      rows={3}
                    ></textarea>
                  </div>
                  <div className=" mt-4">
                    <p
                      style={{ color: cachedBranding?.page_TextColor }}
                      className="model-student-label mb-0"
                    >
                      Suggested Resources
                    </p>
                    <textarea
                      className="w-100 mt-2 notification-input p-2"
                      rows={3}
                    ></textarea>
                  </div>
                  <div className=" mt-4">
                    <RoundedButton
                      text="Send outcome"
                      handleOnClick={() => {}}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Model;
