import React from "react";
import { useQueryClient } from "@tanstack/react-query";
import { isEmpty } from "lodash";

function TabButton({ active, text, handleOnClick }) {
  const queryClient = useQueryClient();
  const cachedBranding = queryClient.getQueryData(["branding"])?.data;

  return (
    <a
      className={`nav-link 
      ${active ? "active-bt" : "inactive-bt"} 
      cursor-pointer`}
      onClick={handleOnClick}
      style={{
        backgroundColor:
          active && !isEmpty(cachedBranding)
            ? cachedBranding?.page_ButtonColor
            : active && isEmpty(cachedBranding)
            ? "#0744e1"
            : "#fff",
      }}
    >
      {text}
    </a>
  );
}

export default TabButton;
