import React from "react";
import { Modal } from "react-bootstrap";

function YourPlan({ show, handleClose, handleSubmit, cachedBranding }) {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="token-plan modal-walkthrough"
      size="lg"
    >
      <div className="modal-content p-0">
        <div className="speech left p-4 m-0">
          <div className="row">
            <div className="col-12 d-flex justify-content-end mb-3">
              <button type="button" className="btn" onClick={handleClose}>
                <img src="/images/close-modal.svg" />
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-6 pe-4">
              <img src="/images/your-plan.svg" />
            </div>
            <div className="col-6 p-0 pe-5">
              <h5 className="fw-semibold">Token Management</h5>
              <ol style={{ fontSize: "14px" }}>
                <li>
                  Manage token usage per month by accessing the Token
                  Restriction module.
                </li>
                <li>
                  Set token restrictions based on available amounts or unlimited
                  usage.
                </li>
                <li>
                  Set token user restrictions, lock, or activate a user usage.
                </li>
                <li>
                  Set token group restrictions, lock, or activate a group usage.
                </li>
              </ol>
              <div className="d-flex justify-content-end">
                <a
                  className="btn main-btn mt-4 px-4 cursor-pointer"
                  onClick={handleSubmit}
                  style={{ backgroundColor: cachedBranding?.page_ButtonColor }}
                >
                  Next
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default YourPlan;
