import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { RoundedButton } from "components/Custom";
import { API_URL } from "constants";
import { useFetchGroups } from "hooks/groups";
import { useFetchFolders } from "hooks/materials";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { cleanToken } from "utils";
import { generateUUID } from "utils";

function NewGroupChatModal({
  show,
  handleClose,
  handleSelect,
  setNewGroup,
  cachedBranding,
}) {
  const { data: groups } = useFetchGroups();
  const [group, setGroup] = useState("");

  const handleSubmit = () => {
    const id = generateUUID();
    handleSelect(id, "new", id, group);
    setNewGroup(true);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose} centered size="md">
      <div className="modal-content">
        <div className="modal-header">
          <h4
            className="modal-title"
            style={{ color: cachedBranding?.page_TextColor }}
          >
            New Chat
          </h4>
          <div className="modal-option">
            <button type="button" className="btn" onClick={handleClose}>
              <img src="/images/close-modal.svg" />
            </button>
          </div>
        </div>
        <div className="modal-body editProfile justify-content-around flex-column">
          <div className="row mb-3">
            <div className="w-100">
              <label
                className="script-label mb-2"
                style={{ color: cachedBranding?.page_TextColor }}
              >
                Group
              </label>
              <div className="color-pick">
                <select
                  className="form-select border border-0"
                  style={{ fontSize: "12px" }}
                  value={group}
                  onChange={(e) => setGroup(e.target.value)}
                >
                  <option value="" disabled>
                    Please select a group
                  </option>
                  {groups?.data.map((group, index) => (
                    <option value={group?.groupId} key={index}>
                      {group?.groupName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div>
            <RoundedButton
              text="Done"
              handleOnClick={() => handleSubmit()}
              className="btn shadow-sm main-btn p-3 rounded-pill ms-3 float-end w-100"
              disabled={isEmpty(group) ? true : false}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default NewGroupChatModal;
