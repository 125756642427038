import React from "react";
import FavoriteItem from "./FavoriteItem";

function Favorites({
  handleSelect,
  favoriteChats,
  refetchFavorites,
  refetchIndividualChats,
  cachedBranding,
  setSelectedChat,
  refetchInstituteChatsGroup,
  refetchInstituteChats,
}) {
  return (
    <div className="fave">
      <div className="d-flex justify-content-between">
        <h6
          style={{
            color: cachedBranding?.page_TextColor,
          }}
        >
          Favorites
        </h6>
      </div>
      <div className="favorite-list">
        {favoriteChats?.data &&
          favoriteChats?.data.map((chat, index) => (
            <FavoriteItem
              chat={chat}
              key={index}
              handleSelect={handleSelect}
              refetchFavorites={refetchFavorites}
              refetchIndividualChats={refetchIndividualChats}
              cachedBranding={cachedBranding}
              setSelectedChat={setSelectedChat}
              refetchInstituteChatsGroup={refetchInstituteChatsGroup}
              refetchInstituteChats={refetchInstituteChats}
            />
          ))}
      </div>
    </div>
  );
}

export default Favorites;
