import React from "react";
import { Modal } from "react-bootstrap";

function AddUser({ show, handleClose, handleSubmit, cachedBranding }) {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="wt-user modal-walkthrough"
      size="lg"
    >
      <div className="modal-content p-0">
        <div className="speech topuser p-5 m-0">
          <div className="row">
            <div className="col-12 d-flex justify-content-end mb-3 me-4">
              <button type="button" className="btn" onClick={handleClose}>
                <img src="/images/close-modal.svg" />
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-6 pe-4">
              <img src="/images/wt-user.svg" />
            </div>
            <div className="col-6 p-0 px-5">
              <h5 className="fw-semibold">User Management</h5>
              <ol style={{ fontSize: "14px" }}>
                <li>Proceed to the Users module. </li>
                <li>
                  Add users individually or perform bulk addition using CSV
                  upload.
                </li>
                <li>
                  Provide user details, including name, email, mobile,
                  department, and role.
                </li>
                <li>
                  Assign roles such as User admin, Data Analyst, and more.
                </li>
                <li>
                  Edit or delete a user individually or multi-select users
                  through checkboxes.
                </li>
              </ol>
              <div
                className="d-flex justify-content-end"
                onClick={handleSubmit}
              >
                <a
                  className="btn main-btn mt-4 px-4 cursor-pointer"
                  style={{ backgroundColor: cachedBranding?.page_ButtonColor }}
                >
                  Next
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AddUser;
