import React, { useEffect, useState, useRef } from "react";
import DeleteChatModal from "./DeleteChatModal";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "constants";
import { cleanToken } from "utils";
import BotMessage from "./BotMessage";
import moment from "moment";
import FileHeader from "./FileHeader";
import { isEmpty } from "lodash";

function Chat({
  chat,
  refetchChat,
  selectedChat,
  tempText,
  refetchFavorites,
  refetchIndividualChats,
  refetchInstituteChats,
  refetchInstituteChatsGroup,
  refetchMaterialChatsGroup,
  setSelectedChat,
  handleSelect,
  cachedBranding,
  setDocumentId,
}) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [title, setTitle] = useState("");

  useEffect(() => {
    setTitle(chat?.chatTitle);
  }, [chat]);

  const editTitle = useMutation({
    mutationFn: () => {
      return axios.post(
        API_URL + `/Chat/EditChatTitleById/${selectedChat}/${title}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${cleanToken(
              localStorage.getItem("token")
            )}`,
          },
        }
      );
    },
    onSuccess: async () => {
      refetchChat();
      refetchFavorites();
      refetchIndividualChats();
      refetchInstituteChats();
      refetchInstituteChatsGroup();
      refetchMaterialChatsGroup();
      setEdit(!edit);
    },
    onError: (error) => {
      console.log("error: ", error);
    },
  });

  const handleEditTitle = () => {
    if (edit) {
      editTitle.mutate();
    } else {
      setEdit(!edit);
    }
  };
  const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView());
    return <div ref={elementRef} />;
  };
  return (
    <>
      <div
        className="chat-header"
        style={{ backgroundColor: cachedBranding?.topBannerColor }}
      >
        {!edit ? (
          <h6
            className="fw-medium m-0"
            style={{ color: cachedBranding?.page_TextColor }}
          >
            {chat?.chatTitle}
          </h6>
        ) : (
          <input
            className="fw-medium m-0 w-100"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        )}
        <div className="chat-actions d-flex">
          <button className="btn" onClick={() => handleEditTitle()}>
            {!edit ? (
              <img src="/images/edit-user.svg" />
            ) : (
              <img src="/images/save.svg" />
            )}
          </button>
          <button className="btn" onClick={() => setShowDeleteModal(true)}>
            <img src="/images/delete-user.svg" />
          </button>
        </div>
      </div>
      {!isEmpty(chat?.document) && (
        <FileHeader
          cachedBranding={cachedBranding}
          document={chat?.document}
          refetchChat={refetchChat}
          setDocumentId={setDocumentId}
        />
      )}
      <div
        className="chat-body px-5"
        style={{ backgroundColor: cachedBranding?.botBackgroundColor }}
      >
        {chat?.cd &&
          chat?.cd.map((message, index) =>
            !message?.isUser_NotBot ? (
              <>
                <BotMessage
                  message={message}
                  refetchChat={refetchChat}
                  key={index}
                  cachedBranding={cachedBranding}
                />
                <small className="time">
                  {moment(new Date(message?.dateCreated)).isSame(
                    moment(),
                    "day"
                  ) ? (
                    <>
                      {moment(new Date(message?.dateCreated)).format("hh:mm A")}
                    </>
                  ) : (
                    <>
                      {moment(new Date(message?.dateCreated)).format("hh:mm A")}
                      <br />
                      <span>
                        {moment(new Date(message?.dateCreated)).format(
                          "MMM D, YY"
                        )}
                      </span>
                    </>
                  )}
                </small>
              </>
            ) : !isEmpty(message?.documentId) && isEmpty(message?.text) ? (
              <>
                <div className="guest-bubble" key={index}>
                  <div
                    className="mb-0 row"
                    style={{
                      backgroundColor: cachedBranding?.bubbleColor,
                      color: cachedBranding?.userChatColor,
                      fontFamily: `${cachedBranding?.fontStyle}, sans-serif`,
                      width: "250px",
                      overflow: "hidden", // Add this line to hide overflow content
                      textOverflow: "ellipsis", // Add this line to display ellipsis for overflow
                      whiteSpace: "nowrap",
                      padding: "1.125em 1.5em",
                      borderRadius: "1rem",
                    }}
                  >
                    <img
                      src="/images/file-chat.svg"
                      height={58.75}
                      width={47}
                      className="w-25"
                    />
                    <span
                      style={{
                        maxWidth: "200px",
                        fontSize: "18px",
                        display: "block",
                        whiteSpace: "nowrap", // Add this line to prevent text from wrapping
                        overflow: "hidden", // Add this line to hide overflow text
                        textOverflow: "ellipsis", // Add this line to display ellipsis for overflow
                      }}
                      className="w-75 mt-3"
                    >
                      {message?.doumentName}
                    </span>
                  </div>
                </div>
                <small className="align-self-end time">
                  {moment(new Date(message?.dateCreated)).isSame(
                    moment(),
                    "day"
                  ) ? (
                    <>
                      {moment(new Date(message?.dateCreated)).format("hh:mm A")}
                    </>
                  ) : (
                    <>
                      {moment(new Date(message?.dateCreated)).format("hh:mm A")}
                      <br />
                      <span>
                        {moment(new Date(message?.dateCreated)).format(
                          "MMM D, YY"
                        )}
                      </span>
                    </>
                  )}
                </small>
              </>
            ) : (
              <>
                <div className="guest-bubble" key={index}>
                  <p
                    className="mb-0"
                    style={{
                      backgroundColor: cachedBranding?.bubbleColor,
                      color: cachedBranding?.userChatColor,
                      fontFamily: `${cachedBranding?.fontStyle}, sans-serif`,
                    }}
                  >
                    {message?.text}
                  </p>
                </div>
                <small className="align-self-end time">
                  {moment(new Date(message?.dateCreated)).isSame(
                    moment(),
                    "day"
                  ) ? (
                    <>
                      {moment(new Date(message?.dateCreated)).format("hh:mm A")}
                    </>
                  ) : (
                    <>
                      {moment(new Date(message?.dateCreated)).format("hh:mm A")}
                      <br />
                      <span>
                        {moment(new Date(message?.dateCreated)).format(
                          "MMM D, YY"
                        )}
                      </span>
                    </>
                  )}
                </small>
              </>
            )
          )}
        {tempText && (
          <div className="guest-bubble">
            <p>{tempText}</p>
          </div>
        )}
        <AlwaysScrollToBottom />
      </div>
      <DeleteChatModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        chat={chat}
        setSelectedChat={setSelectedChat}
        refetchFavorites={refetchFavorites}
        refetchIndividualChats={refetchIndividualChats}
        refetchInstituteChats={refetchInstituteChats}
        refetchInstituteChatsGroup={refetchInstituteChatsGroup}
        refetchMaterialChatsGroup={refetchMaterialChatsGroup}
        handleSelect={handleSelect}
      />
    </>
  );
}

export default Chat;
