import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "../constants";
import { cleanToken } from "utils";

const fetchAllCategories = async () => {
  return await axios.get(API_URL + `/api/Category/GetCategories`, {
    headers: {
      Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
    },
  });
};

const fetchChatCategorization = async (data) => {
  return await axios.get(
    API_URL +
      `/api/Category/GetChatCatergoization/${data.pageSize}/${data.pageNumber}`,
    {
      headers: {
        Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
      },
    }
  );
};

const fetchChatSettings = async () => {
  return await axios.get(API_URL + `/Company/GetChatSettings`, {
    headers: {
      Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
    },
  });
};
export const useFetchAllCategories = () => {
  return useQuery({
    queryKey: ["categories"],
    queryFn: () => fetchAllCategories(),
    enabled: true,
  });
};

export const useFetchChatCategorization = (data) => {
  return useQuery({
    queryKey: ["chatCategorization"],
    queryFn: () => fetchChatCategorization(data),
    enable: true,
  });
};

export const useFetchChatSettings = () => {
  return useQuery({
    queryKey: ["chatSettings"],
    queryFn: () => fetchChatSettings(),
    enable: true,
  });
};
