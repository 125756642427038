import { Sidebar } from "components";
import { LoadingSpinner, PageTitle, RoundedButton } from "components/Custom";
import { ScriptCard } from "components/Settings";
import NewScriptModal from "components/Settings/NewScriptModal";
import { CopyScript } from "components/WalkThrough";
import { useFetchCompanyBots } from "hooks/script";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

function Script() {
  const location = useLocation();
  const {
    data: bots,
    refetch,
    isFetching: isFetchingBots,
  } = useFetchCompanyBots();
  const queryClient = useQueryClient();
  const cachedBranding = queryClient.getQueryData(["branding"])?.data;
  const [showNewScriptModal, setShowNewScriptModal] = useState(false);
  const [showCopyScript, setShowCopyScript] = useState(
    location?.state?.showModal || false
  );
  const navigate = useNavigate();
  return (
    <div
      className="container-fluid"
      style={{
        backgroundColor: cachedBranding?.page_BackgroundColor,
        minHeight: "100vh",
      }}
    >
      <div className="row flex-nowrap">
        <Sidebar
          handleTourClick={() => (
            setShowCopyScript(true), window.scrollTo(0, 0)
          )}
        />
        <div className="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-2"></div>
        <div
          className="col-10 col-sm-9 col-md-9 col-lg-9 col-xl-10 px-sm-5"
          style={{ padding: "0 200px" }}
        >
          <div className="dashboard">
            <div className="page-title">
              <div className="row w-100">
                <PageTitle title="Script" time="29 May 2023 - 4:02 PM" />
              </div>
              <RoundedButton
                text="New Script"
                className="main-btn w-25"
                handleOnClick={() => setShowNewScriptModal(true)}
              />
            </div>
            <ScriptCard bots={bots} cachedBranding={cachedBranding} />
          </div>
        </div>
      </div>
      <NewScriptModal
        show={showNewScriptModal}
        handleClose={() => setShowNewScriptModal(false)}
        refetch={refetch}
        cachedBranding={cachedBranding}
      />
      <CopyScript
        show={showCopyScript}
        handleClose={() => setShowCopyScript(false)}
        handleSubmit={() =>
          navigate("/settings/chat-settings", { state: { showModal: true } })
        }
        cachedBranding={cachedBranding}
      />
      <LoadingSpinner showSpinner={isFetchingBots} />
    </div>
  );
}

export default Script;
