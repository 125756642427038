import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { RoundedButton } from "components/Custom";
import { API_URL } from "constants";
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { cleanToken } from "utils";
import { useFetchBotScript } from "hooks/script";



function ScriptModal({ show, handleClose, scriptData }) {




  return (
    <Modal show={show} onHide={handleClose} centered size="lg">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Copy Below To Your WebSite</h4>
          <div className="modal-option">
            <button type="button" className="btn" onClick={handleClose}>
              <img src="/images/close-modal.svg" />
            </button>
          </div>
        </div>
        <div className="modal-body editProfile justify-content-around flex-column">
          <div className="input-group align-items-center mb-1">
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              rows="15"
              value={scriptData}
            ></textarea>
          </div>
          <RoundedButton
            text="Copy Embedded Code"
            handleOnClick={() => {}}
            className="btn shadow-sm main-btn p-3 w-500 rounded-pill ms-3"
          />
        </div>
      </div>
    </Modal>
  );
}

export default ScriptModal;
