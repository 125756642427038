import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { DeleteModal, LockModal } from "./modal";

export default function CustomerTableRow({ customer }) {
  const navigate = useNavigate();
  const [showLockModal, setShowLockModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  return (
    <>
      <tr className="table-tile">
        <th scope="row" className="name">
          <button
            type="button"
            className="btn user-btn px-0 pt-2"
            onClick={() => navigate("/admin/customer-details")}
          >
            {" "}
            <div className="user-name">
              <span>{customer?.companyName}</span>
            </div>
          </button>{" "}
        </th>
        <td className="table-details">
          <span>{customer?.contactPerson}</span>
        </td>
        <td className="table-details">
          <span>{customer?.email}</span>
        </td>
        <td className="table-details">
          <span>{customer?.mobile}</span>
        </td>
        <td className="table-details">
          <span>{customer?.plan}</span>
        </td>
        <td className="table-details">
          <span>{customer?.tokens}</span>
        </td>
        <td className="table-details">
          <span>{customer?.dateJoined}</span>
        </td>

        <td className="table-details">
          <div className="action-option">
            <button
              type="button"
              className="btn lock"
              onClick={() => setShowLockModal(true)}
            >
              <img className="dark-filter" src="/images/lock.svg" />
            </button>
            <button
              type="button"
              className="btn action-btn delete"
              onClick={() => setShowDeleteModal(true)}
            >
              <img src="/images/delete.svg" />
            </button>
          </div>
        </td>
      </tr>
      <DeleteModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        handleSubmit={() => {}}
      />
      <LockModal
        show={showLockModal}
        handleClose={() => setShowLockModal(false)}
        handleSubmit={() => {}}
      />
    </>
  );
}
