import { isEmpty } from "lodash";
import React, { useState } from "react";
import { generateUUID } from "utils";
import SetAdminModal from "./SetAdminModal";

function GroupRow({
  chat,
  handleSelect,
  cachedBranding,
  refetchGroups,
  refetchMaterialChatsGroup,
  refetchInstituteChatsGroup,
  setDocumentId,
}) {
  const [showActions, setShowActions] = useState(false);
  const [showSetAdminModal, setShowSetAdminModal] = useState(false);
  const handleEmptyGuid = (guid) => {
    if (!isEmpty(chat?.documentId)) {
      setDocumentId(chat?.documentId);
    }
    if ("00000000-0000-0000-0000-000000000000" === guid) {
      const id = generateUUID();
      handleSelect(id, "new", id, chat?.groupId);
    } else {
      handleSelect(chat?.chatId, "old");
    }
  };

  return (
    <div
      className="groups-card cursor-pointer"
      onClick={() => handleEmptyGuid(chat?.chatId)}
    >
      <div className="row justify-content-between">
        <div className="col-3">
          <img src="/images/group-chat.svg" />
        </div>
        <div className="individual-info col-6">
          <h6
            className="fw-medium mb-0"
            style={{ color: cachedBranding?.page_TextColor, fontSize: "14px" }}
          >
            {chat?.groupName}
          </h6>
          <h6
            style={{
              color: cachedBranding?.page_TextColor,
              fontSize: "12px",
            }}
            className="mb-0"
          >
            {chat?.chatTitle}
          </h6>
          <p>
            Chat Owner:{" "}
            {!isEmpty(chat?.temporaryAdminName)
              ? chat?.temporaryAdminName
              : "N/A"}
          </p>
        </div>
        <div className="col-2">
          {chat?.showAdminControl == 1 && (
            <div className="dropdown">
              <button
                className="px-0 bg-transparent border-0"
                type="button"
                onClick={() => setShowActions(!showActions)}
              >
                <img src="/images/more-chat.svg" style={{ width: "5px" }} />
              </button>
              <div
                className={`dropdown-menu ${
                  showActions ? "show" : ""
                } favorite-dropdown`}
              >
                <button
                  className="dropdown-item chat-dropdown"
                  type="button"
                  style={{
                    color: cachedBranding?.page_TextColor,
                  }}
                  onClick={() => setShowSetAdminModal(true)}
                >
                  Set Chat Owner{" "}
                  <img height={18} src="/images/admin-icon.svg" />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <SetAdminModal
        show={showSetAdminModal}
        handleClose={() => setShowSetAdminModal(false)}
        groupId={chat?.groupId}
        chatId={chat?.chatId}
        refetchGroups={refetchGroups}
        refetchMaterialChatsGroup={refetchMaterialChatsGroup}
        refetchInstituteChatsGroup={refetchInstituteChatsGroup}
        setShowActions={setShowActions}
      />
    </div>
  );
}

export default GroupRow;
