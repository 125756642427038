import { RoundedButton } from "components/Custom";
import React from "react";
import { Modal } from "react-bootstrap";

function CreateModelModal({ show, handleClose, cachedBranding }) {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <div className="modal-content">
        <div className="modal-header">
          <h5
            className="modal-title"
            style={{ color: cachedBranding?.page_TextColor }}
          >
            Create a Model
          </h5>
          <div className="modal-option">
            <button type="button" className="btn" onClick={handleClose}>
              <img src="/images/close-modal.svg" />
            </button>
          </div>
        </div>
        <div className="modal-body">
          <div className="form-group ">
            <label
              className="mb-2"
              style={{ color: cachedBranding?.page_TextColor }}
            >
              Modal Name
            </label>
            <input type="text" className="form-control profile-input" />
          </div>
          <div className="mt-4">
            <img src="/images/upload-ico.svg" />
            <span
              className="ms-2"
              style={{
                color: cachedBranding?.page_TextColor,
                fontSize: "16px",
                fontWeight: 500,
              }}
            >
              Upload Model Answers
            </span>
          </div>
          <div className="mt-4">
            <img src="/images/upload-ico.svg" />
            <span
              className="ms-2"
              style={{
                color: cachedBranding?.page_TextColor,
                fontSize: "16px",
                fontWeight: 500,
              }}
            >
              Upload Students <br />{" "}
              <div className="cont-wmessage">
                <img src="/images/info.svg" alt="vector" />
                <span
                  className="w-message"
                  style={{ color: cachedBranding?.page_TextColor }}
                >
                  When the user selects this option their OneLogin session
                  permits after the browser closes.
                </span>
              </div>
            </span>
          </div>
        </div>
        <div className="modal-footer">
          <RoundedButton
            text="Save"
            className="btn main-btn w-100"
            handleOnClick={handleClose}
          />
        </div>
      </div>
    </Modal>
  );
}

export default CreateModelModal;
