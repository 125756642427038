import React, { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";

function TabToggleButton({ items, selected, setSelected }) {
  const queryClient = useQueryClient();
  const cachedBranding = queryClient.getQueryData(["branding"])?.data;

  return (
    <div className="user-filter">
      {items.map((item, index) => (
        <div
          onClick={() => setSelected(item)}
          key={index}
          className={`${item == selected ? "all" : "group"} cursor-pointer`}
          style={{
            backgroundColor:
              item == selected ? cachedBranding?.page_ButtonColor : "",
          }}
        >
          <span>{item}</span>
        </div>
      ))}
    </div>
  );
}

export default TabToggleButton;
