import React, { useEffect, useState } from "react";
import { RoundOutlinedButton, RoundedButton } from "components/Custom";
import ChangePlanModal from "./ChangePlanModal";
import OrderSummaryModal from "./OrderSummaryModal";
import AdditionalConsumptionsModal from "./AdditionalConsumptionsModal";
import CancelPlanModal from "./CancelPlanModal";

function PlanCard({ plan, cachedBranding }) {
  const [showChangePlanModal, setShowChangePlanModal] = useState(false);
  const [showOrderSummaryModal, setShowOrderSummaryModal] = useState(false);
  const [showCancelPlanModal, setShowCancelPlanModal] = useState(false);
  const [showAdditionalConsumptionsModal, setShowAdditionalConsumptionsModal] =
    useState(false);
  const [autoRenew, setAutoRenew] = useState(false);

  useEffect(() => {
    setAutoRenew(plan.autoRenewal);
  }, [plan]);

  return (
    <div className="normal-card plan">
      <div className="your-plan w-25">
        <h5 style={{ color: cachedBranding?.page_TextColor }}>Your Plan</h5>
        <h5 className="mb-0" style={{ color: cachedBranding?.page_TextColor }}>
          {plan?.name}
        </h5>
        <span style={{ color: cachedBranding?.page_TextColor }}>Monthly</span>
        <p className="fw-light mb-0">Subscription renewal date</p>
        <p
          className="fw-semibold mb-0"
          style={{ color: cachedBranding?.page_TextColor }}
        >
          {plan?.renewalDate} (in {plan?.daysTillRenewal} days)
        </p>
        <div className="auto-renew">
          <span>Auto-renew</span>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              checked={autoRenew ? true : false}
              onChange={() => setAutoRenew(!autoRenew)}
            />
          </div>
        </div>
        <RoundedButton
          text="Change Plan"
          style={{ marginBottom: "1.5rem" }}
          handleOnClick={() => setShowChangePlanModal(true)}
        />
        <a
          className="text-body-tertiary fw-normal cursor-pointer"
          onClick={() => setShowCancelPlanModal(true)}
        >
          Cancel Plan
        </a>
      </div>
      <div className="includes">
        <h5
          className="fw-semibold mb-3"
          style={{ color: cachedBranding?.page_TextColor }}
        >
          Includes in your plan
        </h5>
        <div className="features">
          <div className="f-list">
            <div className="feature-list">
              <span className="fw-medium">GPT Version:</span>
              <h6
                className="fw-semibold mb-0 text-end"
                style={{ color: cachedBranding?.page_TextColor }}
              >
                {plan?.gptVersion}
              </h6>
            </div>
            <div className="feature-list">
              <span className="fw-medium">Tokens:</span>
              <h6
                className="fw-semibold mb-0"
                style={{ color: cachedBranding?.page_TextColor }}
              >
                {plan?.tokens}
              </h6>
            </div>
            <div className="feature-list">
              <span className="fw-medium">Consumptions:</span>
              <h6
                className="fw-semibold mb-0"
                style={{ color: cachedBranding?.page_TextColor }}
              >
                {plan?.consumptions}
              </h6>
            </div>
            <div className="feature-list">
              <span className="fw-medium">Integration:</span>
              <h6
                className="fw-semibold mb-0"
                style={{ color: cachedBranding?.page_TextColor }}
              >
                {plan?.integration}
              </h6>
            </div>
            <div className="feature-list">
              <span className="fw-medium">Support:</span>
              <h6
                className="fw-semibold mb-3"
                style={{ color: cachedBranding?.page_TextColor }}
              >
                {plan?.support}
              </h6>
            </div>
            <h6
              className="fw-semibold"
              style={{ color: cachedBranding?.page_TextColor }}
            >
              Admin Console
            </h6>
          </div>
          <div className="f-list2">
            {plan?.benefits &&
              plan?.benefits.map((benefit, index) => (
                <h6
                  className="fw-semibold mb-3"
                  key={index}
                  style={{ color: cachedBranding?.page_TextColor }}
                >
                  {benefit}
                </h6>
              ))}
          </div>
        </div>
        <div className="add-ons">
          <h5
            className="fw-semibold mb-4"
            style={{ color: cachedBranding?.page_TextColor }}
          >
            Add ons:
          </h5>
          <div className="features">
            {plan?.addsOn &&
              plan?.addsOn.map((addOn, index) => (
                <div className="f-list" key={index}>
                  <h6
                    className="fw-semibold mb-1"
                    style={{ color: cachedBranding?.page_TextColor }}
                  >
                    {addOn?.name}
                  </h6>
                  <p
                    className="fw-light mb-0"
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    Subscription renewal date
                  </p>
                  <p
                    className="fw-semibold mb-2"
                    style={{
                      fontSize: "12px",
                      color: cachedBranding?.page_TextColor,
                    }}
                  >
                    {addOn?.renewalDate} (in {addOn?.daysTillRenewal} days)
                  </p>
                  <div className="feature-list">
                    <span className="fw-medium">Minutes:</span>
                    <h6
                      className="fw-semibold mb-0"
                      style={{ color: cachedBranding?.page_TextColor }}
                    >
                      {addOn?.minutes}
                    </h6>
                  </div>
                  <h6
                    className="fw-semibold mb-3"
                    style={{ color: cachedBranding?.page_TextColor }}
                  >
                    {addOn?.description}
                  </h6>
                </div>
              ))}
          </div>
          <RoundOutlinedButton
            text="Request Additional Consumption"
            className="w-50"
            handleClick={() => {
              setShowAdditionalConsumptionsModal(true);
            }}
          />
        </div>
      </div>
      <ChangePlanModal
        show={showChangePlanModal}
        handleClose={() => setShowChangePlanModal(false)}
        handleSubmit={() => (
          setShowChangePlanModal(false), setShowOrderSummaryModal(true)
        )}
      />
      <OrderSummaryModal
        show={showOrderSummaryModal}
        handleBack={() => (
          setShowOrderSummaryModal(false), setShowChangePlanModal(true)
        )}
      />
      <AdditionalConsumptionsModal
        show={showAdditionalConsumptionsModal}
        handleClose={() => setShowAdditionalConsumptionsModal(false)}
      />
      <CancelPlanModal
        show={showCancelPlanModal}
        handleClose={() => setShowCancelPlanModal(false)}
        handleSubmit={() => {}}
        date={plan?.renewalDate}
      />
    </div>
  );
}

export default PlanCard;
