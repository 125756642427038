import React, { useState } from "react";
import UpdateMethodModal from "./UpdateMethodModal";
import UpdateBillingAddressModal from "./UpdateBillingAddressModal";
import { isEmpty } from "lodash";

function BillingCard({ plan, cardInfo, cachedBranding }) {
  const [showUpdateMethod, setShowUpdateMethod] = useState(false);
  const [showUpdateBilling, setShowUpdateBilling] = useState(false);

  return (
    <div className="normal-card d-flex flex-xl-row flex-lg-column flex-md-column justify-content-between justify-content-md-between w-100">
      <div className="d-flex flex-column w-50 flex-fill mb-md-5">
        <h5
          className="fw-semibold mb-4"
          style={{ color: cachedBranding?.page_TextColor }}
        >
          Payment Method
        </h5>
        <h6
          className="fw-semibold mb-3"
          style={{ color: cachedBranding?.page_TextColor }}
        >
          {plan?.paymentMethod}
        </h6>
        <div className="">
          <img src="/images/visa.png" />
          <span
            className="fw-medium mb-3"
            style={{ color: cachedBranding?.page_TextColor }}
          >
            **** **** **** 1234
          </span>
        </div>
        <button
          className="btn w-75"
          onClick={() => setShowUpdateMethod(true)}
          style={{
            color: !isEmpty(cachedBranding?.page_ButtonColor)
              ? cachedBranding?.page_ButtonColor
              : "#0844e1",
            padding: "10px",
            borderColor: !isEmpty(cachedBranding?.page_ButtonColor)
              ? cachedBranding?.page_ButtonColor
              : "#0844e1",
            borderRadius: "50px",
            marginTop: "10px",
          }}
        >
          Update Payment Method
        </button>
      </div>

      <div className="d-flex flex-column flex-fill w-50">
        <h5
          className="fw-semibold mb-4"
          style={{ color: cachedBranding?.page_TextColor }}
        >
          Billing Address
        </h5>
        <h6
          className="fw-semibold mb-3"
          style={{ color: cachedBranding?.page_TextColor }}
        >
          {plan?.billingAddress?.fullName}
        </h6>
        <span className="fw-light">
          {plan?.billingAddress?.companyName},{" "}
          {plan?.billingAddress?.buildingName}
        </span>
        <span className="fw-light">
          {plan?.billingAddress?.areaName}, {plan?.billingAddress?.cityName},{" "}
          {plan?.billingAddress?.postalNumber},{" "}
          {plan?.billingAddress?.countryName}
        </span>
        <span className="fw-light">TRN:{plan?.billingAddress?.trn}</span>
        <button
          className="btn w-75"
          onClick={() => setShowUpdateBilling(true)}
          style={{
            color: !isEmpty(cachedBranding?.page_ButtonColor)
              ? cachedBranding?.page_ButtonColor
              : "#0844e1",
            padding: "10px",
            borderColor: !isEmpty(cachedBranding?.page_ButtonColor)
              ? cachedBranding?.page_ButtonColor
              : "#0844e1",
            borderRadius: "50px",
            marginTop: "10px",
          }}
        >
          Update Billing Address
        </button>
      </div>
      <UpdateMethodModal
        show={showUpdateMethod}
        handleClose={() => setShowUpdateMethod(false)}
        cardInfo={cardInfo}
      />
      <UpdateBillingAddressModal
        show={showUpdateBilling}
        handleClose={() => setShowUpdateBilling(false)}
        billingAddress={plan?.billingAddress}
      />
    </div>
  );
}

export default BillingCard;
