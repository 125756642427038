import { RoundedButton } from "components/Custom";
import React from "react";
import { Modal } from "react-bootstrap";

function OrderSummaryModal({
  show,
  handleBack,
  handleSubmit,
  selectedAddOn,
  plan,
}) {
  return (
    <Modal show={show} centered size="lg">
      <div className="modal-content">
        <div className="modal-header">
          <h1 className="modal-title fs-5" id="exampleModalLabel">
            Order Summary
          </h1>
          <button type="button" className="btn" onClick={handleBack}>
            <img src="/images/close-modal.svg" />
          </button>
        </div>
        <div className="modal-body">
          <div className="d-flex flex-column">
            <h6 className="fw-semibold mb-0 mt-3">{plan?.name}</h6>
            <span>{plan?.gptVersion}</span>
            <span className="mb-3">{plan?.tokens} Tokens</span>
          </div>

          <div className="border-bottom">
            <h6 className="fw-semibold mb-2 mt-3">Payment Method</h6>
            <div className="d-flex flex-row justify-content-between mb-3">
              <span> Credit Card</span>
              <div className="">
                <img src="/images/visa.png" />
                <span className="fw-medium mb-3">**** **** **** 1234</span>
              </div>
            </div>
          </div>
          <div className="d-flex flex-row justify-content-between mb-3 mt-3">
            <span>Price</span>
            <span>USD ${selectedAddOn?.price}</span>
          </div>
          <div className="d-flex flex-row justify-content-between mb-3 border-bottom">
            <span className="mb-3">Tax</span>
            <span>USD ${selectedAddOn?.tax}</span>
          </div>

          <div className="d-flex flex-row justify-content-between mb-3 mt-3">
            <span>Total Amount</span>
            <span>USD ${selectedAddOn?.price + selectedAddOn?.tax}</span>
          </div>
        </div>
        <div className="d-flex justify-content-between p-4">
          <div className="d-flex align-center">
            <img src="/images/secure.svg" height={24} width={10} />
            <span className="px-2">Secure Checkout</span>
          </div>
          <div className="d-flex w-50 flex-row">
            <RoundedButton
              text="Back"
              style={{ width: "50%", height: "45px" }}
              type="btn-secondary"
              handleOnClick={() => handleBack()}
              className="mx-3"
            />
            <RoundedButton
              text="Confirm"
              type="main-btn w-50"
              handleOnClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default OrderSummaryModal;
