import SearchUser from "components/Users/SearchUser";
import React from "react";
import { useNavigate } from "react-router-dom";

function MaterialFileHeader({
  qnas,
  materialId,
  searchText,
  setSearchText,
  cachedBranding,
}) {
  const navigate = useNavigate();

  return (
    <div className="normal-card sub-token">
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center w-75">
          <div className="d-flex flex-column w-50">
            {/* <h6 className="me-5 mb-0">Advance Mathematics</h6> */}
            <span style={{ color: cachedBranding?.page_TextColor }}>
              {qnas?.length} QnA pairs
            </span>
          </div>

          <div className="search-bar w-50">
            <img src="/images/search.svg" className="search-ic" />
            <SearchUser searchText={searchText} setSearchText={setSearchText} />
          </div>
        </div>
        <div className=" d-flex">
          <button
            className="light-btn p-2 rounded mx-3 border-0"
            onClick={() => navigate(`/qna/custom-qna/${materialId}`)}
          >
            + Custom QnA
          </button>
        </div>
      </div>
      <div className="d-flex align-items-start">
        <div className="d-flex justify-content-between w-100 mt-4 mb-0">
          <h6
            className="w-50"
            style={{ color: cachedBranding?.page_TextColor }}
          >
            Questions
          </h6>
          <h6
            className="w-50 ms-5 ps-4"
            style={{ color: cachedBranding?.page_TextColor }}
          >
            Answers
          </h6>
        </div>
        <div className="d-flex w-25"></div>
      </div>
    </div>
  );
}

export default MaterialFileHeader;
