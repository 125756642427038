import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "constants";
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { cleanToken } from "utils";

function BotMessage({ message, refetchChat, cachedBranding }) {
  const [hoveredButton, setHoveredButton] = useState(null);
  const [selectedRating, setSelectedRating] = useState(null);

  useEffect(() => {
    if (message?.rating) {
      setHoveredButton(message.rating);
      setSelectedRating(message.rating);
    }
  }, [message]);

  const rateResponse = useMutation({
    mutationFn: (rating) => {
      return axios.post(
        API_URL + `/Chat/RateChatResponse/${message?.chatDetailId}/${rating}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${cleanToken(
              localStorage.getItem("token")
            )}`,
          },
        }
      );
    },
    onSuccess: async (data) => {
      console.log("data: ", data);
      refetchChat();
    },
    onError: (error) => {
      console.log("error: ", error);
    },
  });

  const handleRateResponse = (rating) => {
    rateResponse.mutate(rating);
    setSelectedRating(rating);
  };

  const handleButtonHover = (buttonNumber) => {
    setHoveredButton(buttonNumber);
  };

  const renderStars = () => {
    const starButtons = [];

    for (let i = 1; i <= 5; i++) {
      starButtons.push(
        <button
          key={i}
          className={`btn p-1 star ${
            (hoveredButton && i <= hoveredButton) ||
            (selectedRating && i <= selectedRating)
              ? "hovered"
              : ""
          }`}
          onMouseEnter={() => handleButtonHover(i)}
          onMouseLeave={() => handleButtonHover(null)}
          onClick={() => handleRateResponse(i)}
        >
          <img src="/images/star.svg" alt={`Star ${i}`} />
        </button>
      );
    }

    return starButtons;
  };

  const renderTextWithLinks = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.split(urlRegex).map((part, index) => {
      if (part.match(urlRegex)) {
        return (
          <NavLink to="part" target="_blank">
            part
          </NavLink>
        );
      } else {
        return part;
      }
    });
  };
  const linkRegex =
    /(?:https?:\/\/)?(?:www\.)?(?!([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9](?:\s?(?:am|pm)?))(?!([0-9]|0[0-9]|1[0-9]|2[0-3])\.[0-5][0-9])\S+\.\S+/g;
  const docRegex = /\[doc\d*\]/g;

  function modifyResponse(response) {
    // let mResponse = renderTextWithLinks(response);
    // let mResponse = response.toString().replace(
    //   new RegExp(linkRegex, "g"),
    //   <NavLink to="part" target="_blank">
    //     part
    //   </NavLink>
    // );
    let mResponse2 = response.toString().replace(new RegExp(docRegex, "g"), "");
    return mResponse2;
  }

  const handleCopyText = () => {
    if (message?.text) {
      navigator.clipboard
        .writeText(message.text)
        .then(() => {
          console.log("Text copied to clipboard successfully.");
        })
        .catch((error) => {
          console.error("Error copying text to clipboard:", error);
        });
    }
  };
  return (
    <div
      className="gpt-bubble mb-0"
      style={{
        "--bubble-color": cachedBranding?.bubbleColor,
        backgroundColor: `var(--bubble-color)`,
        overflowWrap: "break-word",
      }}
    >
      {/* <div>{modifyResponse(message?.text)} </div> */}

      <div
        style={{
          color: cachedBranding?.botChatColor,
          fontFamily: `${cachedBranding?.fontStyle}, sans-serif`,
          padding: "10px",
        }}
        dangerouslySetInnerHTML={{ __html: modifyResponse(message?.text) }}
      />
      <div className="d-flex justify-content-between">
        <span
          style={{ fontSize: "12px", alignSelf: "center" }}
          className="me-3 "
        >
          Rate this response
        </span>
        <div className="d-flex">{renderStars()}</div>
        <div className="row">
          <div className=" p-2 px-3 rounded d-flex justify-content-between">
            <div className="d-flex">
              <button className="btn" onClick={handleCopyText}>
                <img src="/images/copy.svg" />
              </button>
              {/* <button className="btn">
                <img src="/images/share.svg" />
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BotMessage;
