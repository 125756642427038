import { RoundedButton } from "components/Custom";
import React from "react";
import { Modal } from "react-bootstrap";

function ResetPasswordModal({ show, handleClose }) {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Password Settings</h5>
          <div className="modal-option">
            <button type="button" className="btn" onClick={handleClose}>
              <img src="/images/close-modal.svg" />
            </button>
          </div>
        </div>
        <div className="modal-body">
          <div className="form-check">
            <input className="form-check-input" type="checkbox" value="" />
            <label className="form-check-label">
              Minimum length of 8 characters to ensure that they are strong
              enough to resist brute force attacks
            </label>
          </div>
          <div className="form-check">
            <input className="form-check-input" type="checkbox" value="" />
            <label className="form-check-label">
              Combination of uppercase and lowercase letters, numbers, and
              special characters to make them more difficult to guess.
            </label>
          </div>
        </div>
        <div className="modal-footer">
          <RoundedButton
            text="Confirm"
            className="btn main-btn"
            handleOnClick={handleClose}
          />
        </div>
      </div>
    </Modal>
  );
}

export default ResetPasswordModal;
