import { CustomCheckbox, RoundedButton } from "components/Custom";
import React, { useState } from "react";
import LoginPreviewModal from "./LoginPreviewModal";
import ColorInput from "./ColorInput";
import ResetPasswordModal from "./ResetPasswordModal";
import AddSupportModal from "./AddSupportModal";
import TermsAndConditionsModal from "./TermsAndConditionsModal";
import { isEmpty } from "lodash";

function LoginSettings({
  loginBackgroundColor,
  buttonColor,
  addResetPassword,
  addSupport,
  addTermsAndConditions,
  firstField,
  setFirstField,
  setLoginBackgroundColor,
  loginPattern,
  setLoginPattern,
  setButtonColor,
  setAddResetPassword,
  setAddSupport,
  setAddTermsAndConditions,
  supportText,
  setSupportText,
  termsAndConditions,
  setTermsAndConditions,
  bot,
  tempBot,
  botIconContentType,
  mainLoginBackgroundColor,
  setMainLoginBackgroundColor,
}) {
  const [show, setShow] = useState(false);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [showAddSupportModal, setShowAddSupportModal] = useState(false);
  const [showTermsAndConditionsModal, setShowTermsAndConditionsModal] =
    useState(false);

  return (
    <div className="row w-100">
      <div className="col-md-12 col-lg-8 col-xl-8">
        <div className="normal-card">
          <div className="mb-2 mt-3">
            <label className="form-label fs-5 fw-semibold">Login Page</label>
          </div>
          <div className="row mb-4">
            {/** @todo */}
            {/* <div className="custom-color w-25">
              <label className="form-label mb-2   fw-medium">First Field</label>
              <div className="color-pick">
                <select
                  className="form-select border border-0"
                  style={{ fontSize: "12px" }}
                  value={firstField}
                  onChange={(e) => setFirstField(e.target.value)}
                >
                  <option value={0}>Email Address</option>
                  <option value={1}>One</option>
                  <option value={2}>Two</option>
                  <option value={3}>Three</option>
                </select>
              </div>
            </div> */}
            <ColorInput
              label="Background Color"
              className="col-3 col-md-3 col-lg-4"
              value={loginBackgroundColor}
              onChange={setLoginBackgroundColor}
            />
            <ColorInput
              label="Button Color"
              className="col-3 col-md-3 col-lg-4"
              value={buttonColor}
              onChange={setButtonColor}
            />
            {/* <ColorInput
              label="Main Login Background Color"
              className="col-3 col-md-3 col-lg-4"
              value={mainLoginBackgroundColor}
              onChange={setMainLoginBackgroundColor}
            /> */}
          </div>
          <div className="">
            <CustomCheckbox
              label="Password Settings"
              handleOnChange={() => (
                setShowResetPasswordModal(!addResetPassword ? true : false),
                setAddResetPassword(!addResetPassword)
              )}
              value={addResetPassword}
            />
            <CustomCheckbox
              label="Add Support"
              handleOnChange={() => (
                setShowAddSupportModal(!addSupport ? true : false),
                setAddSupport(!addSupport)
              )}
              value={addSupport}
            />
            <CustomCheckbox
              label="Add Terms & Conditions"
              handleOnChange={() => (
                setShowTermsAndConditionsModal(
                  !addTermsAndConditions ? true : false
                ),
                setAddTermsAndConditions(!addTermsAndConditions)
              )}
              value={addTermsAndConditions}
            />
          </div>
        </div>
      </div>
      <div className="col-4 d-flex flex-column">
        <div
          className="normal-card"
          style={{ backgroundColor: loginBackgroundColor }}
        >
          <div className="d-flex justify-content-between">
            <h5 className="fw-semibold mb-3">Login Page</h5>
          </div>
          <div className="d-flex justify-content-center mb-2">
            {tempBot ? (
              <img
                src={URL.createObjectURL(tempBot)}
                alt="Selected Bot"
                width={73}
              />
            ) : !isEmpty(bot) ? (
              <img
                src={`data:${botIconContentType};base64,${bot}`}
                width={73}
              />
            ) : (
              <img src="/images/default-bot.svg" height={63} width={73} />
            )}
          </div>
          <div className="form-group mb-3">
            <label>Email Address</label>
            <input
              type="email"
              className="form-control profile-input"
              placeholder="Enter your Email Address"
            />
          </div>
          <div className="form-group mb-3">
            <label>Password</label>
            <input type="password" className="form-control profile-input" />
          </div>
          <div className="d-flex justify-content-between mb-2">
            <div className="form-check">
              <input className="form-check-input" type="checkbox" value="" />
              <label className="form-check-label">Remember me</label>
            </div>
            {addResetPassword && (
              <a style={{ fontSize: "12px" }}>Forgot Passsword?</a>
            )}
          </div>
          <RoundedButton
            text="Login"
            className="btn mb-2 w-100"
            color={buttonColor}
            // style={{ backgroundColor: "#0744e1" }}
          />
          <div
            className="d-flex justify-content-between mb-2"
            style={{ fontSize: "12px" }}
          >
            {addTermsAndConditions && <a>Terms and Conditions</a>}
            {addSupport && <a>Need Support?</a>}
          </div>
        </div>
      </div>
      <ResetPasswordModal
        show={showResetPasswordModal}
        handleClose={() => setShowResetPasswordModal(false)}
      />
      <AddSupportModal
        show={showAddSupportModal}
        handleClose={() => setShowAddSupportModal(false)}
        supportText={supportText}
        setSupportText={setSupportText}
      />
      <TermsAndConditionsModal
        show={showTermsAndConditionsModal}
        handleClose={() => setShowTermsAndConditionsModal(false)}
        termsAndConditions={termsAndConditions}
        setTermsAndConditions={setTermsAndConditions}
      />
      <LoginPreviewModal show={show} handleClose={() => setShow(false)} />
    </div>
  );
}

export default LoginSettings;
