import React from "react";
import { useQueryClient } from "@tanstack/react-query";

function GroupNavTab({ text, active, handleOnClick }) {
  const queryClient = useQueryClient();
  const cachedBranding = queryClient.getQueryData(["branding"])?.data;

  return (
    <a
      className={`nav-item nav-link 
      cursor-pointer`}
      style={{
        borderBottomColor: active ? cachedBranding?.page_ButtonColor : "",
        borderBottomWidth: active ? "3px" : "",
        color: active ? cachedBranding?.page_ButtonColor : "",
      }}
      onClick={handleOnClick}
    >
      {text}
    </a>
  );
}

export default GroupNavTab;
