import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "constants";
import React, { useState } from "react";
import { cleanToken } from "utils";
import DeleteChatModal from "./DeleteChatModal";
import { isEmpty } from "lodash";

function InternetCard({
  handleSelect,
  message,
  refetchIndividualChats,
  refetchFavorites,
  cachedBranding,
  setSelectedChat,
  refetchInstituteChats,
  refetchInstituteChatsGroup,
  refetchMaterialChats,
  refetchMaterialChatsGroup,
  setDocumentId,
}) {
  const [showActions, setShowActions] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const addFavorite = useMutation({
    mutationFn: () => {
      return axios.post(
        API_URL +
          `/Chat/SetChatAsFavorite/${message?.chatId}/${!message?.isFavorite}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${cleanToken(
              localStorage.getItem("token")
            )}`,
          },
        }
      );
    },
    onSuccess: async (data) => {
      console.log("data: ", data);
      refetchIndividualChats();
      refetchFavorites();
    },
    onError: (error) => {
      console.log("error: ", error);
    },
  });

  const handleFavorite = () => {
    addFavorite.mutate();
  };

  const selectChat = () => {
    if (!isEmpty(message?.documentId)) {
      setDocumentId(message?.documentId);
    }
    handleSelect(message?.chatId, "old");
  };

  return (
    <div
      className="individual-card cursor-pointer"
      // onClick={() => handleSelect(message?.chatId, "old")}
      onClick={selectChat}
    >
      <div className="d-flex align-items-center w-100">
        <img src="/images/institute-data.svg" style={{ width: "28px" }} />
        <div className="individual-info ms-2">
          <h6 className="m-0" style={{ color: cachedBranding?.page_TextColor }}>
            {message?.chatTitle}
          </h6>
          <div
            style={{
              backgroundColor:
                0 === message?.chatType
                  ? "#BDC4FF"
                  : 1 === message?.chatType
                  ? "#AAFDFF"
                  : "#EBF4FF",
              width: "70px",
              borderRadius: "20px",
            }}
            className="text-center mt-2"
          >
            <span className="">
              {0 === message?.chatType
                ? "Internet"
                : 1 === message?.chatType
                ? "Institute"
                : "QnA"}
            </span>
          </div>
        </div>
      </div>
      <div className="direct-actions">
        {message?.isFavorite ? (
          <img src="/images/favorite.svg" style={{ width: "18px" }} />
        ) : (
          <img src="/images/fave.svg" style={{ width: "18px" }} />
        )}
        <div className="dropdown">
          <button
            className="px-0 bg-transparent border-0"
            onClick={() => setShowActions(!showActions)}
          >
            <img src="/images/more-chat.svg" style={{ width: "5px" }} />
          </button>
          <div
            className={`dropdown-menu ${
              showActions ? "show" : ""
            } chat-dropdown-items`}
          >
            {message?.isFavorite ? (
              <button
                className="dropdown-item chat-dropdown"
                type="button"
                style={{ color: cachedBranding?.page_TextColor }}
                onClick={() => handleFavorite()}
              >
                Unfavorite{" "}
                <img src="/images/favorite.svg" style={{ width: "18px" }} />
              </button>
            ) : (
              <button
                className="dropdown-item chat-dropdown"
                type="button"
                onClick={() => handleFavorite()}
                style={{ color: cachedBranding?.page_TextColor }}
              >
                Favorite <img src="/images/fave.svg" />
              </button>
            )}
            <button
              className="dropdown-item chat-dropdown"
              type="button"
              style={{ color: cachedBranding?.page_TextColor }}
              onClick={() => setShowDeleteModal(true)}
            >
              Delete <img src="/images/delete-user.svg" />
            </button>
          </div>
        </div>
      </div>
      <DeleteChatModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        chat={message}
        setSelectedChat={setSelectedChat}
        refetchFavorites={refetchFavorites}
        refetchIndividualChats={refetchIndividualChats}
        refetchInstituteChats={refetchInstituteChats}
        refetchInstituteChatsGroup={refetchInstituteChatsGroup}
        refetchMaterialChats={refetchMaterialChats}
        handleSelect={handleSelect}
        refetchMaterialChatsGroup={refetchMaterialChatsGroup}
      />
    </div>
  );
}

export default InternetCard;
