// SelectableUser.js
import { useEffect, useState } from "react";
import { useFetchUserImage } from "hooks/users";
import { isEmpty } from "lodash";

function SelectableUser({ member, isSelected, onSelect }) {
  const { data: userImage, refetch: refetchImage } = useFetchUserImage(
    member?.userId
  );

  const [localSelected, setLocalSelected] = useState(isSelected);

  useEffect(() => {
    if (!isEmpty(member)) {
      refetchImage();
    }
  }, [member]);

  const handleToggleSelect = () => {
    const newSelected = !localSelected;
    setLocalSelected(newSelected);
    onSelect(member.userId);
  };

  return (
    <div className="member-card">
      <div className="member-name">
        <img
          src={
            !isEmpty(userImage?.data?.imageBytes)
              ? `data:image/jpeg;base64,${userImage?.data?.imageBytes}`
              : "/images/user-empty.svg"
          }
          width="30"
          height="30"
          className="rounded-circle"
          alt={member?.username}
        />
        <span>
          {member?.firstName} {member?.lastName}
        </span>
      </div>
      <img
        src={localSelected ? "/images/remove.svg" : "/images/add.svg"}
        className="cursor-pointer"
        onClick={handleToggleSelect}
        height={20}
        width={20}
        alt={localSelected ? "Remove" : "Add"}
      />
    </div>
  );
}

export default SelectableUser;
