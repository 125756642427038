import { useQueryClient } from "@tanstack/react-query";
import { Sidebar } from "components";
import { BackButton, PageTitle } from "components/Custom";
import DetailsCard from "components/Profile/DetailsCard";
import UsageCard from "components/Profile/UsageCard";
import {
  useFetchAuthUser,
  useFetchUserImage,
  useFetchUserUsage,
} from "hooks/users";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Profile as ProfileModal } from "components/WalkThrough";

function Profile() {
  const location = useLocation();
  const navigate = useNavigate();
  const [showProfileModal, setShowProfileModal] = useState(
    location?.state?.showModal || false
  );
  const { data: authUser, refetch } = useFetchAuthUser();
  const { data: userImage, refetch: refetchImage } = useFetchUserImage(
    authUser?.data?.userId
  );
  const queryClient = useQueryClient();
  const cachedBranding = queryClient.getQueryData(["branding"])?.data;

  useEffect(() => {
    if (!isEmpty(authUser)) {
      refetchImage();
    }
  }, [authUser]);

  return (
    <div
      className="container-fluid"
      style={{
        backgroundColor: cachedBranding?.page_BackgroundColor,
        minHeight: "100vh",
      }}
    >
      <div className="row flex-nowrap">
        <Sidebar handleTourClick={() => setShowProfileModal(true)} />
        <div className="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-2"></div>
        <div
          className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10"
          style={{ padding: "0 150px" }}
        >
          <div className="dashboard">
            <div className="page-title">
              <div className="row w-100">
                <div className="col-12 d-flex align-items-center">
                  <BackButton />
                  <PageTitle title="Profile" time="29 May 2023 - 4:02 PM" />
                </div>

                <div className="col-6">
                  <DetailsCard
                    user={authUser?.data}
                    refetch={refetch}
                    image={userImage?.data}
                    cachedBranding={cachedBranding}
                  />
                </div>
                <div className="col-6">
                  <UsageCard
                    user={authUser?.data}
                    cachedBranding={cachedBranding}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProfileModal
        show={showProfileModal}
        handleClose={() => setShowProfileModal(false)}
        handleSubmit={() => {}}
      />
    </div>
  );
}

export default Profile;
