import React from "react";

function TableChatSettingHeader({ cachedBranding }) {
  return (
    <thead>
      <tr className="chatsettings-headers">
        <th
          className="tb-rw-message px-5"
          scope="col"
          style={{ color: cachedBranding?.page_TextColor }}
        >
          Questions
        </th>
        <th
          className="tb-rw-message"
          scope="col"
          style={{ color: cachedBranding?.page_TextColor }}
        >
          Category
        </th>
      </tr>
    </thead>
  );
}

export default TableChatSettingHeader;
