import React from "react";
import { Modal } from "react-bootstrap";

function DateModal({
  show,
  handleClose,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  cachedBranding,
  setDateFilterString,
}) {
  const handleSubmit = () => {
    setDateFilterString(
      `and Chats.CreatedOn >= '${startDate}' and Chats.CreatedOn <= '${endDate}'`
    );
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <div className="modal-content">
        <div className="modal-header">
          <h5
            className="modal-title"
            id="exampleModalCenterTitle"
            style={{ color: cachedBranding?.page_TextColor }}
          >
            Custom Date
          </h5>
          <div className="modal-option">
            <button type="button" className="btn" onClick={handleClose}>
              <img src="/images/close-modal.svg" />
            </button>
          </div>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-6">
              <label
                for="startDate"
                style={{ color: cachedBranding?.page_TextColor }}
              >
                From
              </label>
              <input
                id="startDate"
                className="form-control"
                type="date"
                onChange={(e) => setStartDate(e.target.value)}
                defaultValue={startDate}
                style={{ color: cachedBranding?.page_TextColor }}
                max={new Date().toISOString().split("T")[0]}
              />
            </div>
            <div className="col-6">
              <label
                for="startDate"
                style={{ color: cachedBranding?.page_TextColor }}
              >
                To
              </label>
              <input
                id="startDate"
                className="form-control"
                type="date"
                onChange={(e) => setEndDate(e.target.value)}
                defaultValue={endDate}
                style={{ color: cachedBranding?.page_TextColor }}
                max={new Date().toISOString().split("T")[0]}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer d-flex">
          <button
            className="btn btn-secondary w-25"
            style={{
              height: "45px",
              backgroundColor: cachedBranding?.page_TextColor,
            }}
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn main-btn w-25"
            style={{ backgroundColor: cachedBranding?.page_ButtonColor }}
            onClick={() => handleSubmit()}
          >
            Show
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default DateModal;
