import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "../constants";
import { cleanToken } from "utils";

const fetchRoles = async () => {
  return await axios.get(API_URL + `/Role/GetRoles`, {
    headers: {
      Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
    },
  });
};

const fetchRoutes = async () => {
  return await axios.get(API_URL + `/Role/GetRoutes`, {
    headers: {
      Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
    },
  });
};

export const useFetchRoles = () => {
  return useQuery({
    queryKey: ["roles"],
    queryFn: () => fetchRoles(),
    enabled: true,
  });
};

export const useFetchRoutes = () => {
  return useQuery({
    queryKey: ["routes"],
    queryFn: () => fetchRoutes(),
    enabled: true,
  });
};
