import React from "react";
import ScriptRow from "./ScriptRow";
import { useFetchCompanyBots } from "hooks/script";

function ScriptCard({ bots, cachedBranding }) {
  return (
    <>
      <div className="col-12">
        <div className="user-table">
          <div className="table-responsive-sm">
            <table className="table table-borderless tablecenter">
              <thead>
                <tr>
                  <th
                    className="table-header script-table-header"
                    scope="col"
                    style={{ color: cachedBranding?.page_TextColor }}
                  >
                    Bot Name
                  </th>
                  <th
                    className="table-header script-table-header"
                    scope="col"
                    style={{ color: cachedBranding?.page_TextColor }}
                  >
                    Internet/Institute
                  </th>
                  <th
                    className="table-header script-table-header"
                    scope="col"
                    style={{ color: cachedBranding?.page_TextColor }}
                  >
                    Version
                  </th>
                  <th
                    className="table-header script-table-header"
                    scope="col"
                    style={{ color: cachedBranding?.page_TextColor }}
                  >
                    {" "}
                    Project{" "}
                  </th>
                  <th
                    className="table-header script-table-header"
                    scope="col"
                    style={{ color: cachedBranding?.page_TextColor }}
                  >
                    Language
                  </th>
                  <th
                    className="table-header script-table-header"
                    scope="col"
                    style={{ color: cachedBranding?.page_TextColor }}
                  >
                    Status
                  </th>
                  <th
                    className="table-header script-table-header"
                    scope="col"
                    style={{ color: cachedBranding?.page_TextColor }}
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="script-table-body">
                {bots?.data.map((bot, index) => (
                  <ScriptRow
                    bot={bot}
                    cachedBranding={cachedBranding}
                    key={index}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default ScriptCard;
