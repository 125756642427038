import { useFetchGroupImage } from "hooks/groups";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";

function GroupRestrictionCard({ group, onUpdate, cachedBranding }) {
  const [tokens, setTokens] = useState(group?.tokenBalance || 0);
  const [active, setActive] = useState(group?.isActive || false);

  const { data: image, refetch: refetchImage } = useFetchGroupImage(
    group?.groupId
  );

  useEffect(() => {
    setTokens(group?.tokenBalance || 0);
    setActive(group?.isActive || false);
  }, [group]);

  const renderInitials = (name) => {
    const words = name.split(" ");
    const firstLetters = [];
    for (const word of words) {
      if (word.length > 0) {
        firstLetters.push(word[0]);
      }
    }
    const result = firstLetters.join("");
    return <div>{result}</div>;
  };

  const handleTokensChange = (e) => {
    const newTokens = e.target.value;
    setTokens(newTokens);
    onUpdate({ ...group, tokenBalance: parseInt(newTokens) });
  };

  const handleActiveChange = () => {
    const newActive = !active;
    setActive(newActive);
    onUpdate({ ...group, isActive: newActive });
  };
  useEffect(() => {
    if (!isEmpty(group?.groupId)) {
      refetchImage();
    }
  }, [group]);
  return (
    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
      <div className="group-card-rest">
        <div className="group-card-cont-header">
          <div className="tag2 cursor-pointer" onClick={handleActiveChange}>
            {active ? (
              <div className="role-tok ">
                <span>Lock</span>
              </div>
            ) : (
              <div className="role-tok super-admin  text-body-tertiary border-dark-subtle bg-light">
                <span>Unlock</span>
              </div>
            )}
          </div>
          <div className="group-name">
            {image?.data?.imageBytes ? (
              <img
                src={`data:image/jpeg;base64,${image?.data?.imageBytes}`}
                className="group-imageic"
                height={55}
                width={55}
              />
            ) : (
              <div className="group-initials">
                {renderInitials(group?.groupName)}
              </div>
            )}
            <div className="col-6">
              <h5 style={{ color: cachedBranding?.page_TextColor }}>
                {group?.groupName}
              </h5>
            </div>
          </div>
        </div>

        <div className="group-details">
          <div className="group-count">
            <span style={{ color: cachedBranding?.page_TextColor }}>
              Admin ({group?.adminCount}){" "}
            </span>
            <span style={{ color: cachedBranding?.page_TextColor }}>
              {" "}
              Members ({group?.memberCount})
            </span>
          </div>
          <div className="users-group flex-column mb-0">
            <h6
              className="title-token-w"
              style={{ color: cachedBranding?.page_TextColor }}
            >
              Token Balance
            </h6>
            <input
              type="number"
              min="0"
              max="1000000"
              maxLength="9"
              value={tokens}
              className="num-inp2 mb-0"
              disabled={!active}
              onChange={handleTokensChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default GroupRestrictionCard;
