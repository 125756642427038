import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import GroupRestrictionCard from "./GroupRestrictionCard";
import { LoadingSpinner, Pagination } from "components/Custom";
import { useFetchCompanyGroups } from "hooks/groups";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "constants";
import { cleanToken } from "utils";
import toast from "react-hot-toast";

const GroupRestriction = forwardRef(
  (
    {
      cachedBranding,
      groups,
      refetchGroups,
      grouptotalPages,
      page,
      setPage,
      isFetchingGroups,
    },
    ref
  ) => {
    const [groupData, setGroupData] = useState(groups?.data);
    const [changedRows, setChangedRows] = useState([]);

    useImperativeHandle(ref, () => ({
      handleUpdate,
    }));

    const editGroup = useMutation({
      mutationFn: () => {
        return axios.put(
          API_URL + `/User/UpdateGroupTokenBalance`,
          changedRows,
          {
            headers: {
              Authorization: `Bearer ${cleanToken(
                localStorage.getItem("token")
              )}`,
            },
          }
        );
      },
      onSuccess: async (data) => {
        refetchGroups();
        toast.success("Saved Successfully");
      },
      onError: (error) => {
        console.log("error: ", error);
      },
    });

    useEffect(() => {
      refetchGroups();
    }, []);

    const handleGroupUpdate = (updatedGroup) => {
      // Extract only the desired fields
      const { groupId, isActive, tokenBalance } = updatedGroup;

      // Check if the updated user is already in the changedRows array
      const updatedRows = [...changedRows];
      const existingIndex = updatedRows.findIndex(
        (row) => row.groupId === groupId
      );

      if (existingIndex !== -1) {
        // If the user exists in changedRows, update it
        updatedRows[existingIndex] = { groupId, isActive, tokenBalance };
      } else {
        // If not, add it to changedRows
        updatedRows.push({ groupId, isActive, tokenBalance });
      }

      // Update the state with the updated rows
      setChangedRows(updatedRows);
    };

    const handleUpdate = () => {
      editGroup.mutate();
    };

    return (
      <>
        <div className="row">
          {groups?.data?.items &&
            groups?.data?.items.map((group, index) => (
              <GroupRestrictionCard
                group={group}
                key={index}
                onUpdate={(updatedGroup) => handleGroupUpdate(updatedGroup)}
                cachedBranding={cachedBranding}
              />
            ))}
        </div>
        <Pagination
          totalPages={grouptotalPages}
          page={page}
          setPage={setPage}
        />
        <LoadingSpinner showSpinner={isFetchingGroups} />
      </>
    );
  }
);

export default GroupRestriction;
