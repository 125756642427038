import React from "react";
import { Modal } from "react-bootstrap";

function CopyScript({ show, handleClose, handleSubmit, cachedBranding }) {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="script-plan modal-walkthrough"
      size="lg"
    >
      <div className="modal-content p-0">
        <div className="speech topuser p-2 m-0">
          <div className="row">
            <div className="col-12 d-flex justify-content-end mb-3 me-4">
              <button type="button" className="btn" onClick={handleClose}>
                <img src="/images/close-modal.svg" />
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-6 ">
              <img src="/images/wt-script.svg" />
            </div>
            <div className="col-6 p-0 pe-4">
              <h5 className="fw-semibold">Script Generation</h5>
              <ol style={{ fontSize: "14px" }}>
                <li>
                  Create a new script, select the desired language (React or
                  HTML) and the project.
                </li>
                <li>Click the Generate button to create the script.</li>
                <li>
                  Copy the generated script for integration into your software
                  or chat console.
                </li>
                <li>Change the Script status to inactive or active.</li>
              </ol>
              <div className="d-flex justify-content-end">
                <a
                  className="btn main-btn mt-4 px-4 cursor-pointer"
                  onClick={handleSubmit}
                  style={{ backgroundColor: cachedBranding?.page_ButtonColor }}
                >
                  Next
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default CopyScript;
