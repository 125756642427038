import { RoundedButton, RoundedInput } from "components/Custom";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";

function AdditionalConsumptionsModal({ show, handleClose, handleSubmit }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [tokensNeeded, setTokensNeeded] = useState(0);
  const [message, setMessage] = useState("");

  return (
    <Modal show={show} onHide={handleClose} centered>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalCenterTitle">
            Request Additional Consumptions
          </h5>
          <div className="modal-option">
            <button type="button" className="btn" onClick={handleClose}>
              <img src="/images/close-modal.svg" />
            </button>
          </div>
        </div>
        <div className="modal-body">
          <RoundedInput
            label="Name"
            type="text"
            placeholder="Enter your Name"
            value={name}
            handleOnChange={(e) => setName(e.target.value)}
          />
          <RoundedInput
            label="Email address"
            type="email"
            placeholder="Enter your Email"
            handleOnChange={() => {}}
          />
          <RoundedInput
            label="How many token needed?"
            type="number"
            placeholder="Enter Number of Tokens"
            value={tokensNeeded}
            handleOnChange={(e) => setTokensNeeded(e.target.value)}
          />
          <div className="mb-3">
            <label htmlFor="exampleFormControlTextarea1" className="form-label">
              Message
            </label>
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </div>
        </div>
        <div className="modal-footer d-flex">
          <RoundedButton
            text="Send Request"
            style={{ marginBottom: "1.5rem" }}
            handleOnClick={handleSubmit}
            className="w-100"
          />
        </div>
      </div>
    </Modal>
  );
}

export default AdditionalConsumptionsModal;
