import React, { useState } from "react";
import { UserIcon, Tag } from "components/Custom";
import GroupModal from "./GroupModal";

function TableRow({
  user,
  setSelectedUsers,
  selectedUsers,
  setShowDeleteModal,
  setShowEditModal,
  setSelectedUser,
  refetchUsers,
  handleCheckUser,
  cachedBranding,
}) {
  const [showUserInfo, setShowUserInfo] = useState(false);

  const isChecked = selectedUsers.includes(user.userId);

  const toggleCheckUser = () => {
    if (isChecked) {
      setSelectedUsers((selectedUsers) =>
        selectedUsers.filter((id) => id !== user.userId)
      );
    } else {
      setSelectedUsers((selectedUsers) => [...selectedUsers, user.userId]);
    }
  };

  return (
    <>
      <tr className="table-tile">
        <th scope="row" className="name">
          <div className="form-check">
            <input
              className="form-check-input position-static"
              type="checkbox"
              checked={isChecked}
              onChange={toggleCheckUser}
            />
          </div>
          <button
            type="button"
            className="btn user-btn"
            onClick={() => setShowUserInfo(true)}
          >
            <UserIcon
              userId={user?.userId}
              name={user?.firstName + " " + user?.lastName}
              image={user?.image}
              cachedBranding={cachedBranding}
            />
          </button>
        </th>
        <td className="table-details">
          <span style={{ color: cachedBranding?.page_TextColor }}>
            {user?.displayEmail}
          </span>
        </td>
        <td className="table-details">
          <span style={{ color: cachedBranding?.page_TextColor }}>
            {user?.mobile}
          </span>
        </td>
        <td className="table-details">
          <span style={{ color: cachedBranding?.page_TextColor }}>
            {user?.department}
          </span>
        </td>
        <td className="table-details">
          <Tag role={user?.roleName} roleId={user?.roleId} />
        </td>
        <td className="table-details">
          <div className="action-option">
            <button
              type="button"
              className="btn"
              onClick={() => (setShowEditModal(true), setSelectedUser(user))}
            >
              <img src="/images/edit-user.svg" />
            </button>
            <button
              type="button"
              className="btn action-btn delete"
              onClick={() => (setShowDeleteModal(true), setSelectedUser(user))}
            >
              <img src="/images/delete-user.svg" />
            </button>
          </div>
        </td>
      </tr>
      <GroupModal
        show={showUserInfo}
        handleClose={() => setShowUserInfo(false)}
        type="user"
        info={user}
        refetchUsers={refetchUsers}
        refetchGroups={() => {}}
      />
    </>
  );
}

export default TableRow;
