import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "../constants";
import { cleanToken } from "utils";

const fetchCompanyDescription = async () => {
  return await axios.get(API_URL + `/Company/GetCompanyDescription`, {
    headers: {
      Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
    },
  });
};

const fetchChatSettings = async () => {
  return await axios.get(API_URL + `/Company/GetChatSettings`, {
    headers: {
      Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
    },
  });
};

const fetchLoginData = async (subdomain) => {
  return await axios.get(
    API_URL + `/Company/GetLoginDataBySubDomain/${subdomain}`
    // {
    //   headers: {
    //     Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
    //   },
    // }
  );
};

export const useFetchCompanyDescription = () => {
  return useQuery({
    queryKey: ["companyDescription"],
    queryFn: () => fetchCompanyDescription(),
    enabled: true,
  });
};

export const useFetchChatSettings = () => {
  return useQuery({
    queryKey: ["chatSettings"],
    queryFn: () => fetchChatSettings(),
    enabled: true,
  });
};

export const useFetchLoginData = (subdomain) => {
  return useQuery({
    queryKey: ["loginData"],
    queryFn: () => fetchLoginData(subdomain),
    enabled: false,
  });
};
