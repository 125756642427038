import { useValidateToken } from "hooks/authentication";
import { useFetchCompanyBranding } from "hooks/branding";
import React, { useState, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = () => {
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const { data, refetch } = useValidateToken();
  const { data: branding, refetch: refetchBranding } =
    useFetchCompanyBranding();

  useEffect(() => {
    const style = document.createElement("style");
    style.appendChild(
      document.createTextNode(`
        * {
          font-family: '${branding?.data?.page_FontStyle}', sans-serif;
        }
        .login-view * {
          font-family: '${branding?.data?.fontStyle}', sans-serif !important;
        }
      `)
    );
    document.head.appendChild(style);
  }, [branding?.data]);

  useEffect(() => {
    const token = localStorage.getItem("token");

    const checkAuthentication = async () => {
      if (token) {
        const response = await refetch();
        if (response.data) {
          setAuthenticated(true);
        } else {
          setAuthenticated(false);
        }
      } else {
        setAuthenticated(false);
      }
      setLoading(false);
    };

    checkAuthentication();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return authenticated ? <Outlet /> : <Navigate to="/" replace />;
};

export default PrivateRoute;
