import React from "react";
import { Modal } from "react-bootstrap";

function Materials({ show, handleClose, handleSubmit, cachedBranding }) {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="material-plan modal-walkthrough"
      size="lg"
    >
      <div className="modal-content p-0">
        <div className="speech right p-5 m-0">
          <div className="row">
            <div
              className="col-12 d-flex justify-content-end mb-3 me-4"
              onClick={handleClose}
            >
              <img src="/images/close-modal.svg" />
            </div>
          </div>
          <div className="row">
            <div className="col-6 ">
              <img src="/images/manage-materials.svg" />
            </div>
            <div className="col-6 p-0 pe-4">
              <h5 className="fw-semibold">Cognitive Search Setup</h5>
              <ol style={{ fontSize: "14px" }}>
                <li>Access the Manage Materials module. </li>
                <li>Create folders to organize your data. </li>
                <li>
                  Upload documents or provide website URLs for cognitive search.
                </li>
                <li>
                  Review and edit paired Q&A generated by the system, or add
                  custom Q&A.
                </li>
              </ol>
              <div className="d-flex justify-content-end">
                <a
                  className="btn main-btn mt-4 px-4 cursor-pointer"
                  onClick={handleSubmit}
                  style={{ backgroundColor: cachedBranding?.page_ButtonColor }}
                >
                  Next
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default Materials;
