import { Sidebar } from "components";
import { PageTitle } from "components/Custom";
import { MaterialsCard } from "components/Materials";
import { Materials } from "components/WalkThrough";
import React, { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate, useLocation } from "react-router-dom";
import { QnaMaterialsCard } from "components/Qna";
import QNAMaterialsCard from "components/Qna/MaterialsCard";

function Qna() {
  const location = useLocation();
  const queryClient = useQueryClient();
  const cachedBranding = queryClient.getQueryData(["branding"])?.data;
  const [showMaterialsCard, setShowMaterialsCard] = useState(
    location?.state?.showModal || false
  );
  const navigate = useNavigate();

  return (
    <div
      className="container-fluid"
      style={{
        backgroundColor: cachedBranding?.page_BackgroundColor,
        minHeight: "100vh",
      }}
    >
      <div className="row flex-nowrap">
        <Sidebar handleTourClick={() => setShowMaterialsCard(true)} />
        <div className="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-2"></div>
        <div
          className="col-10 col-sm-9 col-md-9 col-lg-9 col-xl-10"
          style={{ padding: "0 5%" }}
        >
          <div className="dashboard">
            <div className="page-title">
              <div className="row w-100">
                <PageTitle
                  title="Custom Questions and Answers"
                  time="29 May 2023 - 4:02 PM"
                />
              </div>
            </div>
            <QNAMaterialsCard cachedBranding={cachedBranding} />
          </div>
        </div>
      </div>
      <Materials
        show={showMaterialsCard}
        handleClose={() => setShowMaterialsCard(false)}
        handleSubmit={() =>
          navigate("/settings/script", { state: { showModal: true } })
        }
        cachedBranding={cachedBranding}
      />
    </div>
  );
}

export default Qna;
