import React from "react";
import { Spinner } from "react-bootstrap";

function LoadingSpinner({ showSpinner }) {
  return (
    <>
      {showSpinner && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 9999,
          }}
        >
          <Spinner animation="border" role="status"></Spinner>
        </div>
      )}
    </>
  );
}

export default LoadingSpinner;
