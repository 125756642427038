import React from "react";
import MaterialsTableHeader from "./MaterialsTableHeader";
import MaterialsTableRow from "./MaterialsTableRow";

function MaterialsTable({ files, refetch, cachedBranding, isQna = false }) {
  return (
    <div className="">
      <div className="table-result">
        <div className="table-responsive-sm">
          <table className="table table-hover">
            <MaterialsTableHeader cachedBranding={cachedBranding} />
            <tbody>
              {files &&
                files.map((file, index) => (
                  <MaterialsTableRow
                    file={file}
                    refetch={refetch}
                    cachedBranding={cachedBranding}
                    key={index}
                    isQna={isQna}
                  />
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default MaterialsTable;
