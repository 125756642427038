import React from "react";
import { Modal } from "react-bootstrap";

function CancelPlanModal({ show, handleClose, handleSubmit, date }) {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Confirm Cancellation</h4>
          <div class="modal-option">
            <button type="button" class="btn" onClick={handleClose}>
              <img src="/images/close-modal.svg" />
            </button>
          </div>
        </div>
        <div class="modal-body editProfile justify-content-center d-flex text-center w-100 py-4">
          If you confirm and end your subscription,
          <br /> you can still use it till {date}.
        </div>
        <div class="modal-footer">
          <button type="button" class="btn normal-btn" onClick={handleClose}>
            Not Now
          </button>
          <button type="button" class="btn delete-btn" onClick={handleSubmit}>
            Confirm
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default CancelPlanModal;
