import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import SpinnerCircle from "components/Custom/SpinnerCircle";
import { API_URL } from "constants";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { cleanToken } from "utils";

function AddDocumentModal({
  show,
  handleClose,
  cachedBranding,
  chatType,
  newId,
  selectedChat,
  setChatType,
  setSelectedChat,
  refetchChat,
  setChatText,
  setTempText,
  refetchIndividualChats,
  refetchGroups,
  isInstitute,
}) {
  const [isFocused, setIsFocused] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    setIsFocused(true);
    setUploading(false);
  }, []);

  const addFile = useMutation({
    mutationFn: (fields) => {
      return axios.post(API_URL + `/Azure/UploadDocumentToChat`, fields, {
        headers: {
          Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
        },
      });
    },
    onSuccess: async (data) => {
      if ("new" === chatType) {
        setChatType("old");
        await setSelectedChat(newId);
      }
      refetchChat();
      setChatText("");
      setTempText("");
      refetchIndividualChats();
      refetchGroups();
      setTimeout(() => {
        handleClose();
        setUploading(false);
      }, 2000);
    },
    onError: (error) => {
      console.log("error: ", error);
      toast.error("Something went wrong.");
    },
  });

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const file = e.dataTransfer.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("DocumentUploaded", file);
      formData.append(
        "ChatId",
        "new" === chatType
          ? "00000000-0000-0000-0000-000000000000"
          : selectedChat
      );
      formData.append("IsFocusNotShared", isFocused);
      setSelectedFile(file);
      setUploading(!uploading);
      addFile.mutate(formData);
    }
  };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("DocumentUploaded", file);
    formData.append(
      "ChatId",
      "new" === chatType ? "00000000-0000-0000-0000-000000000000" : selectedChat
    );
    formData.append("IsFocusNotShared", isFocused);
    if (file) {
      setSelectedFile(file);
      setUploading(!uploading);
      addFile.mutate(formData);
    }
  };

  const handleFileButtonClick = () => {
    document.getElementById("fileInput").click();
  };

  const preventDefault = (e) => {
    e.preventDefault();
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <div className="modal-content">
        <div className="modal-header">
          <h1
            className="modal-title fs-5 add-document-title"
            style={{ color: cachedBranding?.page_TextColor }}
          >
            Add Document
          </h1>
          <img
            src="/images/close-modal.svg"
            className="cursor-pointer"
            onClick={handleClose}
          />
        </div>
        <div className="modal-body">
          <div className="row px-3 pt-3 pb-0">
            <div className="form-check col">
              <input
                className="form-check-input"
                type="radio"
                checked={isFocused}
                onChange={() => setIsFocused(true)}
              />
              <label
                className="form-check-label fs-6 fw-medium add-document-label"
                htmlFor="flexRadioDefault1"
                style={{ color: cachedBranding?.page_TextColor }}
              >
                Focus Mode
              </label>
            </div>
            <div className="form-check col">
              <input
                className="form-check-input"
                type="radio"
                checked={!isFocused}
                onChange={() => setIsFocused(false)}
                disabled={!isInstitute}
              />
              <label
                className="form-check-label fs-6 fw-medium add-document-label"
                htmlFor="flexRadioDefault1"
                style={{ color: cachedBranding?.page_TextColor }}
              >
                Shared Mode
              </label>
            </div>
          </div>
          <div>
            <span
              className="focus-mode-text"
              style={{
                color: cachedBranding?.page_TextColor,
              }}
            >
              <img src="/images/info.svg" />{" "}
              <span>
                {isFocused
                  ? "Upon uploading a document, the chat responses will exclusively derive from the content of the uploaded file, creating a focused interaction mode. Subsequently removing the file restores the conversation to draw responses from the original institutional data."
                  : "Upon uploading a document, chat responses will integrate content from both the uploaded file and the initial institute data (managed materials data). The interaction mode will collaboratively engage with both files; however, upon removal of the uploaded document, responses will exclusively draw from the institute data."}
              </span>
            </span>
          </div>
        </div>
        <div className="upload-border d-flex justify-content-between mb-2 mx-3">
          <div className="d-flex w-75">
            <img src="/images/upload-file.svg" />
            <div
              className="d-flex justify-content-center flex-column mx-3"
              onDragOver={preventDefault}
              onDrop={handleDrop}
            >
              <h6 style={{ color: cachedBranding?.page_TextColor }}>
                Drag and Drop or Upload
              </h6>
            </div>
          </div>
          {addFile.isLoading || uploading ? (
            <SpinnerCircle />
          ) : (
            <button
              className="main-btn w-25"
              onClick={handleFileButtonClick}
              style={{ backgroundColor: cachedBranding?.page_ButtonColor }}
            >
              Upload
            </button>
          )}
          <input
            type="file"
            id="fileInput"
            accept=".pdf, .doc, .docx"
            onChange={handleFileSelect}
            style={{ display: "none" }}
          />
        </div>
      </div>
    </Modal>
  );
}

export default AddDocumentModal;
