import React from "react";

function MaxResponse({ limit, setLimit }) {
  return (
    <div className="p-5">
      <p className="fw-semibold">
        Set a limit on the number of tokens per response
      </p>
      <div className="cont-wmessage">
        <img src="/images/notice.png" alt="vector" />
        <p className="limit-message">
          The API supports a maximum of 4000 tokens shared between the prompt
          (including system message, examples, message history, and user query)
          and the model's response. One token is roughly 4 characters for
          typical English text.
        </p>
      </div>
      <div className="mt-2 row">
        <div className="col-11">
          <input
            type="range"
            className="form-range mt-2"
            value={limit}
            onChange={(e) => setLimit(e.target.value)}
          />
        </div>
        <div className="col-1">
          <input
            className="institute-limit form-control"
            value={limit}
            onChange={(e) => setLimit(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
}

export default MaxResponse;
