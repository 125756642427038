import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "constants";
import { isEmpty } from "lodash";
import React from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { cleanToken } from "utils";

function ConfirmDeleteModal({
  show,
  handleClose,
  selectedUsers,
  setSelectedUsers,
  selectedUser,
  refetchUsers
}) {
  const deleteUser = useMutation({
    mutationFn: (fields) => {
      return axios.delete(
        API_URL + `/User/DeleteUser?userId=${fields?.userId}`,
        {
          headers: {
            Authorization: `Bearer ${cleanToken(
              localStorage.getItem("token")
            )}`,
          },
        }
      );
    },
    onSuccess: async (data) => {
      handleClose();
      toast.success("User successfully deleted")
    },
    onError: (error) => {
      console.log("error: ", error);
    },
  });

  const deleteMultipleUsers = useMutation({
    mutationFn: (data) => {
      return axios.post(API_URL + `/User/BulkDeleteUser`, data, {
        headers: {
          Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
        },
      });
    },
    onSuccess: async (data) => {
      handleClose();
      setSelectedUsers([]);
      refetchUsers()
      toast.success("Users successfully deleted")
    },
    onError: (error) => {
      console.log("error: ", error);
      setSelectedUsers([]);
    },
  });

  const handleSubmit = () => {
    if (!isEmpty(selectedUsers)) {
      const formattedUsers = selectedUsers.map((user) => user.toString());
      deleteMultipleUsers.mutate(formattedUsers);
    } else {
      deleteUser.mutate({
        userId: selectedUser?.userId,
      });
    }
  };


  return (
    <Modal show={show} onHide={handleClose} animation={false} centered>
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Attention! </h4>
          <div className="modal-option">
            <button
              type="button"
              className="btn"
              data-bs-dismiss="modal"
              onClick={handleClose}
            >
              <img src="/images/close.svg" />
            </button>
          </div>
        </div>
        <div className="modal-body editProfile justify-content-around flex-column align-items-center text-center">
          <div className="">
            <img src="/images/warning.png" className=" mb-2" />
          </div>
          User’s data will be removed, <br /> Do you want to proceed?
        </div>
        <div className="modal-footer border-0 justify-content-center">
          <button
            type="button"
            className="btn cancel-btn rounded-pill w-25 px-4"
            onClick={handleClose}
          >
            No
          </button>
          <button
            type="button"
            className="btn danger-btn rounded-pill w-25 px-4"
            onClick={() => handleSubmit()}
          >
            Yes
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default ConfirmDeleteModal;
