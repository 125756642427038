import React, { useState, useEffect } from "react";

function Session({ cachedBranding }) {
  return (
    <div>
      <div className="normal-card">
        <div className="px-3">
          <p
            className="mb-0 policies-label"
            style={{ color: cachedBranding?.page_TextColor }}
          >
            One-time Password
          </p>
          <p
            className="mb-0 policies-label-sub"
            style={{ color: cachedBranding?.page_TextColor }}
          >
            Sessions expire after
          </p>
          <div className="row mt-3">
            <div className="col-6">
              <p
                className="mb-3 policies-label-sub"
                style={{ color: cachedBranding?.page_TextColor }}
              >
                Fixed Time
              </p>
              <div className="switch-ai-g">
                <div className="me-2">
                  <label className="timeout-label">
                    Session timeout by fixed time value
                  </label>
                  <div>
                    <input className="timeout-input" type="number" />
                  </div>
                </div>
                <div>
                  <label className="timeout-label">
                    Session timeout by fixed time unit
                  </label>
                  <div>
                    <input className="timeout-input" type="number" />
                  </div>
                </div>
              </div>
              <div className="cont-wmessage">
                <img src="/images/info.svg" alt="vector" />
                <p
                  className="w-message"
                  style={{ color: cachedBranding?.page_TextColor }}
                >
                  Use 0 for “Never Timeout"
                </p>
              </div>
            </div>
            <div className="col-6">
              <p
                className="mb-3 policies-label-sub"
                style={{ color: cachedBranding?.page_TextColor }}
              >
                Inactivity
              </p>
              <div className="switch-ai-g">
                <div className="me-2">
                  <label className="timeout-label">
                    Session timeout by inactive value
                  </label>
                  <div>
                    <input className="timeout-input" type="number" />
                  </div>
                </div>
                <div>
                  <label className="timeout-label">
                    Session timeout by inactive unit
                  </label>
                  <div>
                    <input className="timeout-input" type="number" />
                  </div>
                </div>
              </div>
              <div className="cont-wmessage">
                <img src="/images/info.svg" alt="vector" />
                <p
                  className="w-message"
                  style={{ color: cachedBranding?.page_TextColor }}
                >
                  Use 0 for “Never Timeout"
                </p>
              </div>
            </div>
          </div>
          <div className="switch-ai-g mt-3">
            <p
              className="no-margin"
              style={{ color: cachedBranding?.page_TextColor }}
            >
              Give users the “Keep me sign in” option on the login screen
            </p>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                // checked={isChecked}
                // onChange={() => setIsChecked(!isChecked)}
              />
            </div>
          </div>
          <div className="cont-wmessage">
            <img src="/images/info.svg" alt="vector" />
            <p
              className="w-message"
              style={{ color: cachedBranding?.page_TextColor }}
            >
              When the user selects this option their OneLogin session permits
              after the browser closes.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Session;
