import React, { useState } from "react";
import axios from "axios";
import { API_URL } from "constants";
import { cleanToken } from "utils";
import { useMutation } from "@tanstack/react-query";

function TableChatSettingsBody({
  chatCategorization,
  refetchChatCategorization,
  startIndex,
  categories,
  refetchCategories,
  cachedBranding,
}) {
  const [selectedChatId, setSelectedChatID] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  const categorizeChatMessage = useMutation({
    mutationFn: () => {
      return axios.post(
        API_URL +
          `/api/Category/CategorizeChat/${selectedCategory}/${selectedChatId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${cleanToken(
              localStorage.getItem("token")
            )}`,
          },
        }
      );
    },
    onSuccess: () => {
      refetchChatCategorization();
      selectedChatId("");
    },
    onError: (error) => {
      console.log("error: ", error);
    },
  });

  const handleCategorySelect = (e, chatId) => {
    setSelectedChatID(chatId);
    setSelectedCategory(e.target.value);

    categorizeChatMessage.mutate();
  };

  return (
    <tbody>
      {chatCategorization &&
        chatCategorization?.data?.items?.map((message) => (
          <tr key={message?.chatId}>
            <td
              className=" table-details col-6  px-5"
              style={{ color: cachedBranding?.page_TextColor }}
            >
              {message?.chatQuestion}
            </td>
            <td className="tb-rw-messages col-6">
              {message?.categoriesId ? (
                <select
                  onChange={(e) => handleCategorySelect(e, message?.chatId)}
                  className="scrollable-select border border-0 p-1 w-50 "
                  style={{
                    fontSize: "13px",
                    textAlign: "center",
                    color: cachedBranding?.page_TextColor,
                  }}
                  defaultValue="selectcategory"
                >
                  <option value="selectcategory" disabled>
                    {message?.categoryText}
                  </option>
                  {categories?.data &&
                    categories?.data?.map((category) => (
                      <option
                        key={category?.categoriesId}
                        value={category?.categoriesId}
                      >
                        {category?.categoryText}
                      </option>
                    ))}
                </select>
              ) : (
                <select
                  onChange={(e) => handleCategorySelect(e, message?.chatId)}
                  className="scrollable-select border border-0 p-1 w-50 "
                  style={{
                    fontSize: "13px",
                    textAlign: "center",
                    color: "#8A8A8A",
                  }}
                  defaultValue="selectcategory"
                >
                  <option value="selectcategory" disabled>
                    Select Category
                  </option>
                  {categories?.data &&
                    categories?.data?.map((category) => (
                      <option
                        key={category?.categoriesId}
                        value={category?.categoriesId}
                      >
                        {category?.categoryText}
                      </option>
                    ))}
                </select>
              )}
            </td>
          </tr>
        ))}
    </tbody>
  );
}

export default TableChatSettingsBody;
