import React from "react";
import { useNavigate } from "react-router-dom";

function Nav({ className, overlapClassName }) {
  const navigate = useNavigate();

  const handleLogoutUser = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  return (
    <div className="header-admin">
      <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
        <a
          href="/"
          className="d-flex align-items-center pb-3 mb-4 d-flex pb-3 mb-4 text-decoration-none px-3 mx-5"
        >
          <img src="/images/bot.svg" />
        </a>
        <div className="d-flex justify-content-between flex-column h-75 w-100">
          <ul
            className="nav nav-admin-pills flex-column mb-0 align-items-center align-items-sm-start"
            id="menu"
          >
            <li className="nav-admin-item menu-admin-list active-admin">
              <a className=" nav-link align-middle p-0 d-flex align-items-center justify-content-center cursor-pointer">
                <img
                  className="dark-filter mx-2"
                  src="/images/user-ico.svg"
                  width="30"
                  height="30"
                />{" "}
                <span className="ms-1 d-none d-sm-inline">Customer</span>
              </a>
            </li>
          </ul>
          <div className="dropdown pb-4">
            <a
              href="#"
              className="d-flex align-items-center text-white text-decoration-none dropdown-toggle"
              id="dropdownUser1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="/images/admin.svg"
                alt="hugenerd"
                width="30"
                height="30"
                className="rounded-circle"
              />
              <span className="d-none d-sm-inline mx-1">Admin</span>
            </a>
            <ul
              className="dropdown-menu dropdown-menu-dark text-small shadow"
              aria-labelledby="dropdownUser1"
            >
              <li>
                <a
                  className="dropdown-item cursor-pointer"
                  onClick={() => handleLogoutUser()}
                >
                  Sign out
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Nav;
