import React, { useEffect, useState } from "react";
import EmptyChat from "./EmptyChat";
import Chat from "./Chat";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { cleanToken, generateUUID } from "utils";
import { API_URL } from "constants";
import { isEmpty } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCirclePlus,
  faL,
  faMicrophone,
} from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";
import { OverlayTrigger, Popover } from "react-bootstrap";
import AddDocumentModal from "./AddDocumentModal";

function ChatBox({
  chatType,
  chat,
  setChatType,
  setSelectedChat,
  selectedChat,
  refetchChat,
  group,
  groupInit,
  newGroup,
  chatSettings,
  isInstitute,
  isQna,
  cachedBranding,
  refetchFavorites,
  refetchIndividualChats,
  refetchInstituteChats,
  refetchInstituteChatsGroup,
  refetchMaterialChatsGroup,
  handleSelect,
  refetchGroups,
  selectedTab,
  documentId,
  setDocumentId,
}) {
  const [chatText, setChatText] = useState("");
  const [tempText, setTempText] = useState("");
  const [newId, setNewId] = useState("");
  const [tempfields, setTempFields] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  const [showAddDocumentModal, setShowAddDocumentModal] = useState(false);
  const [showPopover, setShowPopover] = useState(false);

  useEffect(() => {
    if ("new" === chatType) {
      setNewId(generateUUID());
    }
  }, [chatType]);

  useEffect(() => {
    if (newId) {
      setSelectedChat(newId);
    }
  }, [newId]);

  useEffect(() => {
    if ("new" === chatType && "group" === selectedTab && isEmpty(groupInit)) {
      setIsDisabled(true);
      setChatText("");
    } else {
      setIsDisabled(false);
    }
  }, [selectedTab, groupInit, chatType]);

  const sendMessage = useMutation({
    mutationFn: (fields) => {
      setTempFields(fields);
      return axios.post(API_URL + `/Chat/ChatGPT`, fields, {
        headers: {
          Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
        },
      });
    },
    onSuccess: async () => {
      if ("new" === chatType) {
        setChatType("old");
        await setSelectedChat(newId);
        setTimeout(() => {
          refetchChat();
        }, 50);
      }
      setTimeout(() => {
        refetchChat();
      }, 50);
      setChatText("");
      setTempText("");
      refetchIndividualChats();
      refetchGroups();
    },
    onError: (error) => {
      toast.error("Something went wrong! Please try again.");
      setSelectedChat("");
      setChatType("new");
      setChatText("");
      setTempText("");
      console.log("error: ", error);
    },
  });

  const regenarateMessage = useMutation({
    mutationFn: () => {
      return axios.post(
        API_URL + `/Chat/ChatGPT`,
        {
          chatId:
            "new" === chatType ? newId : !isEmpty(group) ? group : selectedChat,
          chatType: 0,
          isInternet: true,
          oldPrompt:
            "new" === chatType
              ? "0"
              : chat?.data?.cd[chat?.data?.cd?.length - 1]?.text,
          prompt: chat?.data?.cd[chat?.data?.cd?.length - 2]?.text,
        },
        {
          headers: {
            Authorization: `Bearer ${cleanToken(
              localStorage.getItem("token")
            )}`,
          },
        }
      );
    },
    onSuccess: async (data) => {
      setTempFields(tempfields);
      if ("new" === chatType) {
        setChatType("old");
        setSelectedChat(newId);
      }
      refetchChat();

      setChatText("");
      setTempText("");
    },
    onError: (error) => {
      toast.error("Something went wrong! Please try again.");
      setSelectedChat("");
      setChatType("new");
      setChatText("");
      setTempText("");
      setTempFields({});
      console.log("error: ", error);
    },
  });

  const regenarate = () => {
    if (isInstitute) {
      regenarateInstitute.mutate();
    } else if (isQna) {
      regenarateQna.mutate();
    } else {
      regenarateMessage.mutate();
    }
  };

  const regenarateQna = useMutation({
    mutationFn: (text) => {
      setTempFields(text);
      return axios.post(
        group?.length === 0
          ? API_URL +
              `/QNA/ChatWithData/${selectedChat}/${
                chatSettings?.activeProjectId
              }/${chat?.data?.cd[chat?.data?.cd?.length - 2]?.text}`
          : API_URL +
              `/QNA/ChatWithData/${selectedChat}/${
                chatSettings?.activeProjectId
              }/${
                chat?.data?.cd[chat?.data?.cd?.length - 2]?.text
              }?GroupId=${groupInit}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${cleanToken(
              localStorage.getItem("token")
            )}`,
          },
        }
      );
    },
    onSuccess: async (data) => {
      setTempFields(tempfields);
      if ("new" === chatType) {
        setChatType("old");
        setSelectedChat(newId);
      }
      refetchChat();

      setChatText("");
      setTempText("");
    },
    onError: (error) => {
      toast.error("Something went wrong! Please try again.");
      setSelectedChat("");
      setChatType("new");
      setChatText("");
      setTempText("");
      setTempFields({});
      console.log("error: ", error);
    },
  });

  const regenarateInstitute = useMutation({
    mutationFn: () => {
      return axios.post(
        group?.length === 0
          ? API_URL +
              `/Azure/ChatWithData/${selectedChat}/${
                chatSettings?.activeProjectId
              }/${chat?.data?.cd[chat?.data?.cd?.length - 2]?.text}`
          : API_URL +
              `/Azure/ChatWithData/${selectedChat}/${
                chatSettings?.activeProjectId
              }/${
                chat?.data?.cd[chat?.data?.cd?.length - 2]?.text
              }?GroupId=${groupInit}`,
        {
          headers: {
            Authorization: `Bearer ${cleanToken(
              localStorage.getItem("token")
            )}`,
          },
        }
      );
    },
    onSuccess: async (data) => {
      setTempFields(tempfields);
      if ("new" === chatType) {
        setChatType("old");
        setSelectedChat(newId);
      }
      refetchChat();

      setChatText("");
      setTempText("");
    },
    onError: (error) => {
      toast.error("Something went wrong! Please try again.");
      setSelectedChat("");
      setChatType("new");
      setChatText("");
      setTempText("");
      setTempFields({});
      console.log("error: ", error);
    },
  });

  const chatWithData = useMutation({
    mutationFn: (text) => {
      setTempFields(text);
      return axios.post(
        group?.length === 0
          ? API_URL +
              `/QNA/ChatWithData/${
                "new" === chatType
                  ? "00000000-0000-0000-0000-000000000000"
                  : selectedChat
              }/${chatSettings?.activeProjectId}/${text}`
          : API_URL +
              `/QNA/ChatWithData/${
                "new" === chatType
                  ? "00000000-0000-0000-0000-000000000000"
                  : selectedChat
              }/${chatSettings?.activeProjectId}/${text}?GroupId=${groupInit}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${cleanToken(
              localStorage.getItem("token")
            )}`,
          },
        }
      );
    },
    onSuccess: async (data) => {
      setChatText("");
      setTempText("");
      if ("new" === chatType) {
        setChatType("old");
        setSelectedChat(data.data.newID);
        refetchChat();
      }
      refetchChat();
    },
    onError: (error) => {
      toast.error("Something went wrong! Please try again.");
      setSelectedChat("");
      setChatType("new");
      console.log("error: ", error);
      setChatText("");
      setTempText("");
    },
  });

  const chatWithDataInstitute = useMutation({
    mutationFn: (text) => {
      setTempFields(text);
      return axios.post(
        group?.length === 0
          ? API_URL +
              `/Azure/ChatWithData/${
                "new" === chatType
                  ? "00000000-0000-0000-0000-000000000000"
                  : selectedChat
              }/${chatSettings?.activeIndexerId}/${text}`
          : API_URL +
              `/Azure/ChatWithData/${
                "new" === chatType
                  ? "00000000-0000-0000-0000-000000000000"
                  : selectedChat
              }/${chatSettings?.activeIndexerId}/${text}?GroupId=${groupInit}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${cleanToken(
              localStorage.getItem("token")
            )}`,
          },
        }
      );
    },
    onSuccess: async (data) => {
      console.log(data);
      setChatText("");
      setTempText("");
      if ("new" === chatType) {
        setChatType("old");
        setSelectedChat(data.data.newID);
        setTimeout(() => {
          refetchChat();
        }, 50);
      }
      setTimeout(() => {
        refetchChat();
      }, 50);
    },
    onError: (error) => {
      toast.error("Something went wrong! Please try again.");
      setSelectedChat("");
      setChatType("new");
      console.log("error: ", error);
      setChatText("");
      setTempText("");
    },
  });

  const chatWithDocument = useMutation({
    mutationFn: (fields) => {
      setTempFields(fields);
      if (!isEmpty(group)) {
        return axios.post(
          API_URL +
            `/Azure/ChatWithDocument/${
              "new" === chatType
                ? "00000000-0000-0000-0000-000000000000"
                : !isEmpty(group)
                ? group
                : selectedChat
            }/${documentId}/${tempText}/${groupInit}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${cleanToken(
                localStorage.getItem("token")
              )}`,
            },
          }
        );
      } else {
        return axios.post(
          API_URL +
            `/Azure/ChatWithDocument/${
              "new" === chatType
                ? "00000000-0000-0000-0000-000000000000"
                : !isEmpty(group)
                ? group
                : selectedChat
            }/${documentId}/${tempText}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${cleanToken(
                localStorage.getItem("token")
              )}`,
            },
          }
        );
      }
    },
    onSuccess: async () => {
      if ("new" === chatType) {
        setChatType("old");
        await setSelectedChat(newId);
      }
      refetchChat();

      setChatText("");
      setTempText("");
      refetchIndividualChats();
      refetchGroups();
    },
    onError: (error) => {
      toast.error("Something went wrong! Please try again.");
      setSelectedChat("");
      setChatType("new");
      setChatText("");
      setTempText("");
      console.log("error: ", error);
    },
  });

  const handleSubmit = () => {
    setTempText(chatText);
    setChatText("");
    if (!isEmpty(chatText)) {
      if (documentId) {
        chatWithDocument.mutate();
      } else if (isInstitute) {
        chatWithDataInstitute.mutate(chatText);
      } else if (isQna) {
        chatWithData.mutate(chatText);
      } else {
        if (!isEmpty(group)) {
          sendMessage.mutate({
            chatId:
              "new" === chatType
                ? newId
                : !isEmpty(group)
                ? group
                : selectedChat,
            chatType: 0,
            isInternet: true,
            groupId: groupInit,
            oldPrompt:
              "new" === chatType
                ? "0"
                : chat?.data?.cd[chat?.data?.cd?.length - 1]?.text,
            prompt: chatText,
          });
        } else {
          sendMessage.mutate({
            chatId:
              "new" === chatType
                ? newId
                : !isEmpty(group)
                ? group
                : selectedChat,
            chatType: 0,
            isInternet: true,
            oldPrompt:
              "new" === chatType
                ? "0"
                : chat?.data?.cd[chat?.data?.cd?.length - 1]?.text,
            prompt: chatText,
          });
        }
      }
    }
  };

  const handleSubmitSuggested = (message) => {
    setChatType("old");

    if (!isEmpty(group)) {
      sendMessage.mutate({
        chatId: "new" === chatType ? newId : selectedChat,
        chatType: 0,
        isInternet: true,
        groupId: groupInit,
        oldPrompt:
          "new" === chatType
            ? "0"
            : chat?.data?.cd[chat?.data?.cd?.length - 1]?.text,
        prompt: message,
      });
    } else {
      sendMessage.mutate({
        chatId: "new" === chatType ? newId : selectedChat,
        chatType: 0,
        isInternet: true,
        oldPrompt:
          "new" === chatType
            ? "0"
            : chat?.data?.cd[chat?.data?.cd?.length - 1]?.text,
        prompt: message,
      });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmit();
    }
  };

  return (
    <div
      className={`${
        "new" === chatType
          ? "col-7 col-md-7 col-lg-8 col-xl-9 chat-area"
          : "col-7 col-md-7 col-lg-8 col-xl-9 px-0 bg-white"
      }`}
    >
      <div className="chat-content">
        {"new" === chatType && isEmpty(tempText) ? (
          <EmptyChat
            handleSubmitSuggested={handleSubmitSuggested}
            cachedBranding={cachedBranding}
          />
        ) : (
          <Chat
            handleSelect={handleSelect}
            chat={chat?.data}
            refetchChat={refetchChat}
            selectedChat={selectedChat}
            setSelectedChat={setSelectedChat}
            tempText={tempText}
            refetchFavorites={refetchFavorites}
            refetchIndividualChats={refetchIndividualChats}
            refetchInstituteChats={refetchInstituteChats}
            refetchInstituteChatsGroup={refetchInstituteChatsGroup}
            refetchMaterialChatsGroup={refetchMaterialChatsGroup}
            cachedBranding={cachedBranding}
            setDocumentId={setDocumentId}
          />
        )}
        <div
          className="chat-footer"
          style={{ position: "new" === chatType ? "absolute" : "initial" }}
        >
          {(chat?.data?.showTextBox || "new" !== chatType) &&
            !isInstitute &&
            !isQna && (
              <div className="regenarate">
                <button
                  className="btn regenarate-btn"
                  // onClick={() => regenarateMessage.mutate()}
                  onClick={() => regenarate()}
                >
                  <img src="/images/regenerate.svg" />
                  {"\u00A0"}
                  {"\u00A0"}Regenerate Response
                </button>
              </div>
            )}
          {(chat?.data?.showTextBox || "new" == chatType) && (
            <div className="chatbox-input rounded-pill mt-3">
              {/* <OverlayTrigger
                trigger="click"
                key="top"
                placement="top"
                show={showPopover}
                onToggle={() => setShowPopover(!showPopover)}
                overlay={
                  <Popover id={`popover-positioned-top`}>
                    <Popover.Body>
                      <div
                        className="row cursor-pointer"
                        onClick={() => {
                          setShowAddDocumentModal(true);
                          setShowPopover(false);
                        }}
                      >
                        <div className="col-6">
                          <span className="text-nowrap">Document</span>
                        </div>
                        <div className="col-6 text-end">
                          <img src="/images/doc.svg" />
                        </div>
                      </div>
                    </Popover.Body>
                  </Popover>
                }
              >
                {!isQna ? (
                  "new" === chatType ? (
                    <></>
                  ) : (
                    <button className="btn" type="file">
                      <FontAwesomeIcon
                        icon={faCirclePlus}
                        fontSize={30}
                        color={
                          !isEmpty(cachedBranding?.page_ButtonColor)
                            ? cachedBranding?.page_ButtonColor
                            : "#0844e1"
                        }
                      />
                    </button>
                  )
                ) : (
                  <></>
                )}
              </OverlayTrigger> */}

              <>
                <input
                  type="text"
                  placeholder="Ask Anything!"
                  value={chatText}
                  onChange={(e) => setChatText(e.target.value)}
                  onKeyDown={handleKeyDown}
                  disabled={isDisabled}
                  className="ps-3"
                />
                <button
                  className="btn send-btn"
                  type="button"
                  onClick={() => handleSubmit()}
                  style={{ backgroundColor: cachedBranding?.page_ButtonColor }}
                >
                  Send <img src="/images/send.svg" />
                </button>
              </>

              <button className="btn mic mx-2" type="button">
                <FontAwesomeIcon
                  icon={faMicrophone}
                  fontSize={25}
                  color={
                    !isEmpty(cachedBranding?.page_ButtonColor)
                      ? cachedBranding?.page_ButtonColor
                      : "#0844e1"
                  }
                />
              </button>
            </div>
          )}
        </div>
      </div>
      <AddDocumentModal
        show={showAddDocumentModal}
        handleClose={() => setShowAddDocumentModal(false)}
        cachedBranding={cachedBranding}
        chatType={chatType}
        newId={newId}
        selectedChat={selectedChat}
        setChatType={setChatType}
        setSelectedChat={setSelectedChat}
        refetchChat={refetchChat}
        setChatText={setChatText}
        setTempText={setTempText}
        refetchIndividualChats={refetchIndividualChats}
        refetchGroups={refetchGroups}
        isInstitute={isInstitute}
      />
    </div>
  );
}

export default ChatBox;
