import React from "react";
import "bootstrap/dist/css/bootstrap.css";

function CompanyName({ companyName }) {
  return (
    <div className="row pt-3 mb-3 d-flex align-item-center">
      <div className="col-6">
        <h3 className="fw-semibold">{companyName}</h3>
      </div>
      <div className="col-6 d-flex justify-content-end">
        <button className="btn main-admin-btn rounded-pill fw-medium px-3 text-white">
          Assume Owner
        </button>
        <button className="btn second-outline rounded-pill fw-medium px-3 mx-3">
          <img className="pe-2 dark-filter" src="/images/lock.svg" />
          Lock
        </button>
        <button className="btn danger-outline rounded-pill fw-medium px-3">
          <img className="pe-2 danger-filter" src="/images/delete.svg" />
          Delete
        </button>
      </div>
    </div>
  );
}

export default CompanyName;
