import React, { useState } from "react";
import AddTokensModal from "./AddTokensModal";
import { AddMoreModal } from "components/Custom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { isEmpty } from "lodash";

function BalanceCard({ tokens, cachedBranding }) {
  const [show, setShow] = useState(false);
  const [showAddMoreModal, setShowAddMoreModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState();

  const handleClose = () => {
    setShow(false);
    setSelectedPlan();
  };

  return (
    <div className="normal-card sub-token">
      <span style={{ color: cachedBranding?.page_TextColor }}>
        Token Balance
      </span>
      <div className="total-tokens">
        <div className="result">
          <img src="/images/token-ico.svg" />
          <h3
            className="mb-0"
            style={{ color: cachedBranding?.page_TextColor }}
          >
            {tokens}
          </h3>
        </div>
        <button type="button" className="btn" onClick={() => setShow(true)}>
          <FontAwesomeIcon
            icon={faCirclePlus}
            fontSize={30}
            color={
              !isEmpty(cachedBranding?.page_ButtonColor)
                ? cachedBranding?.page_ButtonColor
                : "#0844e1"
            }
          />
        </button>
      </div>
      <AddTokensModal
        show={show}
        handleClose={handleClose}
        handleAddMore={() => (setShowAddMoreModal(true), setShow(false))}
        setSelectedPlan={setSelectedPlan}
        selectedPlan={selectedPlan}
        cachedBranding={cachedBranding}
      />
      <AddMoreModal
        show={showAddMoreModal}
        handleClose={() => setShowAddMoreModal(false)}
      />
    </div>
  );
}

export default BalanceCard;
