import React from "react";

function DataCardTable({ responses, cachedBranding }) {
  return (
    <div className="table-result">
      <div className="table-responsive-sm  minCard">
        <table className="table table-striped table-borderless ">
          <thead>
            <tr>
              <th
                scope="col"
                className="table-name"
                style={{ color: cachedBranding?.page_TextColor }}
              >
                Name
              </th>
              <th
                scope="col"
                className="table-name"
                style={{ color: cachedBranding?.page_TextColor }}
              >
                Response
              </th>
              <th
                scope="col"
                className="table-name text-nowrap"
                style={{ color: cachedBranding?.page_TextColor }}
              >
                Token Count
              </th>
            </tr>
          </thead>
          <tbody>
            {responses.map((response, index) => (
              <tr key={index}>
                <th
                  scope="row"
                  className="table-details"
                  style={{ color: cachedBranding?.page_TextColor }}
                >
                  {response?.firstName}
                </th>
                <td
                  className="ques-res"
                  style={{ color: cachedBranding?.page_TextColor }}
                >
                  {response?.text}
                </td>
                <td
                  className="table-details"
                  style={{
                    color: cachedBranding?.page_TextColor,
                    textAlign: "center",
                  }}
                >
                  {response?.botResponceToken}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default DataCardTable;
