import { Sidebar } from "components";
import { PageTitle, RoundedButton, TabButton } from "components/Custom";
import {
  BotSettings,
  ChatboxSettings,
  LoginSettings,
  MainPageSettings,
} from "components/Settings";
import { BrandingModal } from "components/WalkThrough";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useFetchCompanyBranding } from "hooks/branding";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "constants";
import { cleanToken } from "utils";
import { isEmpty, set } from "lodash";
import toast from "react-hot-toast";

function Branding() {
  const location = useLocation();
  const queryClient = useQueryClient();
  const cachedBranding = queryClient.getQueryData(["branding"])?.data;
  const [showBranding, setShowBranding] = useState(
    location?.state?.showModal || false
  );
  const navigate = useNavigate();
  const {
    data: branding,
    refetch: refetchBranding,
    isFetching,
  } = useFetchCompanyBranding(navigate);
  const [botName, setBotName] = useState("");
  const [botColor, setBotColor] = useState("#020a58");
  const [botChatColor, setBotChatColor] = useState("#020a58");
  const [userChatColor, setUserChatColor] = useState("#020a58");
  const [topBannerColor, setTopBannerColor] = useState("#dde0f8");
  const [fontStyle, setFontStyle] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("#F3F8FD");
  const [pattern, setPattern] = useState("no");
  const [welcomeMessage, setWelcomeMessage] = useState("");
  const [firstField, setFirstField] = useState(0);
  const [loginBackgroundColor, setLoginBackgroundColor] = useState("#F3F8FD");
  const [buttonColor, setButtonColor] = useState("#0844E1");
  const [loginPattern, setLoginPattern] = useState("no");
  const [addResetPassword, setAddResetPassword] = useState(false);
  const [addSupport, setAddSupport] = useState(false);
  const [addTermsAndConditions, setAddTermsAndConditions] = useState(false);
  const [selected, setSelected] = useState("login");
  const [sidebarPrimaryColor, setSidebarPrimaryColor] = useState("#020a58");
  const [sidebarSecondaryColor, setSidebarSecondaryColor] = useState("#050958");
  const [sidebarTextColor, setSidebarTextColor] = useState("#fff");
  const [pageBackgroundColor, setPageBackgroundColor] = useState("#F3F8FD");
  const [pageButtonColor, setPageButtonColor] = useState("#0844E1");
  const [pageFontStyle, setPageFontStyle] = useState("");
  const [pageTextColor, setPageTextColor] = useState("#000000");
  const [logo, setLogo] = useState(null);
  const [bot, setBot] = useState(null);
  const [tempLogo, setTempLogo] = useState(null);
  const [tempBot, setTempBot] = useState(null);
  const [supportText, setSupportText] = useState("");
  const [termsAndConditions, setTermsAndConditions] = useState("");
  const [bubbleColor, setBubbleColor] = useState("#f5f5f5");
  const [botIconContentType, setBotIconContentType] = useState("");
  const [selectedButtonColor, setSelectedButtonColor] = useState("#0844E1");
  const [mainLoginBackgroundColor, setMainLoginBackgroundColor] =
    useState("#ffffff");

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        toast.error("Image size exceeds the maximum limit of 5MB.");
      } else {
        setTempLogo(e.target.files[0]);
      }
    }
  };

  const handleBotChange = (e) => {
    const file = e?.target?.files[0] || e;
    if (file && file.size > 1 * 1024 * 1024) {
      toast.error("Image size exceeds the maximum limit of 1MB.");
    } else if (file) {
      setTempBot(file);
    }
  };

  useEffect(() => {
    refetchBranding();
  }, []);

  useEffect(() => {
    if (!isEmpty(branding?.data)) {
      setBotName(
        !isEmpty(branding?.data?.botName) ? branding?.data?.botName : ""
      );
      setBotColor(
        !isEmpty(branding?.data?.botColor)
          ? branding?.data?.botColor
          : "#6a5acd"
      );
      setBotChatColor(
        !isEmpty(branding?.data?.botChatColor)
          ? branding?.data?.botChatColor
          : "#6a5acd"
      );
      setUserChatColor(
        !isEmpty(branding?.data?.userChatColor)
          ? branding?.data?.userChatColor
          : "#6a5acd"
      );
      setTopBannerColor(
        !isEmpty(branding?.data?.topBannerColor)
          ? branding?.data?.topBannerColor
          : "#6a5acd"
      );
      setFontStyle(
        !isEmpty(branding?.data?.fontStyle) ? branding?.data?.fontStyle : ""
      );
      setBackgroundColor(
        !isEmpty(branding?.data?.botBackgroundColor)
          ? branding?.data?.botBackgroundColor
          : "#6a5acd"
      );
      setPattern(
        !isEmpty(branding?.data?.loginBackgroundPattern)
          ? branding?.data?.loginBackgroundPattern
          : "no"
      );
      setWelcomeMessage(
        !isEmpty(branding?.data?.welcomeMessage)
          ? branding?.data?.welcomeMessage
          : ""
      );
      setFirstField(
        !isEmpty(branding?.data?.firstField) ? branding?.data?.firstField : 0
      );
      setLoginBackgroundColor(
        !isEmpty(branding?.data?.loginBackgroundColor)
          ? branding?.data?.loginBackgroundColor
          : "#6a5acd"
      );
      setButtonColor(
        !isEmpty(branding?.data?.loginButtonColor)
          ? branding?.data?.loginButtonColor
          : "#6a5acd"
      );
      setLoginPattern(
        !isEmpty(branding?.data?.loginBackgroundPattern)
          ? branding?.data?.loginBackgroundPattern
          : "no"
      );
      setAddResetPassword(branding?.data?.addResetPassword || false);
      setAddSupport(branding?.data?.addSupport || false);
      setAddTermsAndConditions(branding?.data?.addTermsandCondition || false);
      setSidebarPrimaryColor(
        !isEmpty(branding?.data?.sideBar_PrimaryColor)
          ? branding?.data?.sideBar_PrimaryColor
          : "#6a5acd"
      );
      setSidebarSecondaryColor(
        !isEmpty(branding?.data?.sideBar_SecondaryColor)
          ? branding?.data?.sideBar_SecondaryColor
          : "#6a5acd" 
      );
      setSidebarTextColor(
        !isEmpty(branding?.data?.sideBar_TextColor)
          ? branding?.data?.sideBar_TextColor
          : "#6a5acd"
      );
      setPageBackgroundColor(
        !isEmpty(branding?.data?.page_BackgroundColor)
          ? branding?.data?.page_BackgroundColor
          : "#6a5acd"
      );
      setPageButtonColor(
        !isEmpty(branding?.data?.page_ButtonColor)
          ? branding?.data?.page_ButtonColor
          : "#6a5acd"
      );
      setPageFontStyle(
        !isEmpty(branding?.data?.page_FontStyle)
          ? branding?.data?.page_FontStyle
          : "#6a5acd"
      );
      setPageTextColor(
        !isEmpty(branding?.data?.page_TextColor)
          ? branding?.data?.page_TextColor
          : "#6a5acd"
      );
      setBot(
        !isEmpty(branding?.data?.botIcon) ? branding?.data?.botIcon : null
      );
      setLogo(
        !isEmpty(branding?.data?.sideBarLogo)
          ? branding?.data?.sideBarLogo
          : null
      );
      setSupportText(
        !isEmpty(branding?.data?.addSupportText)
          ? branding?.data?.addSupportText
          : ""
      );
      setTermsAndConditions(
        !isEmpty(branding?.data?.termsAndConditions)
          ? branding?.data?.termsAndConditions
          : ""
      );
      setBotIconContentType(
        !isEmpty(branding?.data?.botIconContentType)
          ? branding?.data?.botIconContentType
          : ""
      );
      setSelectedButtonColor(
        !isEmpty(branding?.data?.selectedButtonColor)
          ? branding?.data?.selectedButtonColor
          : ""
      );
      console.log("mainLoginBackgroundColor", branding?.data?.mainLoginBackgroundColor)
      setMainLoginBackgroundColor(
        !isEmpty(branding?.data?.mainLoginBackgroundColor)
          ? branding?.data?.mainLoginBackgroundColor
          : ""
      );
    }
  }, [branding]);

  const addBranding = useMutation({
    mutationFn: (fields) => {
      return axios.post(API_URL + `/Branding/SetCompanyBranding`, fields, {
        headers: {
          Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
        },
      });
    },
    onSuccess: async (data) => {
      toast.success("Saved Successfully");
      console.log("data: ", data);
      refetchBranding();
      window.location.reload();
    },
    onError: (error) => {
      console.log("error: ", error);
      refetchBranding();
    },
  });

  const handleOnClick = () => {
    const formData = new FormData();
    formData.append("BotName", botName);
    formData.append("BotColor", botColor);
    formData.append("BotChatColor", botChatColor);
    formData.append("UserChatColor", userChatColor);
    formData.append("TopBannerColor", topBannerColor);
    formData.append("FontStyle", fontStyle);
    formData.append("BotBackgroundColor", backgroundColor);
    formData.append("WelcomeMessage", welcomeMessage);
    formData.append("LoginBackgroundColor", loginBackgroundColor);
    formData.append("LoginBackgroundPattern", loginPattern);
    formData.append("LoginButtonColor", buttonColor);
    formData.append("AddResetPassword", addResetPassword);
    formData.append("AddSupport", addSupport);
    formData.append("AddTermsandCondition", addTermsAndConditions);
    formData.append("AddMinLength", false);
    formData.append("AddCombination", false);
    console.log(tempBot);
    if (tempBot != null) {
      formData.append("BotIcon", tempBot);
    }
    console.log(tempLogo);
    if (tempLogo != null) {
      formData.append("SideBarLogo", tempLogo);
    }
    // formData.append("SideBarLogo", tempLogo);
    formData.append("SideBar_PrimaryColor", sidebarPrimaryColor);
    formData.append("SideBar_SecondaryColor", sidebarSecondaryColor);
    formData.append("SideBar_TextColor", sidebarTextColor);
    formData.append("Page_FontStyle", pageFontStyle);
    formData.append("Page_BackgroundColor", pageBackgroundColor);
    formData.append("Page_TextColor", pageTextColor);
    formData.append("Page_ButtonColor", pageButtonColor);
    formData.append("AddSupportText", supportText);
    formData.append("TermsAndConditions", termsAndConditions);
    formData.append("BubbleColor", bubbleColor);
    addBranding.mutate(formData);
  };

  return (
    <div
      className="container-fluid"
      style={{
        backgroundColor: cachedBranding?.page_BackgroundColor,
        minHeight: "100vh",
      }}
    >
      <div className="row flex-nowrap">
        <Sidebar
          handleTourClick={() => (setShowBranding(true), window.scrollTo(0, 0))}
          uploadedLogo={logo}
          primaryColor={sidebarPrimaryColor}
          secondaryColor={sidebarSecondaryColor}
          textColor={sidebarTextColor}
        />
        <div className="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-2"></div>
        <div className="col-10 col-sm-9 col-md-9 col-lg-9 col-xl-10 px-sm-5">
          <div className="dashboard">
            <div className="page-title">
              <div className="row w-100 justify-content-between">
                <PageTitle title="Branding" time="29 May 2023 - 4:02 PM" />
                <div className="col-5 col-lg-5 col-xl-4 filter">
                  <div className="add-user">
                    <RoundedButton
                      text="Save"
                      className="main-btn w-25"
                      handleOnClick={handleOnClick}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-5">
            <div className="user-option">
              <nav className="nav nav-pills nav-fill user-opt-btns w-100">
                <TabButton
                  active={"login" === selected ? true : false}
                  text="Login"
                  handleOnClick={() => setSelected("login")}
                />
                <TabButton
                  active={"mainPage" === selected ? true : false}
                  text="Main Page"
                  handleOnClick={() => setSelected("mainPage")}
                />
                <TabButton
                  active={"bot" === selected ? true : false}
                  text="Bot"
                  handleOnClick={() => setSelected("bot")}
                />
              </nav>
            </div>
          </div>
          {"login" === selected ? (
            <LoginSettings
              loginBackgroundColor={loginBackgroundColor}
              buttonColor={buttonColor}
              addResetPassword={addResetPassword}
              addSupport={addSupport}
              addTermsAndConditions={addTermsAndConditions}
              firstField={firstField}
              setFirstField={setFirstField}
              setLoginBackgroundColor={setLoginBackgroundColor}
              loginPattern={loginPattern}
              setLoginPattern={setLoginPattern}
              setButtonColor={setButtonColor}
              setAddResetPassword={setAddResetPassword}
              setAddSupport={setAddSupport}
              setAddTermsAndConditions={setAddTermsAndConditions}
              supportText={supportText}
              setSupportText={setSupportText}
              termsAndConditions={termsAndConditions}
              setTermsAndConditions={setTermsAndConditions}
              bot={bot}
              tempBot={tempBot}
              botIconContentType={botIconContentType}
              mainLoginBackgroundColor={mainLoginBackgroundColor}
              setMainLoginBackgroundColor={setMainLoginBackgroundColor}
            />
          ) : "mainPage" === selected ? (
            <MainPageSettings
              sidebarPrimaryColor={sidebarPrimaryColor}
              setSidebarPrimaryColor={setSidebarPrimaryColor}
              sidebarSecondaryColor={sidebarSecondaryColor}
              setSidebarSecondaryColor={setSidebarSecondaryColor}
              sidebarTextColor={sidebarTextColor}
              setSidebarTextColor={setSidebarTextColor}
              pageBackgroundColor={pageBackgroundColor}
              setPageBackgroundColor={setPageBackgroundColor}
              pageButtonColor={pageButtonColor}
              setPageButtonColor={setPageButtonColor}
              pageFontStyle={pageFontStyle}
              setPageFontStyle={setPageFontStyle}
              pageTextColor={pageTextColor}
              setPageTextColor={setPageTextColor}
              handleImageChange={handleImageChange}
              logo={logo}
              selectedButtonColor={selectedButtonColor}
              setSelectedButtonColor={setSelectedButtonColor}
            />
          ) : (
            <BotSettings
              botName={botName}
              setBotName={setBotName}
              botColor={botColor}
              setBotColor={setBotColor}
              botChatColor={botChatColor}
              setBotChatColor={setBotChatColor}
              userChatColor={userChatColor}
              setUserChatColor={setUserChatColor}
              topBannerColor={topBannerColor}
              setTopBannerColor={setTopBannerColor}
              fontStyle={fontStyle}
              setFontStyle={setFontStyle}
              backgroundColor={backgroundColor}
              setBackgroundColor={setBackgroundColor}
              pattern={pattern}
              setPattern={setPattern}
              welcomeMessage={welcomeMessage}
              setWelcomeMessage={setWelcomeMessage}
              handleBotChange={handleBotChange}
              bot={bot}
              tempBot={tempBot}
              buttonColor={buttonColor}
              setButtonColor={setButtonColor}
              bubbleColor={bubbleColor}
              setBubbleColor={setBubbleColor}
              botIconContentType={botIconContentType}
            />
          )}
        </div>
      </div>
      <BrandingModal
        show={showBranding}
        handleClose={() => setShowBranding(false)}
        handleSubmit={() =>
          navigate("/materials/manage-materials", {
            state: { showModal: true },
          })
        }
        cachedBranding={cachedBranding}
      />
    </div>
  );
}

export default Branding;
