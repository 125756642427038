import React from "react";
import { Modal } from "react-bootstrap";

function CompanyDetails({ show, handleClose, handleSubmit, cachedBranding }) {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="c-plan modal-walkthrough"
      size="lg"
    >
      <div className="modal-content p-0">
        <div className="speech right p-5 m-0">
          <div className="row">
            <div className="col-12 d-flex justify-content-end mb-3">
              <button type="button" className="btn" onClick={handleClose}>
                <img src="/images/close-modal.svg" />
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-6 pe-4">
              <img src="/images/wt-company.svg" />
            </div>
            <div className="col-6 p-0 pe-5">
              <h5 className="fw-semibold">Company Profile</h5>
              <ol style={{ fontSize: "14px" }}>
                <li>Access the Company Profile module. </li>
                <li>Edit your Company’s brief description. </li>
              </ol>
              <div className="d-flex justify-content-end cursor-pointer">
                <a
                  className="btn main-btn mt-4 px-4 cursor-pointer"
                  onClick={handleSubmit}
                  style={{ backgroundColor: cachedBranding?.page_ButtonColor }}
                >
                  Next
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default CompanyDetails;
