import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "../constants";
import { cleanToken } from "utils";

const fetchFolders = async () => {
  return await axios.get(API_URL + `/Material/GetFolders`, {
    headers: {
      Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
    },
  });
};

const fetchMaterialsByFolder = async (folderId) => {
  return await axios.get(
    API_URL + `/Material/GetMaterialByFolder/${folderId}`,
    {
      headers: {
        Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
      },
    }
  );
};

const fetchFolderById = async (folderId) => {
  return await axios.get(API_URL + `/Material/GetFolderById/${folderId}`, {
    headers: {
      Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
    },
  });
};

const fetchQnaByMaterialId = async (materialId) => {
  return await axios.get(
    API_URL + `/Material/GetQnaByMaterialId/${materialId}`,
    {
      headers: {
        Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
      },
    }
  );
};

export const useFetchFolders = () => {
  return useQuery({
    queryKey: ["folders"],
    queryFn: () => fetchFolders(),
    enabled: true,
  });
};

export const useFetchMaterialsByFolder = (folderId) => {
  return useQuery({
    queryKey: ["materials"],
    queryFn: () => fetchMaterialsByFolder(folderId),
    enabled: true,
  });
};

export const useFetchFolderById = (folderId) => {
  return useQuery({
    queryKey: ["folder"],
    queryFn: () => fetchFolderById(folderId),
    enabled: true,
  });
};

export const useFetchQnaByMaterialId = (materialId) => {
  return useQuery({
    queryKey: ["qna"],
    queryFn: () => fetchQnaByMaterialId(materialId),
    enabled: true,
  });
};
