import React from "react";
import { Header, DataCards, CustomerTable, Nav } from "components/Admin";

function CustomerList() {
  const customers = [
    {
      id: 1,
      companyName: "ABC Company",
      contactPerson: "Andrew Williams",
      email: "andrew@.com",
      mobile: "+971 56 572 1234",
      plan: "Tier 1",
      tokens: 3000,
      dateJoined: "Jul 10, 2023",
    },
    {
      id: 2,
      companyName: "CTS Company",
      contactPerson: "John Doe",
      email: "joehn@.com",
      mobile: "+971 56 572 4444",
      plan: "Tier 3",
      tokens: 5000,
      dateJoined: "Jul 19, 2023",
    },
    {
      id: 3,
      companyName: "FIFA",
      contactPerson: "Diego Maradona",
      email: "dam@.com",
      mobile: "+971 56 572 5555",
      plan: "Tier 1",
      tokens: 250000,
      dateJoined: "Aug 10, 2023",
    },
  ];

  return (
    <div className="row">
      <div className="col-2">
        <Nav />
      </div>
      <div className="col-10 px-5 pt-4">
        <Header />
        <DataCards />
        <CustomerTable customers={customers} />
      </div>
    </div>
  );
}

export default CustomerList;
