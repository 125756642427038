import { isEmpty } from "lodash";
import React from "react";

function EmptyChat({ cachedBranding }) {
  return (
    <div className="new-chat" style={{ fontSize: "15px" }}>
      {!isEmpty(cachedBranding?.botIcon) ? (
        <img
          src={`data:${cachedBranding?.botIconContentType};base64,${cachedBranding?.botIcon}`}
          height={56}
        />
      ) : (
        <img src="/images/main-bot.svg" />
      )}
      <span
        className="fs-6 fw-semibold mt-2"
        style={{ color: cachedBranding?.page_TextColor }}
      >
        I'm AiDucator! Your AI Chatbot!
      </span>
      <span style={{ color: cachedBranding?.page_TextColor }}>
        I'm here to assist you with creativity and provide useful guidance.
      </span>
      {/* <span>Feeling uncertain about where to begin? You might consider:</span> */}
    </div>
  );
}

export default EmptyChat;
